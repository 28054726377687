import React from "react";
import {css, styled} from "../../../core/styledComponents";
import {IconTelegram} from "../../../shared/icons/IconTelegram";
import {IconViber} from "../../../shared/icons/IconViber";
import {IconWatsapp} from "../../../shared/icons/IconWatsapp";

interface IProps {
    noTitle?: boolean;
    noHideOnMobile?: boolean;
}

export const SharedContent: React.FC<IProps> = (props) => {
    return (
        <Container noTitle={true} noHideOnMobile={props.noHideOnMobile}>
            {!props.noTitle && <Title>Свяжитесь с нами:</Title>}
            <div style={{display: "flex"}}>
                <Item target={"_blank"} href={"https://t.me/pmr_mdbot"}>
                    <IconTelegram width={"32px"} height={"32px"} />
                </Item>
                <Item target={"_blank"} href={"viber://chat?number=+37377581111"}>
                    <IconViber height={"32px"} width={"32px"} />
                </Item>
                <Item target={"_blank"} href={"https://wa.me:/+37377581111"}>
                    <IconWatsapp width={"32px"} height={"32px"} />
                </Item>
            </div>
        </Container>
    );
};

const Container = styled.div<{noTitle?: boolean; noHideOnMobile?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: start;
    align-self: flex-start;
    margin-right: 13px;
    margin-top: ${(props) => (props.noTitle ? 0 : 21)}px;
    @media (max-width: 420px) {
        ${(props) =>
            props.noHideOnMobile !== true &&
            css`
                display: none;
            `};
    }

    @media (max-width: 991px) {
        align-self: center;
        margin-right: 0;
        align-items: center;
        padding-bottom: 20px;
    }
`;

const Title = styled.div`
    margin-right: 5px;
    font-family: SFUITextMedium, sans-serif;
    font-size: 20px;
    margin-bottom: 8px;
    color: #4a4a4a;
`;

const Item = styled.a`
    display: block;
    margin-right: 15px;
    cursor: pointer;
    img {
        height: 32px;
        width: auto;
    }
`;
