/*tslint:disable*/
import {BaseRequest} from "./BaseRequest";
import {INews} from "./dto/News.g";
import {INewsFilter} from "./dto/NewsFilter.g";
import {IPageResult} from "./dto/PageResult.g";

export class NewsApiRequest extends BaseRequest {
  getById(id: string, config?: Object): Promise<INews> {
    return this.fetch(`/allApi/News/${id}`, Object.assign({method: "GET"}, config))
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getByFilter(pageNumber: number, pageSize: number, filter: INewsFilter, config?: Object): Promise<IPageResult<INews>> {
    const p: {[key: string]: any} = {};
    p["filter"] = filter;
    return this.fetch(
      `/allApi/News/GetByFilter/${pageNumber}/${pageSize}?${this.getObjectFlatter()(filter)}`,
      Object.assign({method: "GET"}, config)
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }
}
