import React from "react";
import {styled} from "../../../core/styledComponents";
import {SharedContent} from "./sharedLinks";
import {SocialNetwork} from "./socialNetwork";

export const FooterDirectoryLink: React.FC = () => {
  return (
    <Container>
      <SharedContent/>
      <SocialNetwork/>
      <Block>
        <Title>Контакты:</Title>
        <Contacts href={"/contacts"}>Бизнес-портала</Contacts>
      </Block>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-start;
  @media (max-width: 420px) {
    display: none;
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
  @media (max-width: 1199px) {
    margin-bottom: 20px;
    align-self: center;
    justify-content: center;
  }
`;

const Block = styled.div`
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const Contacts = styled.a`
  margin-right: 5px;
  font-family: SFUITextMedium, sans-serif;
  font-size: 20px;
  margin-bottom: 8px;
  color: #ffffff;
  background: #4a4a4a;
  border-radius: 8px;
  padding: 4px 16px;
  cursor: pointer;
  text-align: center;
  :hover {
    text-decoration: none;
    color: #ffffff;
  }
`;

const Title = styled.div`
  margin-right: 5px;
  font-family: SFUITextMedium, sans-serif;
  font-size: 20px;
  margin-bottom: 8px;
  color: #4a4a4a;
`;
