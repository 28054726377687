import React from "react";

export const IconMobileHomePage = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 34 34" style={{marginRight: 10}}>
        <g fill="none" fillRule="evenodd">
            <path fill="none" d="M0 0h34v34H0z" />
            <path
                fill="#3e4c5b"
                fillRule="nonzero"
                d="M33.636 15.127L17.699 1.261a1.059 1.059 0 0 0-1.392 0L10.625 6.2v-2A2.13 2.13 0 0 0 8.5 2.066 2.13 2.13 0 0 0 6.375 4.2v5.698l-6.01 5.229a1.072 1.072 0 0 0-.105 1.506c.386.444 1.056.49 1.5.107L17 3.48l15.238 13.26a1.061 1.061 0 0 0 1.498-.107c.39-.446.34-1.116-.1-1.506z"
            />
            <path
                fill="#3e4c5b"
                fillRule="nonzero"
                d="M18.819 9.477a2.068 2.068 0 0 0-2.64 0l-10.417 8.57A2.09 2.09 0 0 0 5 19.666v10.243C5 31.063 5.933 32 7.083 32h6.25V21.546h8.334V32h6.25A2.09 2.09 0 0 0 30 29.91V19.665c0-.632-.281-1.221-.765-1.617L18.82 9.477z"
            />
        </g>
    </svg>
);
