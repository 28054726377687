/*tslint:disable*/
import {BaseRequest} from "./BaseRequest";
import {ICalendarDate} from "./dto/CalendarDate.g";
import {ICalendarFiles} from "./dto/CalendarFiles.g";
import {ICalendarFilesGroup} from "./dto/CalendarFilesGroup.g";
import {CalendarType} from "./dto/CalendarType.g";

export class CalendarApiRequest extends BaseRequest {
  getAll(config?: Object): Promise<ICalendarDate[]> {
    return this.fetch(
      `/allApi/Calendar`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getByMonth(month: number, year: number, config?: Object): Promise<ICalendarDate[]> {
    const p: {[key: string]: any} = {};
    p["month"] = month;
    p["year"] = year;
    return this.fetch(
      `/allApi/Calendar/GetByMonth?${this.getObjectFlatter()(p)}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getFiles(type: CalendarType, year: number, config?: Object): Promise<ICalendarFiles> {
    const p: {[key: string]: any} = {};
    p["type"] = type;
    p["year"] = year;
    return this.fetch(
      `/allApi/Calendar/GetFiles?${this.getObjectFlatter()(p)}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getAllFiles(config?: Object): Promise<ICalendarFilesGroup[]> {
    return this.fetch(
      `/allApi/Calendar/GetAllFiles`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }
}
