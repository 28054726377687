import {css, styled} from "../core/styledComponents";

export const buttonCss = css`
display: flex;
align-items: center;
align-self: flex-start;
justify-content: center;
border: solid 2px ${(props) => props.theme.colors.blueAccent};
cursor: pointer;
border-radius: 999px;
padding: 10px 20px;
color: ${(props) => props.theme.colors.blueAccent};
font-size: 16px;
font-weight: 500;
background: border-box;
text-align: center;
font-family: SFUITextMedium, sans-serif;
text-decoration: none;
outline: none;
:hover {
  text-decoration: none;
  color: white;
  background: ${(props) => props.theme.colors.blueAccent};
  outline: none;
}
@media (max-width: 768px) {
  align-self: stretch;
  margin: 10px 0 0 0;
}
`;

export const Button = styled.button<{inverted?: boolean}>`
${buttonCss};
${({inverted, theme}) =>
  inverted &&
  css`
  background: ${theme.colors.blueAccent};
  color: ${theme.colors.white};
  outline: none;
  :hover,
  :focus,
  :active {
    background: border-box;
    color: ${theme.colors.blueAccent};
    outline: none;
  }
  margin-left: 8px;
`};
`;
