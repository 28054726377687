/*tslint:disable*/
import {BaseRequest} from "./BaseRequest";
import {IFirm} from "./dto/Firm.g";
import {IFirmFilter} from "./dto/FirmFilter.g";
import {IFirmNameFilter} from "./dto/FirmNameFilter.g";
import {IFirmShort} from "./dto/FirmShort.g";
import {IFirmShortUpdate} from "./dto/FirmShortUpdate.g";
import {IPageResult} from "./dto/PageResult.g";


export class FirmApiRequest extends BaseRequest {
  IncreaseCountOfFirmViews(webId: string, config?: Object): Promise<IFirm> {
    return this.fetch(
      `/allApi/Firm/IncreaseCountOfFirmViews/${webId}`,
      Object.assign(
        {
          method: "POST"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getById(webId: string, config?: Object): Promise<IFirm> {
    return this.fetch(
      `/allApi/Firm/${webId}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getFromBlacklist(pageNumber: number, pageSize: number, config?: Object): Promise<IPageResult<IFirm>> {
    return this.fetch(
      `/allApi/Firm/Blacklist/${pageNumber}/${pageSize}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getList(
    pageNumber: number,
    pageSize: number,
    filter?: IFirmNameFilter | null,
    symbol?: string | null,
    config?: Object
  ): Promise<IPageResult<IFirm>> {
    const p: {[key: string]: any} = {};
    p["filter"] = filter;
    p["symbol"] = symbol;
    return this.fetch(
      `/allApi/Firm/List/${pageNumber}/${pageSize}?${this.getObjectFlatter()(p)}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getByFilter(
    pageNumber: number,
    pageSize: number,
    filter: IFirmFilter,
    config?: Object
  ): Promise<IPageResult<IFirm>> {
    const p: {[key: string]: any} = {};
    p["filter"] = filter;
    return this.fetch(
      `/allApi/Firm/GetByFilter/${pageNumber}/${pageSize}?${this.getObjectFlatter()(p)}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  sendCreateEmail(firm: IFirmShort, config?: Object): Promise<void> {
    return this.fetch(
      `/allApi/Firm/Create`,
      Object.assign(
        {
          method: "POST",
          body: JSON.stringify(firm)
        },
        config
      )
    ).catch(BaseRequest.handleError);
  }

  sendUpdateEmail(firmSiteUrl: string, firm: IFirmShortUpdate, config?: Object): Promise<void> {
    const p: {[key: string]: any} = {};
    p["firmSiteUrl"] = firmSiteUrl;
    return this.fetch(
      `/allApi/Firm/Update?${this.getObjectFlatter()(p)}`,
      Object.assign(
        {
          method: "POST",
          body: JSON.stringify(firm)
        },
        config
      )
    ).catch(BaseRequest.handleError);
  }

  GetSimilarById(webId: string, cityId: number, config?: Object): Promise<IPageResult<IFirm>> {
    return this.fetch(
      `/allApi/Firm/GetSimilarById/${webId}/${cityId}/4`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  GetSuggestions(searchString: string, config?: Object): Promise<string> {
    return this.fetch(
      `/allApi/Firm/GetSuggestions/${searchString}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }
}
