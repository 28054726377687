import React from "react";
import styled, {css} from "styled-components";

interface IProps {
  activePage: number;
  countPage: number;
  onClick: (value: number) => void;
}

export const Pagination: React.FC<IProps> = (props) => {
  const initialNumerPage = (): number[] => {
    const maxPerPage = Math.min(5, props.countPage);
    let visiblePage: number[] = [];

    if (maxPerPage < 5) {
      for (let i = 0; i <= maxPerPage; i++) {
        if (i) {
          visiblePage.push(i);
        }
      }
    } else {
      if (props.activePage >= props.countPage - 2) {
        visiblePage = [
          props.countPage - 4,
          props.countPage - 3,
          props.countPage - 2,
          props.countPage - 1,
          props.countPage
        ];
      } else {
        if (props.activePage < 3) {
          visiblePage = [1, 2, 3, 4, 5];
        } else {
          visiblePage = [
            props.activePage - 2,
            props.activePage - 1,
            props.activePage,
            props.activePage + 1,
            props.activePage + 2
          ];
        }
      }
    }

    return visiblePage;
  };

  const Prev = () => {
    onClick(props.activePage - 1);
  };
  const Next = () => {
    onClick(props.activePage + 1);
  };
  const onClick = (value: number) => {
    window.scrollTo(0, 0);
    props.onClick(value);
  };

  const page = React.useMemo(() => {
    return initialNumerPage().map((elem) => (
        // tslint:disable-next-line
        <Button active={props.activePage === elem} key={elem} onClick={() => onClick(elem)}>
          <span>{elem}</span>
        </Button>
      )
    );
  }, [props]);

  return (
    <>
      {props.countPage <= 1 && <div style={{marginTop: "40px"}}/>}
      <Container visible={props.countPage <= 1}>
        <Button
          disabled={props.activePage === 1}
          style={{color: props.activePage === 1 ? "#B2B2B2" : "#000"}}
          onClick={Prev}
        >
          «
        </Button>
        {page}
        <Button
          disabled={props.activePage === props.countPage}
          style={{color: props.activePage === props.countPage ? "#B2B2B2" : "#000"}}
          onClick={Next}
        >
          »
        </Button>
      </Container>
    </>
  );
};

const Container = styled.div<{visible?: boolean}>`
  display: ${(props) => (props.visible ? "none" : "flex")};
  margin: 40px 0;
  @media (max-width: 420px) {
    justify-content: center;
  }
`;

const Button = styled.div<{disabled?: boolean; active?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background: #fff;
  color: black;
  border-radius: 50%;
  border: none;
  font-size: 16px;
  padding: 7px 12px;
  margin: 0 2px;
  outline: none;
  cursor: pointer;
  text-align: center;
  user-select: none;
  ${(props) => props.disabled && css`cursor: auto; pointer-events: none;`};
  ${(props) => props.active && css`background: #0f77ff; color: white;`};
  span {
    text-align: center;
    user-select: none;
  }
`;
