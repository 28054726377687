import {styled} from "../core/styledComponents";

export const Outside = styled.div`
    @media (max-width: 1001px) {
        width: 100%;
        height: 1000%;
        position: fixed;
    }
`;

export const Header = styled("header")`
    display: flex;
    height: 77px;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    background-color: ${(props) => props.theme.colors.beige};
    padding: 0 !important;

    @media (min-width: ${(props) => props.theme.sizes.mainMenuFoldingWidth + 1}px) {
        .ContainerNavMob,
        .burger_left {
            display: none;
        }
    }

    @media (max-width: ${(props) => props.theme.sizes.mainMenuFoldingWidth}px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .Container {
            display: flex;
            align-items: center;
            z-index: 999;
            width: 100%;
        }
        .activeSpan {
            position: fixed;
            top: 0;
            left: 0;
            padding: 10px 0 0 0;
            background: white;
            height: 100%;
            transition: all 0ms ease-in-out;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            box-shadow: 0 0 120px #aaa;

            img {
                display: flex;
            }

            .burger_left {
                display: none;
            }

            @media (max-width: 420px) {
                width: 85%;
                justify-content: flex-start;
            }

            @media (min-width: 421px) {
                width: 320px;
                left: 0;
                justify-content: flex-start;
                z-index: 3;
            }

            .outside {
                width: 100%;
                height: 1000%;
                position: fixed;
                z-index: -1;
            }
        }
        .ContainerNavMob {
            display: none;
        }
        .activeSpan .ContainerNavMob {
            display: flex;
            flex-direction: column;
            background-color: ${(props) => props.theme.colors.beige};
            height: 100%;
            justify-content: flex-start;
            padding-top: 1px;
        }
    }
`;
