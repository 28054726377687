import {createSelector} from "reselect";
import {IAppState} from "../../core/store/mainReducer";

const citySelector = createSelector(
  (state: IAppState) => state.search.city,
  (cityList) => cityList.map((item) => ({value: item.id, label: item.title}))
);

const bankSelector = createSelector(
  (state: IAppState) => state.bankReducer.data,
  (bankList) => bankList.map((item) => ({value: item.id, label: item.title}))
);

export const addCompanyMapState = createSelector(
  (state: IAppState) => state.companyItem.data,
  citySelector,
  bankSelector,
  (data, city, banks) => ({data, city, banks})
);
