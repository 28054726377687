import {reducerWithInitialState} from "typescript-fsa-reducers";
import {ICalendarDate} from "../../api/dto/CalendarDate.g";
import {ICalendarFilesGroup} from "../../api/dto/CalendarFilesGroup.g";
import {calendar, calendarAllFiles} from "./calendarAction";

interface ICelebrate {
    data: ICalendarDate[];
    error: boolean;
    loading: boolean;
    allFiles: ICalendarFilesGroup[];
}

const CalendarInitialState: ICelebrate = {
    data: [],
    error: false,
    loading: true,
    allFiles: []
};

export const CalendarReducer = reducerWithInitialState(CalendarInitialState)
    .case(calendar.started, (state) => ({
        data: state.data,
        error: false,
        loading: true,
        allFiles: state.allFiles
    }))
    .case(calendar.done, (state, payload) => ({
        data: payload.result,
        error: false,
        loading: false,
        allFiles: state.allFiles
    }))
    .case(calendar.failed, (state) => ({
        data: state.data,
        error: true,
        loading: false,
        allFiles: state.allFiles
    }))

    .case(calendarAllFiles.started, (state) => ({
        data: state.data,
        error: false,
        loading: true,
        allFiles: state.allFiles
    }))
    .case(calendarAllFiles.done, (state, payload) => ({
        data: state.data,
        error: false,
        loading: false,
        allFiles: payload.result
    }))
    .case(calendarAllFiles.failed, (state) => ({
        data: state.data,
        error: true,
        loading: false,
        allFiles: state.allFiles
    }));
