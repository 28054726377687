import React from "react";
import Slider from "react-slick";
import {UrlBackend} from "../../common/constants";
import {styled} from "../../core/styledComponents";
import "./slick-theme.min.css";
import "./slick.min.css";


interface IProps {
  photoCompany: string[];
  clickImage: (value: number) => void;
  autoplay?: boolean;
}

export const PhotoCompany: React.FC<IProps> = (props) => {
  const settings = React.useMemo(() => ({
    dots: false,
    infinite: true,
    autoplay: props.autoplay,
    speed: 400,
    slidesToShow: window.innerWidth > 767 ? 4 : window.innerWidth > 420 ? 2 : 1,
    slidesToScroll: 1,
    accessibility: true,
    arrows: window.innerWidth > 767
  }), [window.innerWidth]);

  const inCarousel = React.useMemo(() => {
    if (window.innerWidth > 767 && props.photoCompany.length > 4) {
      return <Slider {...settings}>
        {props.photoCompany.map((item, index) => (// tslint:disable-next-line
          <ContainerImage key={index} onClick={() => props.clickImage(index)}>
            <Image src={UrlBackend + "/" + item}/>
          </ContainerImage>
        ))}
      </Slider>;
    } else if (window.innerWidth > 420 && window.innerWidth <=767 && props.photoCompany.length > 2) {
      return <Slider {...settings}>
        {props.photoCompany.map((item, index) => (// tslint:disable-next-line
          <ContainerImage key={index} onClick={() => props.clickImage(index)}>
            <Image src={UrlBackend + "/" + item}/>
          </ContainerImage>
        ))}
      </Slider>;
    } else if (window.innerWidth <= 420 && props.photoCompany.length > 1) {
      return <Slider {...settings}>
        {props.photoCompany.map((item, index) => (// tslint:disable-next-line
          <ContainerImage key={index} onClick={() => props.clickImage(index)}>
            <Image src={UrlBackend + "/" + item}/>
          </ContainerImage>
        ))}
      </Slider>;
    } else {
      return <ContainerNoSlider>
        {props.photoCompany.map((item, index) => (// tslint:disable-next-line
          <ContainerImage key={index} onClick={() => props.clickImage(index)}>
            <Image src={UrlBackend + "/" + item}/>
          </ContainerImage>
        ))}
      </ContainerNoSlider>;
    }
  }, [window.innerWidth, props.photoCompany]);

  return <>
    <Title>Фото:</Title>
    <Container>
      {inCarousel}
    </Container>
  </>;
};

const Container = styled.div`
  margin-bottom: 40px;
  .slick-arrow{
    background: rgb(210, 210, 210, 0.9);
    height: 100%;
    
    @media(max-width: 768px){
    display: none;
    }
  }

`;

const Image = styled.img`
    max-width: 255px;
    max-height: 170px;
`;

const ContainerNoSlider = styled.div`
  display: flex;
  div {
    margin-right: 25px;
  }
`;

const ContainerImage = styled.div`
  display: flex !important;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  background: white;
  align-items: center;
  justify-content: center;
  max-width: 205px;
  height: 170px;
  
  @media (min-width: 1200px){
    max-width: 255px;
  }

`;

const Title = styled.div`
  font-family: SFUITextMedium, sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #3e4c5b;
  margin: 16px 0;
`;
