const symbols = {
    '#': "%23",
    '+': '%2b'
} as const


export const replaceSymbolSearch = (value: string) => {
    let newValue = value;
    for (const [key, val] of Object.entries(symbols)) {
        newValue = newValue.replace(key,val)
    }

    return newValue;
}
