import React from "react";

/* tslint:disable:max-line-length */
export const IconMobileCalendar = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510" width="18px" height="18px" style={{marginRight: 10}}>
        <g>
            <path
                d="M408,510c28.05,0,51-22.95,51-51V51c0-28.05-22.95-51-51-51H255v178.5l-63.75-38.25L127.5,178.5V0H102    C73.95,0,51,22.95,51,51v408c0,28.05,22.95,51,51,51H408z"
                fill="#3e4c5b"
            />
        </g>
    </svg>
);
