import moment from "moment";
import React from "react";
import {styled} from "../../../core/styledComponents";
import {NavLink} from "../../../ui/NavLink";
import {FooterAdvertising} from "./advertising/footerAdvertising";
import {BlackListLink} from "./blackListLink";
import {FooterDirectoryLink} from "./footerDirectoryLink";
import {UsefulLinks} from "./usefulLinks";
import {VisitCounter} from "./visitCounter/visitCounter";

export const Footer: React.FC = () => {
  return <div style={{background: "white"}}>
    <FooterContainer className={"container"}>
      <RightBlock className="col-lg-9 col-md-12 col-sm-12" style={{paddingLeft: 0, paddingRight: 0}}>
        <FooterAdvertising/>
        <FooterDirectoryLink/>
        <BlackListLink/>
        <BottomContainer>
          <DirectoryTextLink to="/">
            1998-{moment().format("YYYY")}, Бизнес-портал Приднестровья
          </DirectoryTextLink>
          <CounterContainer>
            <VisitCounter/>
          </CounterContainer>
        </BottomContainer>
      </RightBlock>
      <UsefulLinks/>
    </FooterContainer>
  </div>;
};

const FooterContainer = styled.footer`
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    padding-top: 32px;

    @media (max-width: 420px) {
        background-color: ${(props) => props.theme.colors.beige};
    }
    @media (max-width: 500px) {
        padding-bottom: 0;
    }
    @media (max-width: 1199px) {
        align-items: center;
        flex-direction: column;
    }
`;

const DirectoryTextLink = styled(NavLink)`
    display: block;
    font-family: SFUIText, sans-serif;
    font-size: 16px;
    color: #3e4c5b;
    @media (max-width: 770px) {
        text-align: center;
        margin-bottom: 20px;
    }
    @media (max-width: 420px) {
        font-size: 12px;
        color: ${(props) => props.theme.colors.mainGrey};
        margin-bottom: 16px;
    }
`;
const RightBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

const BottomContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1199px) {
        padding: 0 140px;
        justify-content: space-around;
    }
    @media (max-width: 900px) {
        padding: 0 120px;
    }
    @media (max-width: 840px) {
        padding: 0 100px;
    }
    @media (max-width: 770px) {
        flex-direction: column;
        padding: 20px;
        text-align: center;
    }
`;

const CounterContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding-right: 25px;
    @media (max-width: 1199px) {
        flex-grow: 0;
        padding-right: 0;
    }
`;
