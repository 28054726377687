export interface IUrlPageNews {
  page?: number;
  category?: string;
  city?: string;
  text?: string;
}

export const createUrlPageNews = (params: IUrlPageNews) => {
  return `?page=${params.page || 1}${params.category ? `&category=${params.category}` : ""}${
    params.city ? "&city=" + params.city : ""
    }${params.text ? "&text=" + params.text : ""}`;
};
