import React from "react";

/* tslint:disable:max-line-length */
export const IconFlagEuro = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <path fill="none" d="M0 0h32v32H0z" />
            <circle cx="16" cy="16" r="15" fill="#0052B4" fillRule="nonzero" />
            <g fill="#FFDA44" fillRule="nonzero">
                <path d="M16 6.87l.486 1.494h1.571l-1.271.924.485 1.495-1.27-.924-1.272.924.485-1.495-1.271-.924h1.571zM9.544 9.544l1.4.713 1.111-1.11-.246 1.551 1.4.714-1.551.246-.246 1.552-.714-1.4-1.552.245 1.111-1.11zM6.87 16l1.494-.486v-1.571l.924 1.271 1.495-.485L9.859 16l.924 1.271-1.495-.485-.924 1.271v-1.571zM9.544 22.456l.713-1.4-1.11-1.111 1.551.246.714-1.4.246 1.551 1.552.246-1.4.714.245 1.552-1.11-1.111zM16 25.13l-.486-1.494h-1.571l1.271-.924-.485-1.495 1.271.924 1.271-.924-.485 1.495 1.271.924h-1.571zM22.456 22.456l-1.4-.713-1.111 1.111.246-1.552-1.4-.714 1.551-.246.246-1.552.714 1.4 1.552-.245-1.111 1.11zM25.13 16l-1.494.486v1.571l-.924-1.271-1.495.485.924-1.271-.924-1.271 1.495.485.924-1.271v1.571zM22.456 9.544l-.713 1.4 1.111 1.111-1.552-.246-.714 1.4-.246-1.551-1.552-.246 1.4-.714-.245-1.552 1.111 1.111z" />
            </g>
        </g>
    </svg>
);
