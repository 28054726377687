import React from "react";

interface IProps {
  width: string;
  height: string
}

export const IconPlus = (props: IProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#0F77FF"
      d="M16,0 C7.17168348,0 0,7.17168348 0,16 C0,24.8283165 7.17168348,32 16,32 C24.8283165,32 32,24.8283165 32,16 C32,7.17168348 24.8283165,0 16,0 Z M16,1.39130435 C24.0763993,1.39130435 30.6086957,7.9236007 30.6086957,16 C30.6086957,24.0763993 24.0763993,30.6086957 16,30.6086957 C7.9236007,30.6086957 1.39130435,24.0763993 1.39130435,16 C1.39130435,7.9236007 7.9236007,1.39130435 16,1.39130435 Z M15.3043478,7.65217391 L15.3043478,15.3043478 L7.65217391,15.3043478 L7.65217391,16.6956522 L15.3043478,16.6956522 L15.3043478,24.3478261 L16.6956522,24.3478261 L16.6956522,16.6956522 L24.3478261,16.6956522 L24.3478261,15.3043478 L16.6956522,15.3043478 L16.6956522,7.65217391 L15.3043478,7.65217391 Z"
    />
  </svg>
);
