import {BankApiRequest} from "../../api/BankApiRequest.g";
import {IThunkAction} from "../../core/store/mainReducer";
import {BankAction} from "./bankAction";

export const BankActionAsync = {
  LoadBanks(): IThunkAction {
    const api = new BankApiRequest();

    return async (dispatch) => {
      dispatch(BankAction.bankListItem.started);
      try {
        const result = await api.getAll();
        dispatch(BankAction.bankListItem.done({params: "", result}));
      } catch (error) {
        dispatch(BankAction.bankListItem.failed({params: "", error}));
      }
    };
  }
};
