import {DivisionApiRequest} from "../../api/DivisionApiRequest.g";
import {IThunkAction} from "../../core/store/mainReducer";
import {initialRubricator, subCategories} from "./rubricatorAction";

export const GetRubricator = {
  Initial(): IThunkAction {
    const api = new DivisionApiRequest();

    return async (dispatch) => {
      dispatch(initialRubricator.started(""));
      try {
        const result = await api.getFavorite();
        dispatch(initialRubricator.done({params: "", result}));
      } catch (error) {
        dispatch(initialRubricator.failed({params: "", error}));
      }
    };
  },

  SubCategory(id: string): IThunkAction {
    const api = new DivisionApiRequest();

    return async (dispatch) => {
      dispatch(subCategories.started(""));
      try {
        const result = await api.getSub(id);
        dispatch(subCategories.done({params: "", result}));
      } catch (error) {
        dispatch(subCategories.failed({params: "", error}));
      }
    };
  },

  AllCategory(): IThunkAction {
    const api = new DivisionApiRequest();

    return async (dispatch) => {
      dispatch(subCategories.started(""));
      try {
        const result = await api.getRoot();
        dispatch(subCategories.done({params: "", result}));
      } catch (error) {
        dispatch(subCategories.failed({params: "", error}));
      }
    };
  }
};
