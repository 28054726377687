import {CategoryApiRequest} from "../../../api/CategoryApiRequest.g";
import {ICategory} from "../../../api/dto/Category.g";
import {IThunkAction} from "../../../core/store/mainReducer";
import {initialCategories} from "./categoryAction";

export const InitialCategories = {
  InitialCategory(): IThunkAction {
    const api = new CategoryApiRequest();

    let result: ICategory[] = [];

    return async (dispatch) => {
      dispatch(initialCategories.started(""));
      try {
        result = await api.getRoot();
        dispatch(initialCategories.done({params: "", result}));
      } catch (error) {
        dispatch(initialCategories.failed({params: "", error}));
      }
    };
  }
};
