export const amountOfNews = 18;

export const amountOfSimilarNews = 4;

export const amountOfFirms = 20;

// http://pmrmd.dextechnology.com:20502 - prod version
// http://pmrmd.dextechnology.com:20501 - test version

// export const UrlBackend = "https://pmr.md";

export const UrlBackend = "";
