import React from "react";

export const IconFullScreen = () => (
    <svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={12}
        height={12}
        viewBox="0 0 459 459"
        enableBackground="new 0 0 459 459">
        <g id="crop-landscape">
            <path
                fill={"white"}
                d="M408,51H51C22.95,51,0,73.95,0,102v255c0,28.05,22.95,51,51,51h357c28.05,0,51-22.95,51-51V102
C459,73.95,436.05,51,408,51z M408,357H51V102h357V357z"
            />
        </g>
    </svg>
);
