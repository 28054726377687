import {ExchangeApiRequest} from "../../api/ExchangeApiRequest.g";
import {IThunkAction} from "../../core/store/mainReducer";
import {exchangeCourse} from "./exchangeCourseAction";

export const CalculateCelebrateData = {
  LoadItems(): IThunkAction {
    const api = new ExchangeApiRequest();

    return async (dispatch) => {
      dispatch(exchangeCourse.started);
      try {
        const result = await api.getAll();
        dispatch(exchangeCourse.done({params: "", result}));
      } catch (error) {
        dispatch(exchangeCourse.failed({params: "", error}));
      }
    };
  }
};
