import React from "react";
import burger from "../../../shared/img/burger.png";
import {Header as HeaderContainer} from "../../../ui/Header";
import {DirectoryLink} from "./componentHeader/directoryLink";
import {NavBar} from "./componentHeader/navBar";
import {NavBarMobile} from "./componentHeader/navBarMobile";

export const Header: React.FC = () => {
  const [isActive, setIsActive] = React.useState("");

  const clickLeftMenu = () => {
    if (isActive === "") {
      setIsActive("activeSpan");
    } else {
      setIsActive("");
    }
  };

  const closeLeftMenu = () => {
    setIsActive("");
  };

  return <header className="container">
    <HeaderContainer>
      <div className={"Container " + isActive}>
        <img className={"burger_left"} src={burger} onClick={clickLeftMenu} alt=""/>
        <DirectoryLink IsActive={isActive}/>
        <NavBarMobile onClick={clickLeftMenu}/>
        <div className={"outside"} onClick={closeLeftMenu}/>
      </div>
      <NavBar/>
    </HeaderContainer>
  </header>;
};


