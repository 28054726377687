import styled from "styled-components";

export const AddCompanyTitle = styled.div`
    font-family: SFUITextMedium, sans-serif;
    font-size: 36px;
    font-weight: 500;
    color: #3e4c5b;
    align-self: center;
    margin: 22px 0 30px;
    text-align: center;
    @media (max-width: 420px) {
        margin: 10px 0;
        font-size: 20px;
        text-align: start;
        font-weight: 600;
    }
`;

export const AddCompanyDescription = styled.div`
    font-size: 14px;
    text-align: center;
    margin-bottom: 40px;
    color: #878688;
    @media (max-width: 420px) {
        margin: 10px 0;
        font-size: 12px;
        text-align: start;
    }
`;

export const TextSocialNetwork = styled.div`
    font-family: Helvetica, sans-serif;
    font-size: 30px;
    margin-bottom: 32px;
    font-weight: 500;
`;
