import React from "react";
import {useHistory} from "react-router";
import {styled} from "../../../core/styledComponents";
import {IconChevron} from "../../../shared/icons/IconChevron";

interface IProps {
  id: string;
  name: string;
  active: boolean;
  onClick: (key: string, offset: number) => void;
  childrenCount: number;
}

export const DropdownItem: React.FC<IProps> = (props) => {
  const containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  const history = useHistory();

  const onClick = () => {
    if (props.childrenCount !== 0) {
      const current = containerRef.current;
      props.onClick(props.id, current != null ? current.offsetTop : 0);
    } else {
      history.push(`./search?category=${props.id}`);
    }
  };

  return <div ref={containerRef} className="row" style={{display: "flex", padding: "4px 0px", marginLeft: 0}}>
    <Block className="col-xs-11">
      <Link onClick={onClick} active={props.active} children={props.name}/>
    </Block>
    {!!props.childrenCount && <BlockIcon className="col-xs-1">{" "}<Icon active={props.active}/>{" "}</BlockIcon>}
  </div>;
};

const Block = styled.div`
    padding: 0;
`;

const BlockIcon = styled.div`
    padding: 0;
    display: flex;
    align-items: center;
    width: 20px;
`;

const Link = styled.span<{active: boolean}>`
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-decoration: none;
    padding: 4px 0;
    cursor: pointer;
    color: ${(props) => (props.active ? "#0f77ff" : "#3e4c5b")};
    max-width: 230px;
    &:hover {
        color: ${(props) => props.theme.colors.blueAccent};
    }
`;

const Icon = styled<{active: boolean}>(({active}) => <IconChevron color={active ? "#0f77ff" : "#3e4c5b"}/>)`
    display: block;
    transform: rotate("45deg");
    margin-left: 45px;
`;
