import * as moment from "moment";
import queryString from "query-string";
import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {INews} from "../../api/dto/News.g";
import {createUrlPageNews, IUrlPageNews} from "../../common/createURL/urlPageNews";
import {createUrlPageNewsItem, IUrlPageNewsItems} from "../../common/createURL/urlPageNewsItems";
import {styled} from "../../core/styledComponents";
import {Span} from "../../ui/span";

interface IProps extends RouteComponentProps {
    index: number;
    data: INews;
}

interface IState {
    description: string;
}

class NewsItemC extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {description: this.props.data.description.replace(/(<([^>]+)>)/gi, "")};
    }

    public render(): JSX.Element {
        const {data} = this.props;
        return (
            <Container index={this.props.index}>
                <HeaderContainer>
                    <Place>ПМР</Place>
                    <TagContainer>
                        <Tag onClick={this.searchTag}>{data.category.name}</Tag>
                    </TagContainer>
                </HeaderContainer>
                <FooterContainer onClick={this.onClick}>
                    <Header>{data.title}</Header>
                    <Description>
                        <div dangerouslySetInnerHTML={{__html: this.state.description.substr(0, 70)}} />
                    </Description>
                    <Date>{moment(data.createdUtc).format("DD MMMM YYYY")}</Date>
                </FooterContainer>
            </Container>
        );
    }

    private onClick = () => {
        const attributes: IUrlPageNewsItems = {id: this.props.data.id};
        this.props.history.push(createUrlPageNewsItem(attributes));
    };

    private searchTag = () => {
        const attributes: IUrlPageNews = queryString.parse(this.props.history.location.search);
        attributes.category = this.props.data.category.id;
        attributes.page = 1;
        this.props.history.push("/news" + createUrlPageNews(attributes));
    };
}

export const SimilarNewsItem = withRouter(NewsItemC);

const Container = styled.div<{index: number}>`
    box-sizing: border-box;
    display: inline-block;
    flex-direction: column;
    padding-top: 21px;
    padding-bottom: 21px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.white};
    margin: 5px 0;

    @media (min-width: 420px) {
        max-width: ${({theme}) => theme.columnMaxWidth}px;
    }
    @media (max-width: 420px) {
        margin: 5px 0;
    }
`;

const HeaderContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
`;

const FooterContainer = styled.div`
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-top: 25px;
    cursor: pointer;
`;

const Place = styled(Span)`
    font-size: 16px;
    color: ${(props) => props.theme.colors.mainGrey};
`;

const TagContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    border-radius: 20px;
    border: solid 1px ${(props) => props.theme.colors.blueAccent};
    cursor: pointer;
`;

const Tag = styled(Span)`
    font-size: 12px;
    color: ${(props) => props.theme.colors.blueAccent};
`;

const Header = styled(Span)`
    font-size: 20px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.slate};
    text-overflow: ellipsis;
`;

const Description = styled(Span)`
    display: inline-block;
    font-size: 16px;
    color: ${(props) => props.theme.colors.slate};
    line-height: 1.1em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 8px 0;
`;

const Date = styled(Span)`
    font-size: 12px;
    color: ${(props) => props.theme.colors.mainGrey};
`;
