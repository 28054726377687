import {reducerWithInitialState} from "typescript-fsa-reducers";
import {INews} from "../../api/dto/News.g";
import {newsItems} from "./newsItemAction";

export interface INewsIteamState {
    loading: boolean;
    data: INews;
    error: boolean;
}

const IteamInitialState: INewsIteamState = {
    data: {} as INews,
    error: false,
    loading: false
};
export const NewsItemReducer = reducerWithInitialState(IteamInitialState)
    .case(newsItems.started, (state) => ({data: state.data, error: false, loading: true}))
    .case(newsItems.done, (state, payload) => ({data: payload.result, error: false, loading: false}))
    .case(newsItems.failed, (state) => ({data: state.data, error: true, loading: false}));
