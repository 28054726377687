import React from "react";

interface IProps {
    width: string;
    height: string;
}

/* tslint:disable:max-line-length */
export const IconVK = ({width, height}: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={width} height={height}>
        <g id="icons8-vk_com" transform="translate(-0.667)">
            <g id="Footer" transform="translate(-578 -216)">
                <g id="icons8-vk_com-2" transform="translate(578.667 216)">
                    <path
                        id="Shape"
                        className="cls-1"
                        fill={"#0288d1"}
                        d="M32,27.556A4.444,4.444,0,0,1,27.556,32H4.444A4.444,4.444,0,0,1,0,27.556V4.444A4.444,4.444,0,0,1,4.444,0H27.556A4.444,4.444,0,0,1,32,4.444Z"
                    />
                    <path
                        id="Shape-2"
                        className="cls-2"
                        fill={"#fff"}
                        d="M24.164,18.985a10.362,10.362,0,0,1,2.3,2.684c.765,1.285-.847,1.388-.847,1.388l-3.087.043a2.176,2.176,0,0,1-1.538-.473c-1.155-.8-2.733-2.883-3.581-2.612s-.587,2.458-.587,2.458a.622.622,0,0,1-.82.622h-.889A8.915,8.915,0,0,1,9.13,20.282C6.2,17.2,3.753,11.443,3.753,11.443s-.148-.367.012-.561c.184-.22.679-.215.679-.215h3.3a1.317,1.317,0,0,1,.533.21,1.01,1.01,0,0,1,.287.392,17.317,17.317,0,0,0,1.106,2.307c1.379,2.411,2.021,2.938,2.49,2.677.683-.376.477-3.4.477-3.4a3.409,3.409,0,0,0-.344-1.591,1.552,1.552,0,0,0-1.025-.524c-.188-.02.117-.459.514-.654a7.47,7.47,0,0,1,2.885-.3c.966.011,1.331,0,1.331,0,1.139.278.752,1.353.752,3.925,0,.826-.147,1.986.441,2.368.254.167,1.165.192,2.711-2.464.734-1.26,1.316-2.445,1.316-2.445a.765.765,0,0,1,.757-.5l3.476.016s1.045-.129,1.213.35c.179.5-.427,1.374-1.845,3.287C22.488,17.463,22.229,17.171,24.164,18.985Z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const IconVKCircle = ({width, height}: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width={width} height={height}>
        <g id="Новость" transform="translate(-266 -1402)">
            <g
                id="icons8-vkontakte_circled_filled-2"
                data-name="icons8-vkontakte_circled_filled"
                transform="translate(266 1402)">
                <path
                    id="Shape"
                    fill={"#4a90e2"}
                    d="M17,0A17,17,0,1,0,34,17,17.019,17.019,0,0,0,17,0ZM11.087,8.87h5.75c1.775,0,6.062.053,6.062,3.725a3.593,3.593,0,0,1-2.174,3.43v.09a4.043,4.043,0,0,1,3.667,3.872c0,2.188-.923,5.145-6.8,5.145h-6.5Zm4.435,2.957v2.957s1.359-.014,1.587-.014a1.292,1.292,0,0,0,1.37-1.361,1.436,1.436,0,0,0-1.419-1.581Zm0,5.913v4.435s1.848-.033,2.217-.033a2.128,2.128,0,0,0,2.217-2.233,2.02,2.02,0,0,0-2.15-2.152C17.411,17.755,15.522,17.739,15.522,17.739Z"
                />
            </g>
        </g>
    </svg>
);
