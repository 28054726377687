import React from "react";
import {useForm} from "react-hook-form";
import {MetaTags} from "react-meta-tags";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps} from "react-router";
import {IFirmForm} from "../api/dto/FirmForm.g";
import {IFirmShort} from "../api/dto/FirmShort.g";
import {ISocialNetworks} from "../api/dto/SocialNetworks.g";
import {styled} from "../core/styledComponents";
import {AddCompany} from "../modules/addCompany/addCompanyActionAsync";
import {BankActionAsync} from "../modules/bank/bankActionAsync";
import {CompanyItemData} from "../modules/companyItem/companyItemActionAsync";
import {Button} from "../ui/Button";
import {ButtonGroup} from "../ui/ButtonGroup";
import {InputForReactForm, ReactFormInputContainer} from "../ui/elementReactForm/inputForReactForm";
// import {ReCaptcha} from "./componentAllPage/captcha/ReCaptcha";
import {Search} from "./componentAllPage/search/search";
import {Activities} from "./screenAddCompanyComponent/components/Activities";
import {Directors} from "./screenAddCompanyComponent/components/Directors";
import {addCompanyMapState} from "./screenAddCompanyComponent/selectors";
import {AddCompanyDescription, AddCompanyTitle, TextSocialNetwork} from "./screenAddCompanyComponent/styled";
import {ArrayFormAddCompany} from "./screenAddCompanyComponent/validation";


export const ScreenAddCompany: React.FC<RouteComponentProps<{id: string}>> = (props) => {
  const {register, handleSubmit, errors} = useForm();
  const state = useSelector(addCompanyMapState);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (props.match.params.id) {
      dispatch(CompanyItemData.LoadItem(props.match.params.id));
    }
    dispatch(BankActionAsync.LoadBanks());
  }, [props.match.params.id]);

  const onSubmit = async (values: /*IFirmShort*/ any) => {
    const data = props.match.params.id ? state.data : ({} as IFirmForm);
    const request: IFirmShort = {} as IFirmShort;

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (key && data[key] !== values[key] && values[key] !== "") {
          request[key] = values[key];
          if (key === "phone") {
            request[key] = values[key + "Code"] ? values[key + "Code"] + values[key] : values[key];
          }

          if (key === "socialNetworks") {
            request.socialNetworks = {} as ISocialNetworks;
            request[key] = {
              viber: values.socialNetworks.viber
                ? values[key].viberCode
                  ? values[key].viberCode + values[key].viber
                  : values[key].viber
                : undefined,
              whatsApp: values[key].whatsApp
                ? values[key].whatsAppCode
                  ? values[key].whatsAppCode + values[key].whatsApp
                  : values[key].whatsApp
                : undefined,
              telegram: values[key].telegram
                ? values[key].telegramCode
                  ? values[key].telegramCode + values[key].telegram
                  : values[key].telegram
                : undefined,
              facebookUrl: values[key].facebookUrl ? values[key].facebookUrl : undefined,
              instagramUrl: values[key].instagramUrl ? values[key].instagramUrl : undefined,
              odkUrl: values[key].odkUrl ? values[key].odkUrl : undefined,
              vkUrl: values[key].vkUrl ? values[key].vkUrl : undefined
            } as ISocialNetworks;
          }
          if (key === "fax") {
            request[key] = values[key + "Code"] ? values[key + "Code"] + values[key] : values[key];
          }
          if (key === "occupations") {
            request[key] = values[key].map(item => {
              return {
                divisionId: item.divisionId ? item.divisionId : undefined,
                subdivisionId: item.subdivisionId ? item.subdivisionId : undefined,
                description: item.description ? item.description : undefined
              };

            });
          }
          if (key === "managers") {
            request[key] = values[key].map(item => {
              return {
                post: item.post ? item.post : undefined,
                fullName: item.fullName ? item.fullName : undefined,
                phone: item.phone ? item.phone : undefined
              };

            });
          }

        }
      }
    }
    request.emailIsShow = !request.emailIsShow;
    if (values.allDay) {
      request.workHours = "круглосуточно";
    }

    console.log(request);
    if (values.occupations.length !== 0) {
      !props.match.params.id
        ? dispatch(AddCompany.CreatingCompany(request))
        : dispatch(AddCompany.UpdatingCompany(state.data.webId, request, `${window.location.host + "/" + state.data.webId + "/index.html"}`));
    }
  };

  const onCancel = () => {
    props.history.goBack();
  };

  return (
    <Container className={"container"}>
      <MetaTags>
        <title>
          Анкета-Заявка На размещение информации в Справочной системе «ПРИДНЕСТРОВЬЕ. Бизнес.
          Общество. Власть»
        </title>
        <meta name="description" content={``}/>
        <meta name="keywords" content={``}/>
      </MetaTags>
      <Search/>
      <AddCompanyTitle>
        Анкета-заявка <br/>
        {`${props.match.params.id ? "на редактирование записи" : "на добавление в Справочную систему Приднестровья"}`}
      </AddCompanyTitle>
      <AddCompanyDescription>
        ВНИМАНИЕ! Перед началом ввода данных проверьте наличие данных о Вашем предприятии в справочной
        системе путём поочередного ввода в поисковой строке названия предприятия, фамилии руководителя,
        адреса, телефона. Если таковых Вы не обнаружили, смело вводите данные предприятия.
      </AddCompanyDescription>


      <form onSubmit={handleSubmit(onSubmit)}>

        <InputForReactForm
          reff={register({required: true})}
          name={ArrayFormAddCompany.name.name}
          label={ArrayFormAddCompany.name.label}
          hasError={errors[ArrayFormAddCompany.name.name]}
          required={true}
          type={"input"}
          description={ArrayFormAddCompany.name.description}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.secondName.name}
          label={ArrayFormAddCompany.secondName.label}
          type={"input"}
          description={ArrayFormAddCompany.secondName.description}
        />

        <InputForReactForm
          reff={register({required: true})}
          name={ArrayFormAddCompany.cityId.name}
          label={ArrayFormAddCompany.cityId.label}
          hasError={errors[ArrayFormAddCompany.cityId.name]}
          required={true}
          type={"select"}
          options={state.city}
        />

        <InputForReactForm
          reff={register({required: true})}
          name={ArrayFormAddCompany.addressName.name}
          label={ArrayFormAddCompany.addressName.label}
          hasError={errors[ArrayFormAddCompany.addressName.name]}
          required={true}
          type={"input"}
          description={ArrayFormAddCompany.addressName.description}
        />

        <InputForReactForm
          codeRef={register()}
          reff={register({
            required: true,
            pattern: {value: /^\+?([\ \-\:\(\)]?\d+[\ \-\:\(\)]?){5}$/i, message: "invalid phone"}
          })}
          name={ArrayFormAddCompany.phone.name}
          label={ArrayFormAddCompany.phone.label}
          hasError={errors[ArrayFormAddCompany.phone.name]}
          required={true}
          type={"select & input"}
        />

        <InputForReactForm
          codeRef={register()}
          reff={register()}
          name={ArrayFormAddCompany["socialNetworks.viber"].name}
          label={ArrayFormAddCompany["socialNetworks.viber"].label}
          type={"select & input"}
        />

        <InputForReactForm
          codeRef={register()}
          reff={register()}
          name={ArrayFormAddCompany["socialNetworks.whatsApp"].name}
          label={ArrayFormAddCompany["socialNetworks.whatsApp"].label}
          type={"select & input"}
        />

        <InputForReactForm
          codeRef={register()}
          reff={register()}
          name={ArrayFormAddCompany["socialNetworks.telegram"].name}
          label={ArrayFormAddCompany["socialNetworks.telegram"].label}
          type={"select & input"}
        />

        <InputForReactForm
          codeRef={register()}
          reff={register()}
          name={ArrayFormAddCompany.fax.name}
          label={ArrayFormAddCompany.fax.label}
          type={"select & input"}
        />

        <InputForReactForm
          checkRef={register()}
          checkboxLabel={ArrayFormAddCompany.workHours.checkboxLabel}
          checkboxName={ArrayFormAddCompany.workHours.checkboxName}
          reff={register()}
          name={ArrayFormAddCompany.workHours.name}
          label={ArrayFormAddCompany.workHours.label}
          description={ArrayFormAddCompany.workHours.description}
          type={"input & checkbox"}
        />

        <InputForReactForm
          checkRef={register()}
          checkboxLabel={ArrayFormAddCompany.email.checkboxLabel}
          checkboxName={ArrayFormAddCompany.email.checkboxName}
          reff={register()}
          name={ArrayFormAddCompany.email.name}
          label={ArrayFormAddCompany.email.label}
          description={ArrayFormAddCompany.email.description}
          type={"input & checkbox"}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.site.name}
          label={ArrayFormAddCompany.site.label}
          type={"input"}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.icq.name}
          label={ArrayFormAddCompany.icq.label}
          type={"input"}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.skype.name}
          label={ArrayFormAddCompany.skype.label}
          type={"input"}
        />

        <TextSocialNetwork>Социальные сети:</TextSocialNetwork>

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany["socialNetworks.facebookUrl"].name}
          label={ArrayFormAddCompany["socialNetworks.facebookUrl"].label}
          type={"input"}
        />


        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany["socialNetworks.odkUrl"].name}
          label={ArrayFormAddCompany["socialNetworks.odkUrl"].label}
          type={"input"}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany["socialNetworks.vkUrl"].name}
          label={ArrayFormAddCompany["socialNetworks.vkUrl"].label}
          type={"input"}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany["socialNetworks.instagramUrl"].name}
          label={ArrayFormAddCompany["socialNetworks.instagramUrl"].label}
          type={"input"}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.bankId.name}
          label={ArrayFormAddCompany.bankId.label}
          type={"select"}
          options={state.banks}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.founded.name}
          label={ArrayFormAddCompany.founded.label}
          type={"date"}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.workCount.name}
          label={ArrayFormAddCompany.workCount.label}
          type={"number"}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.capital.name}
          label={ArrayFormAddCompany.capital.label}
          type={"number"}
        />

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.annualTurnover.name}
          label={ArrayFormAddCompany.annualTurnover.label}
          type={"number"}
        />

        <Directors reff={register()}/>

        <Activities reff={register({required: true})} selectReff={register()} hasError={errors}/>

        <InputForReactForm
          reff={register()}
          name={ArrayFormAddCompany.tagKeywords.name}
          label={ArrayFormAddCompany.tagKeywords.label}
          description={ArrayFormAddCompany.tagKeywords.description}
          type={"input"}
        />

        <InputForReactForm
          reff={register({required: true})}
          name={ArrayFormAddCompany.contactPerson.name}
          label={ArrayFormAddCompany.contactPerson.label}
          description={ArrayFormAddCompany.contactPerson.description}
          hasError={errors[ArrayFormAddCompany.contactPerson.name]}
          required={true}
          type={"input"}
        />

        <ReactFormInputContainer>
          {/* <ReCaptcha name={"captcha"} reff={register({required: true})} hasError={errors.captcha}/> */}
        </ReactFormInputContainer>
        <ButtonGroup>
          <AddCompanyButton type="submit">
            Отправить
          </AddCompanyButton>
          <AddCompanyButton type="button" onClick={onCancel}>
            Отмена
          </AddCompanyButton>
        </ButtonGroup>
      </form>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 60px;
`;

const AddCompanyButton = styled(Button)`
    width: 214px;
    margin-right: 24px;
    margin-top: 30px;
    outline: none;
    @media screen and (max-width: 768px) {
        margin: 0 0 5px 0;
        width: 45%;
    }
    @media screen and (max-width: 420px) {
        width: 100%;
    }
`;
