export const Currencies = [
    {value: "Usd", label: "Usd"},
    {value: "Eur", label: "Eur"},
    {value: "Mdl", label: "Mdl"},
    {value: "Uah", label: "Uah"},
    {value: "Rub", label: "Rub"},
    {value: "Rup", label: "Rup"}
];

export const defaultCurrency = {
    key: "Rup",
    country: "ПМР",
    currency: "р. ПМР",
    currencyFull: "Рубль ПМР",
    order: 0,
    exchangeRate: 1
};
