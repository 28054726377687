import queryString from "query-string";
import React from "react";
import {MetaTags} from "react-meta-tags";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {amountOfFirms} from "../common/constants";
import {IUrlBlackList} from "../common/createURL/urlBlackList";
import {replaceTranslate} from "../common/replaceTranslate";
import {IAppState} from "../core/store/mainReducer";
import {styled} from "../core/styledComponents";
import {BlackListActionAsync} from "../modules/blackList/blackListActionAsync";
import {Pagination} from "./componentAllPage/pagination/pagination";
import {Search} from "./componentAllPage/search/search";
import {BlackListItem} from "./screenBlackListComponent/blackListItem";

export const ScreenBlackList: React.FC = () => {
  const history = useHistory();
  const attributes: IUrlBlackList = React.useMemo(() => (queryString.parse(window.location.search)), [window.location.search]);
  const loading = useSelector((stateRedux: IAppState) => stateRedux.blackList.loading);
  const data = useSelector((stateRedux: IAppState) => stateRedux.blackList.data.items);
  const countPage = useSelector((stateRedux: IAppState) => (Math.ceil(stateRedux.blackList.data.count / amountOfFirms)));
  const [pageNumber, setPageNumber] = React.useState(1);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(BlackListActionAsync.LoadBlackList(attributes.page || 1));
    setPageNumber(attributes.page || 1);
  }, [attributes]);

  const openEnterprises = (id: string, name: string) => {
    return () => {
      const newName = replaceTranslate(name);
      history.push("/" + id + "/" + newName + ".html");
    };
  };
  const GetPage = async (event: number) => {
    attributes.page = event;
    history.push("/blackList?page=" + event);
  };

  const elemBlackList = React.useMemo(() => {
    return data && data.map((items) => (
      <div key={items.webId} onClick={openEnterprises(items.webId, items.name)}>
        <BlackListItem key={items.id} {...items} />
      </div>));
  }, [data]);

  return (
    <>
      <MetaTags>
        <title>Чёрный список предприятий</title>
        <meta name="description" content={``}/>
        <meta name="keywords" content={``}/>
      </MetaTags>
      <Search/>
      <Container className="container">
        <Title>Чёрный список предприятий</Title>
        <Description>
          Внимание! Предприятия попадают в «Черный список» по разным причинам.
          <br/>
          Для решения вопроса об исключении вашего предприятия из &quot;Черного списка&quot;
          <br/>
          обратитесь в редакцию Справочной системы по телефонам:
          <br/>
          0 (775) 8-11-11 (Viber, Telegram, WhatsApp, ICQ), 0 (533) 8-65-65.
          <br/>
          Мы готовы к диалогу и выслушаем даже самую невероятную историю.
        </Description>
        {loading
          ? "Загрузка..."
          : elemBlackList
        }
        <Pagination activePage={Number(pageNumber)} countPage={countPage || 0} onClick={GetPage}/>
      </Container>
    </>
  );
};

const Container = styled.div`
    margin-bottom: 40px;
    margin-top: 26px;
`;

const Title = styled.div`
    font-family: FiraSansMedium, sans-serif;
    font-size: 36px;
    color: #3e4c5b;
`;

const Description = styled.div`
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    color: #000;
    margin: 15px 0;
`;
