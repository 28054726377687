import React from "react";
import {MetaTags} from "react-meta-tags";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {UrlBackend} from "../common/constants";
import {IAppState} from "../core/store/mainReducer";
import {CalendarCelebrateData} from "../modules/calendar/calendarActionAsync";
import * as pdf from "../shared/img/pdf.png";
import {Search} from "./componentAllPage/search/search";

// export const CalendarFilesState = createSelector(
//   (state: IAppState) => state.calendarReducer.loading,
//   (state: IAppState) => state.calendarReducer.allFiles,
//   (loading, allFiles) => ({loading, allFiles})
// );
//
// export const CalendarFilesDispatch = (dispatch: IAppDispatch) => ({
//   initialFiles: () => dispatch(CalendarCelebrateData.LoadAllFile())
// });
//
// interface IProps extends ReturnType<typeof CalendarFilesState>, ReturnType<typeof CalendarFilesDispatch> {
// }

export const ScreenCalendar: React.FC = () => {
  const loading = useSelector((state: IAppState) => state.calendarReducer.loading);
  const allFiles = useSelector((state: IAppState) => state.calendarReducer.allFiles);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(CalendarCelebrateData.LoadAllFile());
  }, []);

  const itemCalendar = React.useMemo(() => {
    return allFiles.map((item) => (
      <>
        <Title>{`Производственный календарь на ${item.year} год`}</Title>
        {item.fiveDaysCalendarPath && <Block>
            <ButtonContainer>
                <Image src={pdf}/>
                <Description>При пятидневной рабочей неделе</Description>
            </ButtonContainer>
            <ButtonContainer>
                <Button href={UrlBackend + "/" + item.fiveDaysCalendarPath} target={"_blank"}> Открыть </Button>
            </ButtonContainer>
        </Block>}
        {item.sixDaysCalendarPath && <Block>
            <ButtonContainer>
                <Image src={pdf}/>
                <Description>При шестидневной рабочей неделе</Description>
            </ButtonContainer>
            <ButtonContainer>
                <Button href={UrlBackend + "/" + item.sixDaysCalendarPath} target={"_blank"}> Открыть </Button>
            </ButtonContainer>
        </Block>}
      </>
    ));
  }, [allFiles]);

  return (
    <Container>
      <MetaTags>
        <title>Календарь Приднестровья</title>
        <meta name="description" content={``}/>
        <meta name="keywords" content={``}/>
      </MetaTags>
      <Search/>
      <div className="container">
        {loading ? "Загрузка..." : itemCalendar}
      </div>
    </Container>
  );
};
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    @media (min-width: 420px) {
        margin-bottom: 400px;
    }
`;
const Title = styled.div`
    font-family: SFUITextMedium, sans-serif;
    font-size: 36px;
    font-weight: 500;
    color: #3e4c5b;
    margin-bottom: 40px;
    @media (max-width: 420px) {
        font-size: 20px;
        text-align: center;
        margin-top: 20px;
    }
`;
const Image = styled.img`
    width: auto;
    height: auto;
    margin-right: 26px;
`;
const Block = styled.div`
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    @media (max-width: 420px) {
        flex-direction: column;
    }
`;
const Description = styled.div`
    font-family: SFUITextMedium, sans-serif;
    font-size: 20px;
    color: #495664;
    text-decoration: #495664;
    margin-right: 30px;
    cursor: default;
    user-select: none;
`;
const Button = styled.a`
    font-family: SFUITextMedium, sans-serif;
    font-size: 16px;
    color: #0f77ff;
    margin-right: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 5px 15px;
    :hover {
        text-decoration: none;
        color: #0f77ff;
        cursor: pointer;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 420px) {
        margin-top: 16px;
    }
`;
