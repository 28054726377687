import queryString from "query-string";
import React from "react";
import {MetaTags} from "react-meta-tags";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {amountOfFirms, amountOfNews} from "../common/constants";
import {
  createUrlPaginationDirectory,
  createUrlSearchDirectory,
  IUrlPageDirectory
} from "../common/createURL/urlPageDirectory";
import {replaceTranslate} from "../common/replaceTranslate";
import {IAppState} from "../core/store/mainReducer";
import {styled} from "../core/styledComponents";
import {SearchDirectory} from "../modules/directorySearch/directorySearchActionAsync";
import {Pagination} from "./componentAllPage/pagination/pagination";
import {Search} from "./componentAllPage/search/search";
import {AdvertisingSearchNoBootstrap} from "./screenResultSearchCompanyComponent/advertisingSearchNoBootstrap";
import {ScreenSitesListFilter} from "./screenSitesListComponent/filter";
import {
  SiteListNotItem,
  SiteListRightColumn,
  SitesList,
  SitesListContainer,
  SitesListContentContainer,
  SitesListItem,
  SitesListMain,
  SitesListTitle
} from "./screenSitesListComponent/styled";

export const ScreenSitesList: React.FC = () => {
  const state = useSelector((stateRedux: IAppState) => stateRedux.directorySearch.data);
  const loading = useSelector((stateRedux: IAppState) => stateRedux.directorySearch.loading);
  const history = useHistory();
  const attributes: IUrlPageDirectory = React.useMemo(() => (queryString.parse(window.location.search)), [window.location.search]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SearchDirectory.DirectoryAlphabetFilter(attributes.page || 1, amountOfFirms, attributes.filter || ""));
  }, [attributes]);


  const translateUrl = (id: string, name: string) => {
    const newName = replaceTranslate(name);
    return "/" + id + "/" + newName + ".html";
  };

  const setActivePage = async (value: number) => {
    const params: IUrlPageDirectory = {
      ...attributes,
      page: value
    };
    history.push(createUrlPaginationDirectory(params));
  };

  const updateFromAlphabet = async (value: string) => {
    const params: IUrlPageDirectory = {
      page: 1,
      filter: value
    };
    history.push(createUrlSearchDirectory(params));
  };

  const site = React.useMemo(() => (
    state.items && state.items.length !== 0
      ? <>
        <SitesListMain>
          <SitesList>
            {(state.items || []).map((key, index) => (
              <SitesListItem key={index}>
                <Link href={translateUrl(key.webId, key.name)}>
                  {key.name}
                </Link>
              </SitesListItem>
            ))}
          </SitesList>
        </SitesListMain>
        <SitesListMain>
          <Pagination
            activePage={Number(attributes.page) || 1}
            countPage={Math.ceil(state.count / amountOfNews)}
            onClick={setActivePage}
          />
        </SitesListMain>
      </>
      : <SitesListMain>
        <SitesList> <SiteListNotItem>Результатов не найдено</SiteListNotItem> </SitesList>
      </SitesListMain>
  ), [state.items]);

  return (
    <>
      <MetaTags>
        <title>Сайты предприятий</title>
        <meta name="description" content={``}/>
        <meta name="keywords" content={``}/>
      </MetaTags>
      <Search/>
      <SitesListContainer className={"container"}>
        <SitesListContentContainer>
          <SiteListRightColumn>
            <SitesListTitle>
              <h1>Список сайтов предприятий</h1>
              <ScreenSitesListFilter filterFunc={updateFromAlphabet}/>
            </SitesListTitle>
            {loading ? "Загрузка..." : site}
          </SiteListRightColumn>
          <AdvertisingSearchNoBootstrap/>
        </SitesListContentContainer>
      </SitesListContainer>
    </>
  );
};

const Link = styled.a`
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    color: ${(props) => props.theme.colors.slate};
    &:hover {
        color: ${(props) => props.theme.colors.blueAccent};
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }
`;
