import queryString from "query-string";
import React from "react";
import {MetaTags} from "react-meta-tags";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import styled from "styled-components";
import {UrlBackend} from "../common/constants";
import {createUrlPageNews, IUrlPageNews} from "../common/createURL/urlPageNews";
import {IUrlPageNewsItems} from "../common/createURL/urlPageNewsItems";
import {IAppState} from "../core/store/mainReducer";
import {NewsItemData} from "../modules/newsItem/newsItemActionAsync";
import {GetSimilarNews} from "../modules/similarNews/similarNewsActionAsync";
import {IconFacebookCircle} from "../shared/icons/IconFacebook";
import {IconOdnoclasnikiCircle} from "../shared/icons/IconOdnoklasniki";
import {IconTwitter} from "../shared/icons/IconTwitter";
import {IconVKCircle} from "../shared/icons/IconVK";
import {Title} from "../ui/title";
import {Search} from "./componentAllPage/search/search";
import {LinkBar} from "./screenNewsComponent/linkBar";
import {SimilarNews} from "./screenNewsItemComponent/similarNews";

export const ScreenNewsItem: React.FC = () => {
  const data = useSelector((stateRedux: IAppState) => stateRedux.newsItem.data);
  const loading = useSelector((stateRedux: IAppState) => stateRedux.newsItem.loading);
  const dataSimilar = useSelector((stateRedux: IAppState) => stateRedux.similar.data);
  const history = useHistory();
  const attributes: IUrlPageNewsItems = React.useMemo(() => (queryString.parse(window.location.search)), [window.location.search]);
  const [text, setText] = React.useState("");

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(NewsItemData.LoadItem(attributes.id || ""));
  }, [attributes]);

  React.useEffect(() => {
    if (data.category) {
      dispatch(GetSimilarNews.LoadItem(data.category.id, attributes.id || ""));
      parseDescription();
    }
  }, [data.category]);

  const parseDescription = async () => {
    if (data.description && data.description.length !== 0) {
      const textArray = data.description.split(" ");
      const textReplace = textArray.map((item) => {
        if (item.indexOf(`frameBorder=\"0\"></iframe>`) > -1) {
          return `frameBorder="0" allowfullscreen></iframe>`;
        }
        return item;
      });

      setText(textReplace.join(" "));
    }
  };

  const updateCurrentLink = (category?: string) => {
    const queryCurrent: IUrlPageNews = {};
    queryCurrent.category = category;
    queryCurrent.page = 1;
    history.push("../news" + createUrlPageNews(queryCurrent));
  };
  const clearCurrentLink = React.useCallback(() => {
    updateCurrentLink();
  }, []);

  return loading
    ? <div style={{height: "2000px"}}/>
    : <Container className="container">
      <MetaTags>
        <title>{data.title}</title>
        <meta name="description" content={data.title}/>
        <meta name="keywords" content={data.title}/>

        {/*Метя теги для facebook и instagram*/}
        <meta property="og:title" content={data.title}/>
        <meta property="og:description" content={data.title}/>
        <meta property="og:image" content={window.location.host+"/"+data.imagePath}/>
      </MetaTags>
      <Title cursorPointer={true} onClick={clearCurrentLink}>Новости </Title>
      <Search placeholder={"Поиск по новостям"} hideClassContainer={true}/>
      <LinkBar updateCurrentLink={updateCurrentLink}/>
      <div>
        <NewsBlock className={"col-md-8"} itemScope={true} itemType={"https://schema.org/NewsArticle"}>
          <meta itemProp="identifier" content={data.title}/>
          <TitleNews itemProp="headline">{data.title}</TitleNews>
          {data.imagePath && data.imagePath.length !== 0 ? <Image src={`${UrlBackend}/${data.imagePath}`}/> : <></>}
          <FullContent
            itemProp="articleBody"
            dangerouslySetInnerHTML={{
              __html: text.replace(new RegExp("_self", "g"), "_blank")
            }}
          />
          <div style={{display: "none"}}>
            <div itemProp="about">{data.category ? data.category.name : ""}</div>
            <time
              itemProp="datePublished"
              dateTime={data.createdUtc && data.createdUtc.toString()}
            >
              {data.createdUtc}
            </time>
            <div itemProp="author">Бизнес-портал Приднестровья</div>
          </div>
          <ShareNews>
            <p>Поделиться новостью</p>
            <a
              target={"_blank"}
              href={`https://www.facebook.com/sharer.php?src=sp&u=${window.location.href}`}
              style={{marginRight: "16px"}}
            >
              <IconFacebookCircle width={"34px"} height={"34px"}/>
            </a>
            <a
              target={"_blank"}
              href={`https://connect.ok.ru/offer?url=${window.location.href}?text=Seven+Seas%2C+%D0%9E%D0%9E%D0%9E`}
              style={{marginRight: "16px"}}
            >
              <IconOdnoclasnikiCircle width={"34px"} height={"34px"}/>
            </a>
            <a
              target={"_blank"}
              href={`http://vk.com/share.php?url=${window.location.href}&title=${data.title}&description=${data.description}&image=${data.imagePath}&noparse=true`}
              style={{marginRight: "16px"}}
            >
              <IconVKCircle width={"34px"} height={"34px"}/>
            </a>
            <a
              target={"_blank"}
              href={`https://twitter.com/share?url=${window.location.href}&text=${data.title}`}
              style={{marginRight: "16px"}}
            >
              <IconTwitter/>
            </a>
          </ShareNews>
        </NewsBlock>
        <div className={"col-md-4"}>
          <SimilarNews data={dataSimilar}/>
        </div>
      </div>
    </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;


const TitleNews = styled(Title)`
  margin-top: 0;
  margin-bottom: 16px;
`;

const FullContent = styled.div`
  font-family: SFUIText, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e4c5b;
  margin-bottom: 46px;
  white-space: pre-line;
  @media (max-width: ${({theme}) => theme.mobileWidth}px) {
    margin-bottom: 16px;
  }

  p {
    margin: 0;
  }
  ul {
    margin: 0;
    line-height: 0;
  
    li {
      line-height: 1.4;
    }
  }
`;

const Image = styled.img`
  height: auto;
  margin-bottom: 24px;
  width: 100%;
  @media (max-width: 420px) {
    margin-bottom: 16px;
  }
`;

const ShareNews = styled.div`
  font-family: SFUIText, sans-serif;
  font-size: 20px;
  text-align: start;
  color: #3e4c5b;
  display: contents;
  float: left;
  img {
    margin-right: 16px;
    cursor: pointer;
  }
  a {
    cursor: pointer;
    color: #a1a9b3;
    :hover {
      text-decoration: none;
      color: #0f77ff;
    }
  }
`;

const NewsBlock = styled.div`
  font-size: 32px;
  margin-bottom: 16px;

  @media (max-width: 420px) {
    padding: 0;
    font-family: SF-UIText, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: #3e4c5b;
  }
`;
