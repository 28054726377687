import {INewsFilter} from "../../api/dto/NewsFilter.g";
import {ISortParamDto} from "../../api/dto/SortParamDto.g";
import {NewsApiRequest} from "../../api/NewsApiRequest.g";
import {IThunkAction} from "../../core/store/mainReducer";
import {initialNews} from "./newsSearchAction";
import {INewsSearchState} from "./newsSearchReducer";

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const SearchNewsFromTag = {
  InitializationNews(
    pageNumber: number,
    pageSize: number,
    categoryId?: string,
    city?: string,
    title?: string
  ): IThunkAction {
    const api = new NewsApiRequest();

    const result: INewsSearchState = {
      data: {count: 0, items: []},
      error: false,
      loading: false
    };

    return async (dispatch, getState) => {
      const cityId = getState().search.city.filter((item) => item.title === city);
      const reqest: INewsFilter = {
        title,
        categoryId,
        cityId: cityId.length !== 0 ? (cityId[0].id === 0 ? undefined : cityId[0].id) : undefined,
        sortParams: {
          fieldName: "CreatedUtc",
          isDesc: true
        } as ISortParamDto
      };

      dispatch(initialNews.started(""));
      try {
        result.data = await api.getByFilter(pageNumber, pageSize, reqest);
        dispatch(initialNews.done({params: "", result}));
      } catch (error) {
        dispatch(initialNews.failed({params: "", error}));
      }
    };
  }
};
