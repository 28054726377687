import {actionCreatorFactory} from "typescript-fsa";
import {IFirm} from "../../api/dto/Firm.g";
import {IPageResult} from "../../api/dto/PageResult.g";

export const charactersActionCreator = actionCreatorFactory("DirectorySearch");

export const searchDirectory = charactersActionCreator.async<string, IPageResult<IFirm>, Error>("SEARCH_DIRECTORY");

export const initialization = charactersActionCreator.async<string, IPageResult<IFirm>, Error>("START_PARAM");

export const alphabetFilter = charactersActionCreator.async<string, IPageResult<IFirm>, Error>("FILTER_ALPHABET");

export const updateValueAlphabetFilter = charactersActionCreator<string>("FILTER_VALUE");

export const searchSubCategories = charactersActionCreator.async<string, IPageResult<IFirm>, Error>("SUB");
