import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {IAppState} from "src/core/store/mainReducer";
import {getCookie} from "../common/getCookie";
import {CompanyItemData} from "../modules/companyItem/companyItemActionAsync";
import {ScreenContainer} from "../ui/ScreenContainer";
import {Search} from "./componentAllPage/search/search";
import {PageCompany} from "./screenCompanyComponent/pageCompany";
import {SimilarOrganization} from "./screenCompanyComponent/similarOrganization";
import {ScreenErrorPage} from "./screenErrorPage";

export const ScreenCompany: React.FC = () => {
  const loading = useSelector((state: IAppState) => state.companyItem.loading);
  const data = useSelector((state: IAppState) => state.companyItem.data);
  const similarOrganization = useSelector((state: IAppState) => state.companyItem.similarOrganization);
  const {id} = useParams();

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (id) {
      dispatch(CompanyItemData.LoadItem(id));

      if (!getCookie("_pika_pika")) {
        dispatch(CompanyItemData.IncreaseCountOfFirmViews(id));
        document.cookie = "_pika_pika=pikachu; max-age=25200";
      }
    }
  }, []);

  React.useEffect(()=>{
    if(id && data.id && !data.isPriority){
      dispatch(CompanyItemData.LoadSimilarOrganization(id, data.city.id));
    }
  },[data]);

  return (
    <ScreenContainer>
      <Search/>
      {loading
        ? "Загрузка..."
        : <>
          {data && data.id ? <PageCompany data={data}/> : <ScreenErrorPage/>}
          {similarOrganization.length > 0 && <SimilarOrganization similarOrganization={similarOrganization}/>}
        </>}
    </ScreenContainer>
  );
};
