import React from "react";
import {useHistory} from "react-router";
import {styled} from "../../../core/styledComponents";
import {theme} from "../../../core/theme";
import {IconChevron} from "../../../shared/icons/IconChevron";
import {Span} from "../../../ui/span";

export const ButtonAllNews: React.FC = () => {
  const history = useHistory();
  const onClick = () => {
    history.push("/news");
  };

  return (
    <Container onClick={onClick}>
      <Text>Смотреть все новости</Text>
      <IconChevronWideScreen>
        <IconChevron color={theme.colors.blueAccent}/>
      </IconChevronWideScreen>
      <IconChevronNarrowScreen>
        <IconChevron color={theme.colors.mainGrey}/>
      </IconChevronNarrowScreen>
    </Container>
  );
};

const Container = styled.div`
    display: flex;
    border-radius: 136px;
    background-color: #fdfcfa;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    padding: 0 31px;
    cursor: pointer;
    @media (max-width: 991px) {
        align-self: center;
    }
    @media (max-width: 420px) {
        padding: 0 16px;
        align-self: flex-start;
        margin-top: 20px;
    }
    @media (min-width: 992px) {
        margin-left: 15px;
    }
`;

const Text = styled(Span)`
    font-size: 18px;
    font-family: SFUITextMedium, sans-serif;
    color: ${(props) => props.theme.colors.blueAccent};
    margin-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    @media (max-width: 420px) {
        align-self: center;
        font-size: 16px;
        color: ${(props) => props.theme.colors.mainGrey};
    }
`;

const IconChevronWideScreen = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 420px) {
        display: none;
    }
`;
const IconChevronNarrowScreen = styled.div`
    display: flex;
    align-items: center;
    @media (min-width: 421px) {
        display: none;
    }
`;
