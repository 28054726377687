import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ICategory} from "../../api/dto/Category.g";
import {IAppState} from "../../core/store/mainReducer";
import {styled} from "../../core/styledComponents";
import {InitialCategories} from "../../modules/newsSearch/newsCategory/newsCategoryActionAsync";

interface IProps {
  currentLink?: string;
  updateCurrentLink: (category?: string) => void;
}

export const LinkBar: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(InitialCategories.InitialCategory());
  }, []);

  const allCategories: ICategory[] = useSelector((state: IAppState) => (state.newsCategory.allCategories));

  const onClick = (currentKey: string) => {
    return () => {
      props.updateCurrentLink(props.currentLink === currentKey ? undefined : currentKey);
    };
  };

  const links = React.useMemo(() => {
    return allCategories.map((item) =>
      <MyLink
        onClick={onClick(item.id)}
        color={props.currentLink === item.id ? "#0f77ff" : "#a1a9b3"}
        key={item.id}
      >
        {item.name}
      </MyLink>
    );
  }, [allCategories, props]);

  return <Container>{links}</Container>;

};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: SFUITextMedium, sans-serif;
  margin-bottom: 40px;
  flex-wrap: wrap;

  @media (max-width: 720px) {
    overflow-x: scroll;
    width: 90vw;
    justify-content: flex-start;
  }
  @media (max-width: 420px) {
    display: none;
  }
`;

const MyLink = styled.div<{color: string}>`
  text-decoration: none;
  margin: 0 30px 10px 0;
  color: ${(props) => props.color};
  font-size: 18px;
  cursor: pointer;
  font-family: SFUITextMedium, sans-serif;
  :hover,
  :focus {
    text-decoration: none;
    font-family: SFUITextMedium, sans-serif;
    color: ${(props) => props.color};
  }
`;
