import * as moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {ICurrency} from "../../../api/dto/Currency.g";
import {styled} from "../../../core/styledComponents";
import {HeaderWithScroll} from "../../../ui/HeaderWithScroll";
import {Title} from "../../../ui/title";
import {ExchangeRate} from "./components/ExchangeRate";
import {CalculatDispatch, CalculatState} from "./selectors";

let n = 0;

interface IProps extends ReturnType<typeof CalculatState>, ReturnType<typeof CalculatDispatch> {
}

interface IState {
  currentBankIndex: number;
}

class ExchangeRatesConstructor extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {currentBankIndex: 0};
  }

  public componentDidMount() {
    this.props.initialExchange();
  }

  public render(): JSX.Element {
    const currencies =
      this.props.data && this.props.data.length !== 0
        ? this.props.data[this.state.currentBankIndex].currencies
        : ([] as ICurrency[]);

    return this.props.data && this.props.data.length !== 0 ? (
      <Container>
        <TitleUSD>Курсы валют</TitleUSD>
        <Description>
          с {moment(this.props.data[this.state.currentBankIndex].fromDate).format("DD.MM.YYYY")}
        </Description>
        <HeaderWithScroll title={this.props.data[this.state.currentBankIndex].title}
                          onClickLeft={this.prevBank}
                          onClickRight={this.nextBank}/>
        {currencies.map(
          (item) =>
            item.currencyCode.toLocaleUpperCase() === "USD" && (
              <ExchangeRate key={item.buyCourse}
                            first={true}
                            sellCourse={item.sellCourse}
                            buyCourse={item.buyCourse}
                            currencyCode={item.currencyCode}
                            bankName={this.props.data[this.state.currentBankIndex].title}/>
            )
        )}
        {currencies.map((item) => item.currencyCode.toLocaleUpperCase() === "EUR" && <ExchangeRate key={item.buyCourse}
                                                                                                   first={false}
                                                                                                   sellCourse={item.sellCourse}
                                                                                                   buyCourse={item.buyCourse}
                                                                                                   currencyCode={item.currencyCode}
                                                                                                   bankName={this.props.data[this.state.currentBankIndex].title}/>
        )}
        {currencies.map((item) => item.currencyCode.toLocaleUpperCase() === "RUB" && <ExchangeRate key={item.buyCourse}
                                                                                                   first={false}
                                                                                                   sellCourse={item.sellCourse}
                                                                                                   buyCourse={item.buyCourse}
                                                                                                   currencyCode={item.currencyCode}
                                                                                                   bankName={this.props.data[this.state.currentBankIndex].title}/>
        )}
        {currencies.map((item) => item.currencyCode.toLocaleUpperCase() === "UAH" && <ExchangeRate key={item.buyCourse}
                                                                                                   first={false}
                                                                                                   sellCourse={item.sellCourse}
                                                                                                   buyCourse={item.buyCourse}
                                                                                                   currencyCode={item.currencyCode}
                                                                                                   bankName={this.props.data[this.state.currentBankIndex].title}/>
        )}
        {currencies.map((item) => item.currencyCode.toLocaleUpperCase() === "MDL" && <ExchangeRate key={item.buyCourse}
                                                                                                   first={false}
                                                                                                   sellCourse={item.sellCourse}
                                                                                                   buyCourse={item.buyCourse}
                                                                                                   currencyCode={item.currencyCode}
                                                                                                   bankName={this.props.data[this.state.currentBankIndex].title}/>
        )}
      </Container>
    ) : (
      <></>
    );
  }

  private prevBank = () => {
    const {currentBankIndex} = this.state;
    const prevIndex = currentBankIndex === 0 ? this.props.data.length - 1 : currentBankIndex - 1;
    this.setState({currentBankIndex: prevIndex});
    if (n === 0) {
      n = this.props.data.length;
    } else {
      n = n - 1;
    }
  };
  private nextBank = () => {
    const {currentBankIndex} = this.state;
    const nextIndex = currentBankIndex === this.props.data.length - 1 ? 0 : currentBankIndex + 1;
    this.setState({currentBankIndex: nextIndex});
    if (n === this.props.data.length + 1) {
      n = 0;
    } else {
      n = n + 1;
    }
  };
}

export const ExchangeRates = connect(
  CalculatState,
  CalculatDispatch
)(ExchangeRatesConstructor);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 766px) {
    margin-top: 30px;
  }
`;
const Description = styled.div`
  margin-bottom: 32px;
  width: 97px;
  height: 19px;
  font-family: SFUITextMedium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #a1a9b3;
  white-space: nowrap;
  @media (max-width: 420px) {
    margin-left: 15px;
  }
`;

const TitleUSD = styled(Title)`
  font-size: 24px;
  @media (max-width: 1199px) {
    font-size: 42px;
    margin-bottom: 6px;
  }
  @media (max-width: 420px) {
    font-size: 20px;
  }
`;
