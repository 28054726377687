import React from "react";

interface IProps {
    width: string;
    height: string;
}

/* tslint:disable:max-line-length */
export const IconViber = ({width, height}: IProps) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29 32"
        width={width}
        height={height}>
        <title>Shape</title>
        <desc>Created with Sketch.</desc>
        <g id="Symbols">
            <g id="Footer" transform="translate(-165.000000, -217.000000)">
                <g id="СоцСети" transform="translate(150.000000, 185.000000)" />
                <g id="icons8-viber_filled" transform="translate(164.000000, 217.000000)">
                    <path
                        id="Shape"
                        fill={"#9013FE"}
                        d="M28.5,7.8c-0.5-1.9-1.6-3.4-3.1-4.4c-1.9-1.3-4.1-1.8-6.1-2.1c-2.7-0.4-5.1-0.5-7.3-0.2
				C9.8,1.4,8.2,1.8,6.7,2.5C3.9,3.8,2.1,6,1.6,8.9c-0.3,1.4-0.4,2.7-0.5,3.9c-0.2,2.8,0,5.3,0.6,7.6c0.6,2.3,1.8,3.9,3.5,4.9
				c0.4,0.3,1,0.5,1.5,0.7c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0.1,0.2,0.1,0.2,0.3c0,1.6,0,4.7,0,4.7l0,0.7h1.2l0.2-0.2
				c0.1-0.1,3.1-3,4.2-4.2l0.1-0.2c0.2-0.2,0.2-0.2,0.4-0.2c1.4,0,2.9-0.1,4.4-0.2c1.8-0.1,3.8-0.3,5.7-1.1c1.8-0.7,3-1.9,3.8-3.5
				c0.8-1.6,1.3-3.4,1.5-5.4C29.5,13.6,29.3,10.6,28.5,7.8z M22.1,21.5c-0.4,0.7-1.1,1.2-1.9,1.5c-0.6,0.2-1.1,0.2-1.7-0.1
				c-4.7-2-8.4-5.1-10.8-9.6c-0.5-0.9-0.9-1.9-1.3-2.9C6.4,10.3,6.4,10,6.4,9.8c0-1.6,1.2-2.5,2.5-2.7C9.3,7,9.7,7.1,10.1,7.5
				c1,0.9,1.7,2,2.3,3.2c0.3,0.5,0.1,1-0.3,1.4c-0.1,0.1-0.2,0.2-0.3,0.2c-1,0.7-1.1,1.3-0.6,2.4c0.9,1.9,2.4,3.1,4.3,3.9
				c0.5,0.2,1,0.1,1.4-0.3c0.1-0.1,0.1-0.1,0.1-0.2c0.7-1.2,1.8-1.1,2.8-0.4c0.7,0.5,1.3,1,1.9,1.4C22.8,19.9,22.7,20.5,22.1,21.5z
				 M15.9,9.1c-0.2,0-0.4,0-0.7,0.1c-0.4,0.1-0.7-0.2-0.8-0.6c-0.1-0.4,0.2-0.7,0.6-0.8c0.3-0.1,0.6-0.1,0.9-0.1
				c2.9,0,5.3,2.4,5.3,5.3c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.3,0.6-0.7,0.6c0,0-0.1,0-0.1,0c-0.4-0.1-0.6-0.4-0.6-0.8
				c0-0.2,0.1-0.4,0.1-0.7C19.9,10.8,18.1,9.1,15.9,9.1z M19.2,13.1c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7c0-1.1-0.9-2-2-2
				c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7C17.7,9.7,19.2,11.3,19.2,13.1z M23,14.6c-0.1,0.3-0.4,0.5-0.7,0.5
				c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.6-0.4-0.5-0.8c0.1-0.4,0.1-0.9,0.1-1.3c0-3.3-2.7-5.9-5.9-5.9c-0.4,0-0.9,0.1-1.3,0.1
				c-0.4,0.1-0.7-0.1-0.8-0.5c-0.1-0.4,0.1-0.7,0.5-0.8c0.5-0.1,1.1-0.2,1.6-0.2c4,0,7.3,3.3,7.3,7.3C23.2,13.5,23.2,14.1,23,14.6z"
                    />
                </g>
            </g>
        </g>
    </svg>
);
