import React from "react";

export const IconScrollTop = () => (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.0151 11.0018C19.5051 10.5118 19.5051 9.72185 19.0151 9.23185L10.7051 0.921845C10.3151 0.531845 9.68505 0.531845 9.29505 0.921845L0.985054 9.23185C0.495054 9.72185 0.495054 10.5118 0.985054 11.0018C1.47505 11.4918 2.26505 11.4918 2.75505 11.0018L10.0051 3.76185L17.2551 11.0118C17.7351 11.4918 18.5351 11.4918 19.0151 11.0018Z"
            fill="white"
        />
    </svg>
);

// "#A1A9B3"
