import React from "react";
import {styled} from "../../../../core/styledComponents";
import {Span} from "../../../../ui/span";

export const VisitCounter: React.FC = () => {
  React.useEffect(() => {
    const live = document.getElementById("counterLive");
    if (live) {
      live.innerHTML =
        "<a href=\"//www.liveinternet.ru/click\" target=\"_blank\"><img src=\"//counter.yadro.ru/hit?t14.2;r" +
        escape(document.referrer) +
        (typeof screen === "undefined"
          ? ""
          : ";s" +
          screen.width +
          "*" +
          screen.height +
          "*" +
          (screen.colorDepth ? screen.colorDepth : screen.pixelDepth)) +
        ";u" +
        escape(document.URL) +
        ";h" +
        escape(document.title.substring(0, 150)) +
        ";" +
        Math.random() +
        "\" alt=\"\" title=\"LiveInternet: показано число просмотров за 24 часа, посетителей за 24 часа и за сегодня\" width=\"88\" height=\"31\"></a>";
    }
  }, []);

  return <Text id={"counterLive"}/>;
};

const Text = styled(Span)`
    color: ${(props) => props.theme.colors.mainGrey};
    font-size: 12px;
    text-align: center;
`;
