import React from "react";
import {IOption} from "../components/screenAddCompanyComponent/validation";
import {styled} from "../core/styledComponents";
import {IconArrowSearch} from "../shared/icons/IconArrowSearch";

interface IProps {
  options: IOption[];
  onSelect: (value: IOption) => void;
  defaultValue: string;
}

export const SelectCustom: React.FC<IProps> = (props) => {
  const [value, setValue] = React.useState(props.defaultValue);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("click", closeList);
    return () => document.removeEventListener("click", closeList);
  });

  const openList = () => {
    setOpen(true);
  };

  const closeList = () => {
    if (open) {
      setOpen(false);
    }
  };

  const onSelect = (option: IOption) => {
    setValue(option.label);
    props.onSelect(option);
    closeList();
  };

  const options = React.useMemo(() => {
    return <List open={open}>
      {props.options.length > 0
        ? props.options.map(item =>
          /*tslint:disable-next-line*/
          <ListItem active={item.label === value} key={item.value} onClick={() => onSelect(item)}>
            {item.label}
          </ListItem>)
        : <EmptyList>Список пуст</EmptyList>
      }
    </List>;
  }, [props, open]);

  return <SelectContainer>
    <Field onClick={openList} open={open}>
      <span style={{lineHeight:"1"}}>{value ? value : "..."}</span>
      <IconArrowSearch/>
    </Field>
    {options}
  </SelectContainer>;

};

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;
const Field = styled.div<{open: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${(props) => props.open ? "8px 8px 0 0" : "8px"};
  padding: 14px;
  background: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);;
  
  font-size: 18px;
  cursor: pointer;
`;
const List = styled.ul<{open: boolean;}>`
  display:${(props) => props.open ? "block" : "none"}  ;
  position: absolute;
  width: 100%;
  background: white;
  box-sizing: border-box;
  list-style: none;
  z-index: 10;
  padding: 0;
  margin: 0;
  top: 41px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 11px 9px 0;
  
  max-height: 400px;
  overflow: auto;
  
  border-radius: 0 0 8px 8px;
`;

const ListItem = styled.li<{active?: boolean}>`
  font-family: SFUIText, sans-serif;
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
  color: ${(props) => props.active ? "#ffffff" : "#000000"} ;
  background: ${(props) => props.active ? "#0f77ff" : "#ffffff"} ;
  &:hover {
    background: #0f77ff;
    color: #ffffff;
  }
`;

const EmptyList = styled.div`
  color: #a1a9b3;
  width: 100%;
  text-align: center;
  padding: 20px;
`;
