import React from "react";
import {MetaTags} from "react-meta-tags";
import styled from "styled-components";
import {Search} from "./componentAllPage/search/search";
import {PageContacts} from "./screenContactsComponent/pageContacts";

export const ScreenContacts: React.FC = () => {
  return (
    <Container>
      <MetaTags>
        <title>Контакты : Бизнес-справочник Приднестровья</title>
        <meta name="description" content={`Контакты справочника приднестровья`}/>
        <meta name="keywords" content={`Контакты справочника приднестровья`}/>
      </MetaTags>
      <Search/>
      <PageContacts/>
    </Container>
  );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
`;
