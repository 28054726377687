import queryString from "query-string";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {IUrlRubricator} from "../../../../common/createURL/urlRubricator";
import {IAppState} from "../../../../core/store/mainReducer";
import {styled} from "../../../../core/styledComponents";
import {GetRubricator} from "../../../../modules/rubricator/rubricatorActionAsync";
import {ScreenContainer} from "../../../../ui/ScreenContainer";
import {Title as BaseTitle} from "../../../../ui/title";
import {RubricatorItem} from "./rubricatorItem";

export const RubricatorMobile: React.FC = () => {
    const searchResult = useSelector((state: IAppState) => state.rubricator.baseData);
    const history = useHistory();
    const attributes: IUrlRubricator = React.useMemo(() => queryString.parse(history.location.search), [
        history.location.search
    ]);

    const dispatch = useDispatch();
    React.useEffect(() => {
        if (attributes.id) {
            dispatch(GetRubricator.SubCategory(attributes.id));
        } else if (attributes.active) {
            if (attributes.active === "0") {
                dispatch(GetRubricator.AllCategory());
            } else {
                dispatch(GetRubricator.SubCategory(attributes.active));
            }
        }
    }, [attributes]);

    const categories = React.useMemo(() => {
        return searchResult.map((key) => {
            return (
                <RubricatorItem
                    key={key.id}
                    category={key.name}
                    id={key.id}
                    activeIconPath={key.activeIconPath}
                    iconPath={key.iconPath}
                    categoryName={attributes.name}
                    categoryId={attributes.id}
                />
            );
        });
    }, [searchResult]);

    return (
        <ScreenContainer isWhite={true} style={{background: "#fff"}}>
            <OuterContainer>
                <Title className={"col-lg-12"}>Поиск предприятия в Приднестровье</Title>
                <InnerContainer className={"col-sm-12"} style={{paddingLeft: 0, paddingRight: 0}}>
                    <RubricatorItem
                        key={0}
                        id={"0"}
                        category={"Все категории"}
                        categoryName={attributes.name}
                        categoryId={attributes.id}
                    />
                    {categories}
                </InnerContainer>
            </OuterContainer>
        </ScreenContainer>
    );
};

const OuterContainer = styled.div`
    display: none;
    flex-direction: column;
    @media (max-width: 420px) {
        display: flex;
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1152px;
    background-color: ${(props) => props.theme.colors.white};
    padding: 18px 15px;
    @media (max-width: 420px) {
        padding: 16px;
        flex-direction: column;
    }
`;
const Title = styled(BaseTitle)`
    @media (max-width: 420px) {
        display: flex;
        font-size: 20px;
        font-weight: 600;
        margin-top: 15px;
        color: #3e4c5b;
    }
`;
