export const required = (value: string) => (value ? undefined : "Заполните поле");

// export const email = (value: string) => {
//     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     return value == null || value === "" || re.test(value) ? undefined : "Не email";
// };

export const emailNotNull = (value: string) => {
  const re = /^([a-zA-Z\-0-9]{2,})@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value) ? undefined : "Не email";
};

export const phone = (value: string) => {
  // if (code === "без кода" || code === "") {
  //     return !value ? "Введите номер" : undefined;
  // } else {
  //     if (value) {
  //         return value >= 10000 && value <= 99999 ? undefined : "Неправильно введён телефон";
  //     } else {
  //         return "Введите номер";
  //     }
  // }

  const re = /^\+?([\ \-\:\(\)]?\d+[\ \-\:\(\)]?){5}$/;

  return value && re.test(value) && value.toString().length >= 5
    ? undefined
    : "Телефон должен содержать не менее 5 символов";
};

export const validatePreviewLine = (id: number) => {
  Object.keys(ArrayFormAddCompany).map((name) => {
    ArrayFormAddCompany[name].id < id
      ? (ArrayFormAddCompany[name].showError = true)
      : (ArrayFormAddCompany[name].showError = false);
  });

  return ArrayFormAddCompany;
};

export const ArrayFormAddCompany = {
  name: {
    name: "name",
    description:
      "Вначале укажите то название, по которому потребитель быстрее всего " +
      "сможет Вас найти. Например: «Бурёнка, кафе-бар, ООО ГМО», или «КАРДАН, автомагазин, ООО Шпилька». " +
      "Если у Вашего предприятия нет дополнительных торговых марок, то укажите только название предприятия, например: ЁКЛМН, ООО",
    label: "Название"
  },

  secondName: {
    name: "alias",
    label: "Второе имя",
    description:
      "Второе имя предприятия также необходимо для более быстрого поиска потребителем Вашего" +
      "предприятия. Это может быть аббревиатура, народное имя, и т.д. Например: для Приднестровского" +
      "государственного университета ВТОРЫМ именем является - ПГУ. Если у Вашего предприятия нет" +
      "вышеупомянутого второго имени, то данный пункт не заполняется."
  },
  cityId: {
    name: "city",
    label: "Город или районный центр"
  },
  addressName: {
    name: "addressName",
    label: "Адрес",
    description:
      "Укажите не юридический, а фактический адрес, т.е., где происходит деятельность Вашего предприятия. Если Ваше предприятие находится не в городе, " +
      "который Вы указали ранее, а в районе, где данный город является районным центром, значит, адрес нужно начать с населенного пункта," +
      "например: с. Рашков, ул. Ленина, 123, к. 123."
  },
  phone: {
    name: "phone",
    label: "Телефон",
  },
  "socialNetworks.viber": {
    name: "socialNetworks.viber",
    label: "Viber",
  },
  "socialNetworks.whatsApp": {
    name: "socialNetworks.whatsApp",
    label: "WhatsApp",
  },
  "socialNetworks.telegram": {
    name: "socialNetworks.telegram",
    label: "Telegram",
  },
  fax: {
    name: "fax",
    label: "Факс",
  },
  workHours: {
    name: "workHours",
    checkboxName: "allDay",
    label: "Рабочие часы",
    checkboxLabel: "круглосуточно",
    description: "например: 9:00-18:00, сб. 9:00-14:00"
  },
  email: {
    name: "email",
    label: "E-mail",
    checkboxName: "emailIsShow",
    checkboxLabel: "в закрытом доступе",
    description:
      "ВНИМАНИЕ! Чтобы исключить попадание Вашего e-mail в несанкционированные рассылки почтовых сообщений (СПАМ), укажите - в ОТКРЫТОМ или ЗАКРЫТОМ доступе будет публиковаться Ваш e-mail в печатном издании Справочной системы. Если Вы указываете в ЗАКРЫТОМ доступе, то желающие смогут отправить Вам сообщение только с помощью системы отправки сообщений сайта pmr.md. Таким образом, Ваш e-mail будет использоваться, только конкретным отправителем по прямому назначению и только после Вашего ответа конкретному отправителю Ваш e-mail станет ему известен. Если Вы решите публиковать в ОТКРЫТОМ доступе, то в этом случае в печатном издании e-mail будет опубликован и доступен всем."
  },
  site: {
    name: "site",
    label: "Web-site"
  },
  icq: {
    name: "icq",
    label: "ICQ"
  },
  skype: {
    name: "skype",
    label: "Skype",
  },
  "socialNetworks.facebookUrl": {
    name: "socialNetworks.facebookUrl",
    label: "Facebook",
  },
  "socialNetworks.odkUrl": {
    name: "socialNetworks.odkUrl",
    label: "Одноклассники",
  },
  "socialNetworks.vkUrl": {
    name: "socialNetworks.vkUrl",
    label: "ВКонтакте",
  },
  "socialNetworks.instagramUrl": {
    name: "socialNetworks.instagramUrl",
    label: "Instagram",
  },
  bankId: {
    name: "bankId",
    label: "Банк",
    description: "Укажите банк, обслуживающий Ваше предприятие.",
  },
  founded: {
    name: "founded",
    label: "Дата основания", type: "date",
  },
  workCount: {
    name: "workCount",
    label: "Количество работающих",
  },
  capital: {
    name: "capital",
    label: "Уставной капитал",
  },
  annualTurnover: {
    name: "annualTurnover",
    label: "Годовой оборот компании",
  },
  executives: {

  },
  activities: {

  },
  tagKeywords: {
    name: "tagKeywords",
    description:
      "Введите ключевые слова (теги) для предприятия, с помощью которых Вас можно будет найти через поиск.",
    label: "Ключевые слова"
  },
  contactPerson: {
    name: "contactPerson",
    label: "Контактное лицо",
    description:
      "ФИО, должность, телефон, e-mail (данные для связи со Справочной системой. Эти данные нигде не публикуются). ВНИМАНИЕ! Без заполнения ФИО и телефона контактного лица Анкета для обработки Справочной системой не принимается!"
  },
};

export interface IOption {
  value: number | string;
  label: string;
}

export const codes: IOption[] = [
  {value: "", label: "без кода"},
  {value: "210", label: "210"},
  {value: "215", label: "215"},
  {value: "216", label: "216"},
  {value: "219", label: "219"},
  {value: "552", label: "552"},
  {value: "533", label: "533"},
  {value: "555", label: "555"},
  {value: "557", label: "557"},
  {value: "562", label: "562"},
  {value: "774", label: "774"},
  {value: "775", label: "775"},
  {value: "777", label: "777"},
  {value: "778", label: "778"},
  {value: "779", label: "779"},
  {value: "800", label: "800"},
  {value: "900", label: "900"},
  {value: "373-22", label: "373-22"},
  {value: "373-601", label: "373-601"},
  {value: "373-602", label: "373-602"},
  {value: "373-603", label: "373-603"},
  {value: "373-604", label: "373-604"},
  {value: "373-606", label: "373-606"},
  {value: "373-607", label: "373-607"},
  {value: "373-610", label: "373-610"},
  {value: "373-611", label: "373-611"},
  {value: "373-680", label: "373-680"},
  {value: "373-681", label: "373-681"},
  {value: "373-682", label: "373-682"},
  {value: "373-683", label: "373-683"},
  {value: "373-684", label: "373-684"},
  {value: "373-685", label: "373-685"},
  {value: "373-686", label: "373-686"},
  {value: "373-688", label: "373-688"},
  {value: "373-689", label: "373-689"},
  {value: "373-690", label: "373-690"},
  {value: "373-691", label: "373-691"},
  {value: "373-692", label: "373-692"},
  {value: "373-693", label: "373-693"},
  {value: "373-694", label: "373-694"},
  {value: "373-695", label: "373-695"},
  {value: "373-696", label: "373-696"},
  {value: "373-697", label: "373-697"},
  {value: "373-698", label: "373-698"},
  {value: "373-699", label: "373-699"},
  {value: "373-709", label: "373-709"},
  {value: "373-767", label: "373-767"},
  {value: "373-785", label: "373-785"},
  {value: "373-792", label: "373-792"},
  {value: "373-794", label: "373-794"},
  {value: "373-795", label: "373-795"},
  {value: "373-796", label: "373-796"},
  {value: "373-797", label: "373-797"},
  {value: "0-800", label: "0-800"}
];
