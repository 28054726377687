import React from "react";
import {IFirm} from "../../api/dto/Firm.g";
import {replaceTranslate} from "../../common/replaceTranslate";
import {styled} from "../../core/styledComponents";
import {SearchResultItem} from "../screenResultSearchCompanyComponent/searchResultItem";

interface IProps {
  similarOrganization: IFirm[]
}

export const SimilarOrganization: React.FC<IProps> = (props) => {
  const translateUrl = (id: string, name: string): string => {
    const newName = replaceTranslate(name);
    return "/" + id + "/" + newName + ".html";
  };

  const result = React.useMemo(() => {
    return props.similarOrganization.map(item => <Link key={item.id} href={translateUrl(item.webId, item.name)}>
      <SearchResultItem {...item} isPriority={true}/>
    </Link>);
  }, [props]);

  return <Container>
    <Title>Похожие организации:</Title>
    {result}
  </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled.a`
  text-decoration: none;
  :hover, :active, :focus{
    text-decoration: none;
  }
`;

const Title  =styled.span`
  font-family: SFUITextMedium, sans-serif;
  font-size: 24px;
  color: #3E4C5B;
  margin-bottom: 20px;
`;
