import React from "react";
import {styled} from "../../core/styledComponents";
import {IconScrollTop} from "../../shared/icons/IconScrollTop";

export const ScrollTop: React.FC = () => {
  const [notTop, setNotTop] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset <= 100) {
      setNotTop(false);
    } else if (!notTop) {
      setNotTop(true);
    }
  };

  return (
    <Container onClick={ScrollToTop} visible={notTop}>
      <IconScrollTop/>
    </Container>
  );

};

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

const Container = styled.div<{visible: boolean}>`
    background: rgb(15, 119, 255);
    display: flex;
    opacity: ${(props) => (props.visible ? "1" : "0")};
    transition: all 0.7s ease;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 80px;
    right: 100px;
    z-index: ${(props) => (props.visible ? "1" : "-2")};

    &:hover {
        box-shadow: 0 11px 20px -9px #333333;
    }
    cursor: pointer;

    @media (max-width: 767px) {
        display: none;
    }
`;
