/*tslint:disable*/
import {UrlBackend} from "../common/constants";

type Action<T> = () => T;

export class BaseRequest {
  static handleError = (error: any): Promise<any> => {
    return Promise.reject(error.message || error);
  };

  static token: string;
  static getToken: Action<string> = () => BaseRequest.token;

  fetch(url: string, config: Object): Promise<any> {
    let headers = {
      cookie: "",
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    if (BaseRequest.getToken()) {
      headers = {...headers, cookie: BaseRequest.token};
    }

    return fetch(UrlBackend + url, Object.assign({headers: headers}, config)).then((response) => {
      if (!response.status || response.status < 200 || response.status >= 300) {
        throw response;
      }
      return response;
    });
  }

  getObjectFlatter(): (any: any, string?: any) => string {
    const flatObject = {};

    return function objectToFlat(node: any, parentName?: string): string {
      let queryArgs: string[] = [];
      parentName = parentName ? parentName : "";

      if (node instanceof Object) {
        for (const subNodeName in node) {
          if (node[subNodeName] instanceof Object) {
            objectToFlat(node[subNodeName], subNodeName);
          } else {
            const newNodeValue = node[subNodeName];
            const newNodeName = parentName === "" ? subNodeName : parentName + "." + subNodeName;

            if (newNodeValue) {
              flatObject[newNodeName] = newNodeValue;
            }
          }
        }
      }

      for (const key in flatObject) {
        queryArgs.push(key + "=" + flatObject[key]);
      }

      return queryArgs.join("&");
    };
  }
}
