import React from "react";
import {styled} from "../core/styledComponents";

const Styled = styled.div`
    width: 24px;
    height: 24px;
    background-color: #f44336;
    border-radius: 4px;
    cursor: pointer;
    .cross {
        display: block;
        position: absolute;
        width: 2.7px;
        height: 16.1px;
        transform: rotate(135deg);
        background-color: #ffebee;
        margin-left: 11px;
        margin-top: 4px;
    }
    align-self: flex-start;
`;

interface IProps {
    onClick: () => void;
}
export const ListRemoveElement = ({onClick}: IProps) => (
    <Styled onClick={onClick}>
        <div className={"cross"} />
        <div className={"cross"} style={{transform: "rotate(45deg)"}} />
    </Styled>
);
