import React from "react";

interface IProps {
    width: string;
    height: string;
}

/* tslint:disable:max-line-length */
export const IconWatsapp = ({width, height}: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.2 32.1" width={width} height={height}>
        <title>Asset 1</title>
        <g id="Layer_2">
            <g id="Layer_1-2">
                <g id="Symbols">
                    <g id="Footer">
                        <g id="icons8-whatsapp_filled">
                            <path
                                id="Shape"
                                fill={"#7ed321"}
                                d="M16,0A16,16,0,0,0,0,16a15.57,15.57,0,0,0,2.1,7.9l-2,7.3a.66.66,0,0,0,.2.7.76.76,0,0,0,.5.2H1l7.6-1.9a16.46,16.46,0,0,0,7.6,1.9,16,16,0,0,0,16-16A16.31,16.31,0,0,0,16,0Zm8.1,21.6c-.3.9-2,1.8-2.8,1.9a6,6,0,0,1-2.6-.2,11.82,11.82,0,0,1-2.3-.9c-4.1-1.8-6.8-5.9-7-6.1a8,8,0,0,1-1.7-4.2A4.88,4.88,0,0,1,9.1,8.7a1.39,1.39,0,0,1,1.1-.5H11c.3,0,.6-.1.9.7s1.2,2.8,1.3,3a.6.6,0,0,1,0,.7,2.51,2.51,0,0,1-.4.7c-.2.2-.4.5-.6.7s-.4.4-.2.8a12.61,12.61,0,0,0,2.3,2.8,11.11,11.11,0,0,0,3.3,2c.4.2.7.2.9-.1s1-1.2,1.3-1.6.5-.3.9-.2,2.4,1.1,2.8,1.3.7.3.8.5A4.15,4.15,0,0,1,24.1,21.6Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
