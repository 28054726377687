import {BaseRequest} from "./BaseRequest";
import {IExchangeCourse} from "./dto/ExchangeCourse.g";

export class ExchangeApiRequest extends BaseRequest {
  public getAll(config?: object): Promise<IExchangeCourse[]> {
    return this.fetch(
      `/allApi/Exchange`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }
}
