import {reducerWithInitialState} from "typescript-fsa-reducers";
import {IBaseDto} from "../../api/dto/BaseDto.g";
import {BankAction} from "./bankAction";

export interface IBankState {
    loading: boolean;
    data: IBaseDto[];
    error: boolean;
}

const ItemInitialState: IBankState = {
    data: [],
    error: false,
    loading: true
};
export const BankReducer = reducerWithInitialState(ItemInitialState)
    .case(BankAction.bankListItem.started, (state) => ({data: state.data, error: false, loading: true}))
    .case(BankAction.bankListItem.done, (state, payload) => ({data: payload.result, error: false, loading: false}))
    .case(BankAction.bankListItem.failed, (state) => ({data: state.data, error: true, loading: false}));
