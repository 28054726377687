import queryString from "query-string";
import React from "react";
import {MetaTags} from "react-meta-tags";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {amountOfNews} from "../common/constants";
import {createUrlPageNews, IUrlPageNews} from "../common/createURL/urlPageNews";
import {IAppState} from "../core/store/mainReducer";
import {styled} from "../core/styledComponents";
import {SearchNewsFromTag} from "../modules/newsSearch/newsSearchActionAsync";
import {Title} from "../ui/title";
import {Pagination} from "./componentAllPage/pagination/pagination";
import {Search} from "./componentAllPage/search/search";
import {LinkBar} from "./screenNewsComponent/linkBar";
import {NewsItem} from "./screenNewsComponent/newsItem";


export const ScreenNews: React.FC = () => {
  const state = useSelector((stateRedux: IAppState) => stateRedux.newsSearch.data);
  const loading = useSelector((stateRedux: IAppState) => stateRedux.newsSearch.loading);
  const history = useHistory();

  const attributes: IUrlPageNews = React.useMemo(() => (queryString.parse(history.location.search)), [history.location.search]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SearchNewsFromTag.InitializationNews(attributes.page || 1, amountOfNews, attributes.category, attributes.city, attributes.text));
  }, [attributes]);

  const GetPage = (event: number) => {
    attributes.page = event;
    history.push(createUrlPageNews(attributes));
  };

  const updateCurrentLink = (category?: string) => {
    attributes.category = category || undefined;
    attributes.page = 1;
    history.push("news" + createUrlPageNews(attributes));
  };

  const clearCurrentLink = () => {
    updateCurrentLink();
  };

  const news = React.useMemo(() => (
    <>
      <NewsContainerDesctop style={{padding: 0}}>
        {state.items.map((item) => (
          <Block key={item.id} className={"col-lg-4 col-md-4 col-sm-6"}>
            <NewsItem data={item}/>
          </Block>))}
      </NewsContainerDesctop>
      <Pagination
        activePage={Number(attributes.page) || 1}
        countPage={Math.ceil(state.count / amountOfNews)}
        onClick={GetPage}
      />
    </>
  ), [state]);

  return (
    <Container className={"container"}>
      <MetaTags>
        <title>Новости Приднестровья</title>
        <meta name="description" content={`Новости ПМР, Новости Приднестровья`}/>
        <meta name="keywords" content={`Новости ПМР, Новости Приднестровья`}/>
      </MetaTags>
      <Title onClick={clearCurrentLink} cursorPointer={true}>Новости </Title>
      <Search placeholder={"Поиск по новостям"} hideClassContainer={true}/>
      <LinkBar updateCurrentLink={updateCurrentLink} currentLink={attributes.category}/>
      {loading
        ? "Загрузка..."
        : news
      }
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column; 
  margin-bottom: 40px;
`;

const NewsContainerDesctop = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 20px;
  padding: 0 !important;
`;

const Block = styled.div`
  display: flex;

  @media screen and (max-width: 420px) {
        width: 100%;
    }
`;
