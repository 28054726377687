import {reducerWithInitialState} from "typescript-fsa-reducers";
import {PopupAction} from "./popupAction";

export interface IPopupState {
  visible?: boolean;
  type: string;
  loading?: boolean;
}

const ItemInitialState: IPopupState = {
  visible: false,
  type: "",
  loading: false
};

export const PopupReducer = reducerWithInitialState(ItemInitialState)
  .case(PopupAction.updateDate.started, () => ({visible: true, type: "", loading: true}))
  .case(PopupAction.updateDate.done, (state, payload) => ({
    visible: true,
    type: payload.result,
    loading: false
  }))
  .case(PopupAction.updateDate.failed, () => ({visible: false, type: "", loading: false}));

