import moment from "moment";
import React from "react";
import {UrlBackend} from "../../common/constants";
import {styled} from "../../core/styledComponents";
import {IconFounded} from "../../shared/icons/iconFounded";
import {IconWorkCount} from "../../shared/icons/iconWorkCount";

interface IProps {
  image?: string;
  title: string;
  pmrmd?: boolean;
  blackList?: string;
  workCount?: number;
  founded?: Date | string;
}

export const TitleCompany: React.FC<IProps> = (props) => {
  return (
    <Container>
      {props.image ? <Image src={!props.pmrmd ? UrlBackend + "/" + props.image : props.image} alt="logo"/> : <div/>}
      <div>
        <Title>{props.title}</Title>
        {props.blackList ? <BlackLabel>Предприятие находится в черном списке!</BlackLabel> : <></>}
      </div>
      <ContainerMoreInfo>
        {props.founded && <SmallText><IconFounded/>Основана: {moment(props.founded).format("DD.MM.YYYY")}</SmallText>}
        {props.workCount ? <SmallText><IconWorkCount/>Сотрудники: {props.workCount}</SmallText>:<></>}
      </ContainerMoreInfo>
    </Container>
  );
};


const Container = styled.div`
  display: grid;
  grid-template-columns: 255px auto 180px;
  grid-column-gap: 20px;
  margin-bottom: 32px;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Image = styled.img`
  max-width: 255px;
  @media (max-width: 420px) {
    margin-right: 0;
  }
`;

const Title = styled.h1`
  font-family: SFUITextMedium, sans-serif;
  font-size: 24px;
  color: #3e4c5b;
`;

const BlackLabel = styled.div`
    font-family: SFUIText, sans-serif;
    font-size: 20px;
    color: #d0021b;
`;

const ContainerMoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  
  @media(max-width: 700px){
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const SmallText = styled.div`
  font-family: SFUITextMedium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
  
  display: flex;
  align-items: center;
  svg{
    margin-right: 10px;
  }
  
  @media(max-width: 700px){
    margin-top: 0;
    margin-right: 10px;
  }
  
  @media(max-width: 420px){
    margin-top: 10px;
  }
`;
