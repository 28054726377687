import {FirmApiRequest} from "../../api/FirmApiRequest.g";
import {amountOfFirms} from "../../common/constants";
import {IThunkAction} from "../../core/store/mainReducer";
import {blackListItem} from "./blackListAction";

export const BlackListActionAsync = {
  LoadBlackList(params: number): IThunkAction {
    const api = new FirmApiRequest();

    return async (dispatch) => {
      dispatch(blackListItem.started);
      try {
        const result = await api.getFromBlacklist(params, amountOfFirms);
        dispatch(blackListItem.done({params: "", result}));
      } catch (error) {
        dispatch(blackListItem.failed({params: "", error}));
      }
    };
  }
};
