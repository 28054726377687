import {reducerWithInitialState} from "typescript-fsa-reducers";
import {IFirm} from "../../api/dto/Firm.g";
import {IPageResult} from "../../api/dto/PageResult.g";
import {
    alphabetFilter,
    initialization,
    searchDirectory,
    searchSubCategories,
    updateValueAlphabetFilter
} from "./directorySearchAction";

export interface IDirectorySearchState {
    loading: boolean;
    data: IPageResult<IFirm>;
    error: boolean;
    valueAlphabetFilter: string;
}

export const directorySearchInitialState: IDirectorySearchState = {
    data: {
        count: 0,
        items: []
    },
    valueAlphabetFilter: "",
    error: false,
    loading: true
};

export const directorySearchReducer = reducerWithInitialState(directorySearchInitialState)
    /***************************************** Поиск Фирм**********************************************/
    .case(searchDirectory.started, (state) => ({
        data: state.data,
        error: false,
        loading: true,
        valueAlphabetFilter: ""
    }))
    .case(searchDirectory.done, (state, payload) => ({
        data: payload.result,
        error: false,
        loading: false,
        valueAlphabetFilter: ""
    }))
    .case(searchDirectory.failed, (state) => ({
        data: state.data,
        error: true,
        loading: false,
        valueAlphabetFilter: ""
    }))

    /******************************************** Получение списка фирм при переходе на страницу СПРАВОЧНИК******************************************************/
    .case(initialization.started, (state) => ({
        data: state.data,
        error: false,
        loading: true,
        valueAlphabetFilter: ""
    }))
    .case(initialization.done, (state, payload) => ({
        data: payload.result,
        error: false,
        loading: false,
        valueAlphabetFilter: ""
    }))
    .case(initialization.failed, (state) => ({
        data: state.data,
        error: true,
        loading: false,
        valueAlphabetFilter: "",
        categoryId: ""
    }))

    /******************************************** Поиск по категориям *************************************************************/
    .case(searchSubCategories.started, (state) => ({
        data: {} as IPageResult<IFirm>,
        error: false,
        loading: true,
        valueAlphabetFilter: ""
    }))
    .case(searchSubCategories.done, (state, payload) => ({
        data: payload.result,
        error: false,
        loading: false,
        valueAlphabetFilter: ""
    }))
    .case(searchSubCategories.failed, (state) => ({
        data: state.data,
        error: true,
        loading: false,
        valueAlphabetFilter: ""
    }))

    /*************************************** Обновление значения фильтра на стронице СПРАВОЧНИК *************************************/
    .case(updateValueAlphabetFilter, (state, value) => ({
        data: state.data,
        error: true,
        loading: false,
        valueAlphabetFilter: value
    }))

    /************************************** Поиск фо алфавиту ***************************************/
    .case(alphabetFilter.started, (state) => ({
        data: state.data,
        error: false,
        loading: true,
        valueAlphabetFilter: state.valueAlphabetFilter
    }))
    .case(alphabetFilter.done, (state, payload) => ({
        data: payload.result,
        error: false,
        loading: false,
        valueAlphabetFilter: state.valueAlphabetFilter
    }))
    .case(alphabetFilter.failed, (state) => ({
        data: state.data,
        error: true,
        loading: false,
        valueAlphabetFilter: state.valueAlphabetFilter
    }));
