import React from "react";

export const IconSquare = () => (
    <svg height={12} width={12} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            fill={"white"}
            d="m512 144h-144v-144h144zm-104-40h64v-64h-64zm104 408h-144v-144h144zm-104-40h64v-64h-64zm104-144h-144v-144h144zm-104-40h64v-64h-64zm-264-144h-144v-144h144zm-104-40h64v-64h-64zm104 408h-144v-144h144zm-104-40h64v-64h-64zm104-144h-144v-144h144zm-104-40h64v-64h-64zm288-144h-144v-144h144zm-104-40h64v-64h-64zm104 408h-144v-144h144zm-104-40h64v-64h-64zm104-144h-144v-144h144zm-104-40h64v-64h-64zm0 0"
        />
    </svg>
);
