import React from "react";
import {UrlBackend} from "../../../../common/constants";
import {styled} from "../../../../core/styledComponents";
import {theme} from "../../../../core/theme";
import {IconCategoryAll} from "../../../../shared/icons/IconCategoryAll";

interface IProps {
  category: string;
  onClick: (key: string) => void;
  active: boolean;
  iconPath?: string;
  activeIconPath?: string;
}

export const Category: React.FC<IProps> = (props) => {
  const Icon = React.useMemo(() => {
    return props.category === "Все категории"
      ? <div style={{flexShrink: 0}}>
        <IconCategoryAll color={props.active ? theme.colors.blueAccent : theme.colors.mainGrey}/>
      </div>
      : <div style={{flexShrink: 0}}>
        <img
          src={UrlBackend + (props.active ? "/" + props.activeIconPath : "/" + props.iconPath)}
          alt={props.iconPath}
        />
      </div>;
  }, [props.active]);

  const onClick = () => {
    props.onClick(props.category);
  };

  return <Container onClick={onClick} className={"col-sm-4 col-xs-6"} href={"#rubricator"}>
    {Icon}
    <Span style={{color: props.active ? "#0f77ff" : "#a1a9b3"}}>{props.category}</Span>
  </Container>;
};

const Container = styled.a`
    display: flex;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    :hover, :active{
      text-decoration: none;
    }
    @media (max-width: 420px) {
        padding: 8px 0;
    }
`;

const Span = styled.span`
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
    @media (max-width: 420px) {
        margin-left: 12px;
        font-size: 16px;
    }
`;
