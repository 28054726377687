import {NavLink as Base, NavLinkProps} from "react-router-dom";
import {styled} from "../core/styledComponents";

export const NavLink = styled<NavLinkProps>(Base)`
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-decoration: none;
    color: ${(props) => (props.color ? props.color : props.theme.colors.mainGrey)};
    &:hover {
        color: ${(props) => props.theme.colors.blueAccent};
    }
`;
