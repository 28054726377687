import {reducerWithInitialState} from "typescript-fsa-reducers";
import {IDivision} from "../../api/dto/Division.g";
import {IFavoriteDivision} from "../../api/dto/FavoriteDivision.g";
import {clearCategories, initialRubricator, subCategories} from "./rubricatorAction";

export interface IDivisionState {
    data: IDivision[];
    baseData: IFavoriteDivision[];
}

const rubricatorInitialState: IDivisionState = {
    data: [],
    baseData: [],
};

export const rubricatorReducer = reducerWithInitialState(rubricatorInitialState)
    .case(initialRubricator.started, (state) => ({
        data: state.data,
        baseData: [],
    }))
    .case(initialRubricator.done, (state, payload) => ({
        data: state.data,
        baseData: payload.result,
    }))
    .case(initialRubricator.failed, (state) => ({
        data: state.data,
        baseData: [],
    }))
    /*************************************************************/
    .case(subCategories.started, (state) => ({
        data: [],
        baseData: state.baseData,
    }))
    .case(subCategories.done, (state, payload) => ({
        data: payload.result,
        baseData: state.baseData,
    }))
    .case(subCategories.failed, (state) => ({
        data: [],
        baseData: state.baseData,
    }))
    /****************************************************************/
    .case(clearCategories, (state) => ({
        data: [],
        baseData: state.baseData,
    }));
