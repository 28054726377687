/*tslint:disable*/
import {BaseRequest} from "./BaseRequest";
import {IBaseDto} from "./dto/BaseDto.g";

export class BankApiRequest extends BaseRequest {
  getAll(config?: Object): Promise<IBaseDto[]> {
    return this.fetch(`/allApi/Bank`, Object.assign({method: "GET"}, config))
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }
}
