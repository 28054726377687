/*tslint:disable*/
import {BaseRequest} from "./BaseRequest";
import {ICategory} from "./dto/Category.g";
import {IPageResult} from "./dto/PageResult.g";

export class CategoryApiRequest extends BaseRequest {
  getRoot(config?: Object): Promise<ICategory[]> {
    return this.fetch(
      `/allApi/Category`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getSub(id: string, config?: Object): Promise<ICategory[]> {
    return this.fetch(
      `/allApi/Category/GetSub/${id}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getByPage(pageNumber: number, pageSize: number, config?: Object): Promise<IPageResult<ICategory>> {
    return this.fetch(
      `/allApi/Category/${pageNumber}/${pageSize}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }
}
