/*tslint:disable*/
import {BaseRequest} from "./BaseRequest";
import {IDivision} from "./dto/Division.g";
import {IFavoriteDivision} from "./dto/FavoriteDivision.g";

export class DivisionApiRequest extends BaseRequest {
  getRoot(config?: Object): Promise<IDivision[]> {
    return this.fetch(
      `/allApi/Division`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getSub(id: string, config?: Object): Promise<IDivision[]> {
    return this.fetch(
      `/allApi/Division/GetSub/${id}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getAllSub(id: string, config?: Object): Promise<IDivision[]> {
    return this.fetch(
      `/allApi/Division/GetAllSub/${id}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }

  getFavorite(config?: Object): Promise<IFavoriteDivision[]> {
    return this.fetch(
      `/allApi/Division/Favorite`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }
}
