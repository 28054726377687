import {BaseRequest} from "./BaseRequest";
import {ICity} from "./dto/City.g";

export class CityApiRequest extends BaseRequest {
  public getAll(config?: object): Promise<ICity[]> {
    return this.fetch(
      `/allApi/City`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }
}
