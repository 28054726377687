import queryString from "query-string";
import React from "react";
import {MetaTags} from "react-meta-tags";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import styled from "styled-components";
import {ICity} from "../api/dto/City.g";
import {createUrlPaginationDirectory, IUrlPageDirectory} from "../common/createURL/urlPageDirectory";
import {replaceSymbolSearch} from "../common/replaceSymbolSearch";
import {replaceTranslate} from "../common/replaceTranslate";
import {IAppState} from "../core/store/mainReducer";
import {SearchDirectory} from "../modules/directorySearch/directorySearchActionAsync";
import {ScreenContainer} from "../ui/ScreenContainer";
import {SelectCityAfterSearchRubricator} from "../ui/selectCityAfterSearchRubricator";
import {Pagination} from "./componentAllPage/pagination/pagination";
import {Search} from "./componentAllPage/search/search";
import {AdvertisingSearchNoBootstrap} from "./screenResultSearchCompanyComponent/advertisingSearchNoBootstrap";
import {declinationWord} from "./screenResultSearchCompanyComponent/declinationWord";
import {SearchResultItem} from "./screenResultSearchCompanyComponent/searchResultItem";
import {searchResultState} from "./screenResultSearchCompanyComponent/selectors";

export const ScreenResultSearchCompany: React.FC = () => {
  const state = useSelector(searchResultState);
  const city = useSelector((stateRedux: IAppState) => stateRedux.search.city);
  const history = useHistory();
  const attributes: IUrlPageDirectory = React.useMemo(() => (queryString.parse(window.location.search)), [window.location.search]);
  const [cityName, setCityName] = React.useState("Всё Приднестровье");

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (city.length > 0) {
      dispatch(SearchDirectory.RubricatorSearch(attributes.page || 1, attributes.category, attributes.text, attributes.city));
    }
  }, [attributes, city]);

  const translateUrl = (id: string, name: string): string => {
    const newName = replaceTranslate(name);
    return "/" + id + "/" + newName + ".html";
  };

  const onSelect = (value: ICity) => {
    setCityName(value.title);
    dispatch(SearchDirectory.RubricatorSearch(attributes.page || 1, attributes.category, attributes.text, value.title));
  };

  const GetPage = async (event: number) => {
    const params: IUrlPageDirectory = {
      page: event,
      category: attributes.category,
      filter: attributes.filter,
      text: replaceSymbolSearch(attributes.text || ''),
      city: attributes.city
    };
    history.push(createUrlPaginationDirectory(params));
  };

  const listEnterprises = React.useMemo(() => (
    <ListContainer>
      {!state.loading
        ? <Main>
          <BlockTitle>
            <BlockResult>
              <Title>
                {state.directorySearchData.count === 0
                  ? `Не найдено ни одного предприятия, соответствующего запросу: “${state.searchText}”`
                  : "Результат поиска"}
              </Title>
              <TotalCount>
                {state.directorySearchData.count === undefined
                  ? "ошибка подключения"
                  : state.directorySearchData.count === 0
                    ? <NullSearchText>
                      Рекомендации:
                      <ul>
                        <li>Убедитесь, что все слова написаны без ошибок.</li>
                        <li>Попробуйте использовать другие ключевые слова.</li>
                        <li>Попробуйте уменьшить количество слов в запросе.</li>
                      </ul>
                    </NullSearchText>
                    : `нашел ${state.directorySearchData.count} ${declinationWord(state.directorySearchData.count)}`
                }
              </TotalCount>
            </BlockResult>
            {attributes.category && attributes.category.length !== 0 && <SelectBlock>
                <SelectCityAfterSearchRubricator options={state.options} onSelect={onSelect} defaultValue={cityName}/>
            </SelectBlock>}
          </BlockTitle>

          {state.directorySearchData.items && state.directorySearchData.items.length !== 0
            ? <>
              {state.directorySearchData.items.map(
                (el, index) =>
                  el && (
                    <LinkCompany key={index} href={translateUrl(el.webId, el.name)}>
                      <SearchResultItem key={el.id} {...el} />
                    </LinkCompany>
                  )
              )}
              <Pagination
                activePage={Number(attributes.page) || 1}
                countPage={state.countPage}
                onClick={GetPage}
              />
            </>
            : <></>
          }
        </Main>
        : "Загрузка..."
      }
    </ListContainer>
  ), [state]);

  return (
    <>
      <MetaTags>
        <title>Приднестровье</title>
        <meta name="description" content={``}/>
        <meta name="keywords" content={``}/>
      </MetaTags>
      <Search/>
      <Container>
        <ContentContainer>
          {listEnterprises}
          <AdvertisingSearchNoBootstrap/>
        </ContentContainer>
      </Container>
    </>
  );

};

const Container = styled(({children}) => <ScreenContainer noPadding={true}>{children}</ScreenContainer>)`
    margin-bottom: 40px;
`;

const Title = styled.div`
    font-family: SFUITextMedium, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #3e4c5b;
`;

const TotalCount = styled.div`
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    color: #a1a9b3;
    display: inline;
    @media (max-width: 991px) {
        font-size: 12px;
        margin-top: 10px;
        margin-left: 0;
    }
`;

const BlockTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
    }
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
`;

const Main = styled("main")`
    display: flex;
    flex-direction: column;
    margin: 0 15px;
`;

const SelectBlock = styled.div`
    font-family: SFUIText, sans-serif;
    font-size: 12px;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15);
    margin-left: 25px;
    padding: -5px -20px;
    width: 100%;
    flex: 0;
    @media (max-width: 1199px) {
        display: none;
    }
`;
const BlockResult = styled.div`
    display: flex;
    flex-direction: column;
`;
const NullSearchText = styled.div`
    margin-top: 40px;
`;

const LinkCompany = styled.a`
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: none;
    }
`;
