import {NewsApiRequest} from "../../api/NewsApiRequest.g";
import {IThunkAction} from "../../core/store/mainReducer";
import {newsItems} from "./newsItemAction";

export const NewsItemData = {
  LoadItem(params: string): IThunkAction {
    const api = new NewsApiRequest();

    return async (dispatch) => {
      dispatch(newsItems.started(params));
      try {
        const result = await api.getById(params);
        dispatch(newsItems.done({params, result}));
      } catch (error) {
        dispatch(newsItems.failed({params, error}));
      }
    };
  }
};
