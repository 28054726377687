import React from "react";

export const IconStopAutoplay = () => (
    <svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={12}
        height={12}
        viewBox="0 0 45.975 45.975"
        enableBackground="new 0 0 45.975 45.975">
        <g>
            <path
                fill={"white"}
                d="M13.987,0c-2.762,0-5,2.239-5,5v35.975c0,2.763,2.238,5,5,5s5-2.238,5-5V5C18.987,2.238,16.75,0,13.987,0z"
            />
            <path
                fill={"white"}
                d="M31.987,0c-2.762,0-5,2.239-5,5v35.975c0,2.762,2.238,5,5,5s5-2.238,5-5V5C36.987,2.239,34.749,0,31.987,0z"
            />
        </g>
    </svg>
);
