import React from "react";
import {styled} from "../../core/styledComponents";

interface IProps {
  countOfViewsAll?: number;
  countOfViewsThisYear?: number;
  countOfViewsThisMonth?: number;
}

export const VisitCompany: React.FC<IProps> = (props) => {
  return <ContainerVisitors>
    <Title>Статистика просмотров страницы</Title>
    <BlockContainer>
      <Block>
        <VisitText>{props.countOfViewsThisMonth}</VisitText>
        <span>за месяц</span>
      </Block>
      <Block>
        <VisitText>{props.countOfViewsThisYear}</VisitText>
        <span>за год</span>
      </Block>
      <Block>
        <VisitText>{props.countOfViewsAll}</VisitText>
        <span>всего</span>
      </Block>
    </BlockContainer>
  </ContainerVisitors>;
};


const ContainerVisitors = styled.div`
  display: flex;
  //padding-bottom: 20px;
  margin-top: 30px;
  justify-content: space-between;

  @media(max-width: 700px){
    flex-direction: column;
    align-items: center;
  }
  
  @media(max-width: 400px){
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
`;

const BlockContainer = styled.div`
  display: flex;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding:0 30px;
  border-left: 1px solid #A8AEB8;

  :nth-child(1){
    border-left: none;
  }
  span{
    color: #a1a9b3;
    font-size: 12px;

    @media(max-width: 400px){
      font-size: 10px;
    }
  }
  
  @media(max-width: 400px){
    padding:0 20px;
  }
`;

const VisitText = styled.div`
  font-family: SFUITextMedium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-right: 10px;
  white-space: nowrap;
  
  @media(max-width: 400px){
    font-size: 16px;
  }
`;

const Title = styled.span`
  font-family: SFUITextMedium,sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #3E4C5B;

  @media(max-width: 700px){
    margin-bottom: 10px;
  }
  
  @media(max-width: 400px){
    font-size: 18px;
  }
`;
