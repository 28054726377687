import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../core/store/mainReducer";
import {styled} from "../../../core/styledComponents";
import {SearchNewsFromTag} from "../../../modules/newsSearch/newsSearchActionAsync";
import {Title as BaseTitle} from "../../../ui/title";
import {NewsItem} from "../../screenNewsComponent/newsItem";
import {ButtonAllNews} from "./buttonAllNews";

export const BlockNews: React.FC = () => {
  const searchResult = useSelector((state: IAppState) => state.newsSearch.data.items);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(SearchNewsFromTag.InitializationNews(1, 6));
  }, []);

  const items = searchResult.map((item) => {
    return (
      <ItemContainer key={item.id} className={"col-lg-6 col-md-6"}>
        <NewsItem data={item} hideSchema={true}/>
      </ItemContainer>
    );
  });

  return (
    <Container className="col-md-8">
      <Title>Новости</Title>
      <NewsContainer>{items}</NewsContainer>
      <ButtonAllNews/>
    </Container>
  );

};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 770px) {
        align-items: center;
        justify-content: center;
    }
`;

const NewsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 20px;
    @media (max-width: 770px) {
        justify-content: center;
    }
`;

const ItemContainer = styled.div`
    display: flex;
    @media (max-width: 420px) {
        padding: 0;
        margin-bottom: 0;
        width: 100%;
    }
`;

const Title = styled(BaseTitle)`
    margin-bottom: 29px;
    margin-left: 15px;
    font-size: 36px;
    @media (max-width: 420px) {
        margin-bottom: 16px;
    }
`;
