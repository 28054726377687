import {reducerWithInitialState} from "typescript-fsa-reducers";
import {IFirm} from "../../api/dto/Firm.g";
import {IPageResult} from "../../api/dto/PageResult.g";
import {blackListItem} from "./blackListAction";

export interface IBlackListIteamState {
    loading: boolean;
    data: IPageResult<IFirm>;
    error: boolean;
}

const ItemInitialState: IBlackListIteamState = {
    data: {} as IPageResult<IFirm>,
    error: false,
    loading: true
};
export const BlackListReducer = reducerWithInitialState(ItemInitialState)
    .case(blackListItem.started, (state) => ({data: {} as IPageResult<IFirm>, error: false, loading: true}))
    .case(blackListItem.done, (state, payload) => ({data: payload.result, error: false, loading: false}))
    .case(blackListItem.failed, (state) => ({data: state.data, error: true, loading: false}));
