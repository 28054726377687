import {styled} from "../core/styledComponents";

export const Span = styled.span<{fontSize?: number | string}>`
    font-family: ${(props) => props.theme.fontFamily};
    font-size: ${(props) => props.fontSize || 16}px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
`;
