import {actionCreatorFactory} from "typescript-fsa";
import {IDivision} from "../../api/dto/Division.g";
import {IFavoriteDivision} from "../../api/dto/FavoriteDivision.g";
// import {IDivision} from "../../ui/dto/Division.g";
// import {IDivisionState} from "./rubricatorReduser";

const rubricatorActionFactory = actionCreatorFactory("rubricator");

export const initialRubricator = rubricatorActionFactory.async<string, IFavoriteDivision[], Error>("INITIAL");

export const subCategories = rubricatorActionFactory.async<string, IDivision[], Error>("SUB");

export const clearCategories = rubricatorActionFactory("CLEAR");
