import React from "react";
import {SelectCustom} from "../../ui/selectCustom";
import {IOption} from "../screenAddCompanyComponent/validation";
import {
  AlphabitBlock,
  SelectBlock,
  SiteListFilter,
  SiteListFilterContainer,
  SiteListFilterItem,
  SiteListFilterRowContainer
} from "./styled";

const alphabet = ["123", "ABCD", "А", "Б", "В", "Г", "Д", "Е", "Ж", "З", "И", "К", "Л", "М", "Н", "О", "П", "Р", "С", "Т", "У", "Ф", "Х", "Ц", "Ч", "Ш", "Щ", "Э", "Ю", "Я"];
const alphabetOptions = alphabet.map((key) => ({value: key, label: key}));

interface IProps {
  filterFunc: (value: string) => void;
}

export const ScreenSitesListFilter: React.FC<IProps> = (props) => {
  const [listMode, setListMode] = React.useState(true);
  const [value, setValue] = React.useState("");


  const onChangeMode = (data: boolean) => () => {
    props.filterFunc("");
    setListMode(data);
  };

  const onClickLetter = (currentLetter: string) => () => {
    setValue(currentLetter);
    props.filterFunc(currentLetter);
  };
  const onChange = (data: IOption) => {
    setValue(data.label);
    props.filterFunc(data.label);
  };

  return (
    <SiteListFilterContainer>
      <SiteListFilterRowContainer first={true}>
        <SiteListFilterItem isTitle={true}>Группировать:</SiteListFilterItem>
        <SiteListFilterItem isActive={listMode} onClick={onChangeMode(false)}>
          Алфавит
        </SiteListFilterItem>
        <SiteListFilterItem isActive={!listMode} onClick={onChangeMode(true)}>
          Список
        </SiteListFilterItem>
      </SiteListFilterRowContainer>
      {!listMode && (
        <SiteListFilterRowContainer>
          <>
            <AlphabitBlock>
              {alphabet.map((letter) => (
                <SiteListFilterItem
                  key={letter}
                  isActive={letter === value}
                  onClick={onClickLetter(letter)}>
                  {letter}
                </SiteListFilterItem>
              ))}
            </AlphabitBlock>
            <SelectBlock>
              <SiteListFilter>Фильтр:</SiteListFilter>
              <SelectCustom options={alphabetOptions} onSelect={onChange} defaultValue={value}/>
            </SelectBlock>
          </>
        </SiteListFilterRowContainer>
      )}
    </SiteListFilterContainer>
  );
};
