import React from "react";
import {styled} from "../../../../core/styledComponents";
import {IconArrowBlue} from "../../../../shared/icons/IconArrowBlue";
import {IconFlagEuro} from "../../../../shared/icons/iconFlagEuro";
import {IconFlagMdl} from "../../../../shared/icons/iconFlagMdl";
import {IconFlagRub} from "../../../../shared/icons/iconFlagRub";
import {IconFlagUkr} from "../../../../shared/icons/iconFlagUkr";
import {IconFlagUSA} from "../../../../shared/icons/iconFlagUSA";
import {Span} from "../../../../ui/span";

interface IProps {
    sellCourse: number;
    buyCourse: number;
    currencyCode: "Usd" | "Eur" | "Mdl" | "Uah" | "Rub";
    first: boolean;
    bankName: string;
}

interface IState {
    minimized: boolean;
}

export class ExchangeRate extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {minimized: false};
    }

    public render(): JSX.Element {
        const {first, sellCourse, buyCourse, currencyCode} = this.props;
        const {minimized} = this.state;

        return (
            <Container first={first}>
                {first && this.props.bankName.toLocaleUpperCase() !== "ПРБ" ? (
                    <BuySale>Покупка Продажа</BuySale>
                ) : (
                    <div style={{width: "100%", height: "37px"}} />
                )}
                <CurrencyContainer>
                    {currencyCode === "Usd" && <IconFlagUSA />}
                    {currencyCode === "Eur" && <IconFlagEuro />}
                    {currencyCode === "Mdl" && <IconFlagMdl />}
                    {currencyCode === "Uah" && <IconFlagUkr />}
                    {currencyCode === "Rub" && <IconFlagRub />}
                    <div style={{width: 9}} />
                    <CurrencyText>{currencyCode}</CurrencyText>
                    <div style={{margin: "auto"}} />
                    {!minimized && (
                        <>
                            {this.props.bankName.toLocaleUpperCase() === "ПРБ" ? (
                                <CurrencyValue>{buyCourse.toFixed(4)}</CurrencyValue>
                            ) : (
                                <>
                                    <CurrencyValue>{buyCourse.toFixed(buyCourse < 10 ? 3 : 2)}</CurrencyValue>
                                    <CurrencyValue>{sellCourse.toFixed(sellCourse < 10 ? 3 : 2)}</CurrencyValue>
                                </>
                            )}
                        </>
                    )}
                    {minimized && <IconArrowBlue />}
                </CurrencyContainer>
            </Container>
        );
    }
}

const Container = styled.div<{first: boolean}>`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: ${(props) => props.theme.boxShadow};
    padding: 14px;
    border-radius: 4px;
    margin-top: ${(props) => (props.first ? "-8px" : "0px")};
    margin-bottom: 10px;
    padding-top: ${(props) => (props.first ? "10px" : "14px")};
`;

const BuySale = styled.div`
    word-spacing: 15px;
    text-align: right;
    font-size: 16px;
    font-family: SFUITextLight, sans-serif;
    padding-bottom: 15px;
    width: 100%;
`;

const CurrencyContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CurrencyText = styled(Span)`
    font-size: 25px;
    color: ${(props) => props.theme.colors.slate};
    font-family: SFUITextLight, sans-serif;
    text-transform: uppercase;
`;

const CurrencyValue = styled(Span)`
    font-size: 25px;
    color: ${(props) => props.theme.colors.slate};
    font-family: SFUITextLight, sans-serif;
    text-transform: uppercase;
    min-width: 90px;
    justify-content: flex-end;
`;
