import React from "react";
import {ReactFormInput, ReactFormInputContainer, ReactFormLabel} from "src/ui/elementReactForm/inputForReactForm";
import {styled} from "../../../core/styledComponents";
import {IconPlus} from "../../../shared/icons/IconPlus";
import {ListRemoveElement} from "../../../ui/ListRemoveElement";

interface IProps {
  reff: any;
}

export const Directors: React.FC<IProps> = (props) => {
  const [directors, setDirectors] = React.useState<number[]>([]);

  const add = () => {
    setDirectors([...directors, directors.length]);
  };

  const remove = (value: number) => () => {
    setDirectors(directors.filter(elem => elem !== value));
  };

  return (
    <ReactFormInputContainer>
      <Label>
        Руководитель
        <MobilePlus onClick={add}>
          <IconPlus width={"32px"} height={"32px"}/>
        </MobilePlus>
      </Label>
      <DesctopPlus onClick={add}>
        <IconPlus width={"46px"} height={"46px"}/>
      </DesctopPlus>
      <div/>
      <DataContainer>
        {directors.map((item) =>
          <React.Fragment key={item}>
            <ContainerInput>
              <Input name={`managers[${item}].post`} ref={props.reff} placeholder={"Должность"}/>
              <Input name={`managers[${item}].fullName`} ref={props.reff} placeholder={"ФИО"}/>
              <Input name={`managers[${item}].phone`} ref={props.reff} placeholder={"Телефон"}/>
            </ContainerInput>
            <ListRemoveElement onClick={remove(item)}/>
          </React.Fragment>
        )}
      </DataContainer>
    </ReactFormInputContainer>
  );
};

const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 88% 10%;
  grid-column-gap: 2%;
  margin-top: 20px;
`;

const ContainerInput = styled.div`
  display: grid;
  grid-template-columns: 31% 31% 31%;
  grid-column-gap: 2%;
  
  margin-bottom: 20px;
  
  @media(max-width: 768px){
    grid-template-columns: 100% ;
  }
`;

const MobilePlus = styled.span`
  @media(min-width: 769px){
    display: none;
  }
`;

const DesctopPlus = styled.span`
  @media(max-width: 768px){
    display: none;
  }
`;

const Label = styled(ReactFormLabel)`
  @media(max-width: 768px){
    display: flex;
    justify-content: space-between;
  }
`;

const Input = styled(ReactFormInput)`
  margin-bottom: 20px;
`;
