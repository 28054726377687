import {reducerWithInitialState} from "typescript-fsa-reducers";
import {INews} from "../../api/dto/News.g";
import {IPageResult} from "../../api/dto/PageResult.g";
import {similarNewsItems} from "./similarNewsAction";

export interface ISimilarState {
    loading: boolean;
    data: IPageResult<INews>;
    error: boolean;
}

const SimilarState: ISimilarState = {
    data: {
        count: 0,
        items: []
    },
    error: false,
    loading: false
};
export const SimilarNewsReducer = reducerWithInitialState(SimilarState)
    .case(similarNewsItems.started, (state) => ({data: state.data, error: false, loading: true}))
    .case(similarNewsItems.done, (state, payload) => ({data: payload.result, error: false, loading: false}))
    .case(similarNewsItems.failed, (state) => ({data: state.data, error: true, loading: false}));
