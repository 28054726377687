// import React from "react";
import {styled} from "../../../../core/styledComponents";

// interface IProps {
//   onClick: () => void;
// }

// export const ButtonSearch: React.FC<IProps> = (props) => {
//   const onClick = () => {
//     props.onClick();
//   };
//
//   return <StyledButton onClick={onClick} children="Найти"/>;
// };

export const ButtonSearch = styled.button`
  display: flex;
  width: 182px;
  height: 72px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.blueAccent};
  color: ${(props) => props.theme.colors.white};
  box-shadow: none;
  cursor: pointer;
  margin: 0;
  font-size: 22px;
  font-family: SFUIText, sans-serif;
  border: none;
  outline: none;

  @media (max-width: 700px) {
    display: none;
  }
  :active {
    background: ${(props) => props.theme.colors.mainGrey};
  }
`;
