import React from "react";
import {styled} from "../../../../core/styledComponents";
import {theme} from "../../../../core/theme";
import {NavLink} from "../../../../ui/NavLink";

const activeStyle = {
  color: theme.colors.blueAccent
};

export const NavBar: React.FC = () => {
  return (
    <NavContainer className={"ContainerNavbar"}>
      <CustomNavLink to="/" activeStyle={activeStyle} exact={true}>
        Главная
      </CustomNavLink>
      <CustomNavLink to="/news" activeStyle={activeStyle}>
        Новости
      </CustomNavLink>
      <CustomNavLink to="/siteindex" activeStyle={activeStyle}>
        Сайты предприятий
      </CustomNavLink>
      <StyledButton to="/newfirm">Добавить предприятие</StyledButton>
      <CustomNavLink to="/calendar" activeStyle={activeStyle}>
        Календарь
      </CustomNavLink>
    </NavContainer>
  );
};

export const NavContainer = styled.div`
  display: flex;
  flex: 1;
  height: 77px;
  justify-content: space-around;
  align-items: flex-end;
  @media (max-width: ${(props) => props.theme.sizes.mainMenuFoldingWidth}px) {
    display: none;
    flex-direction: column;
    background: white;
    height: auto;
    left: 10px;
    align-items: flex-start;
  }
`;

const StyledButton = styled(NavLink)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.colors.blueAccent};
  color: ${(props) => props.theme.colors.white};
  padding: 0 10px 18px 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 77px;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  
  :hover,:active,:focus{
    color: #ffffff;
    text-decoration: none;
  }
`;

export const CustomNavLink = styled(NavLink)`
  margin-bottom: 16px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  font-family: SFUITextMedium, sans-serif;
  :hover,
  :focus {
    text-decoration: none;
  }

  @media (max-width: 1001px) {
    color: #3e4c5b;
    font-size: 16px;
    display: flex;
  }
`;
