import {reducerWithInitialState} from "typescript-fsa-reducers";
import {IDivision} from "../../api/dto/Division.g";
import {AddCompanyAction} from "./addCompanyAction";

export interface ICompanyIteamState {
  division?: IDivision[];
  subDivision?: IDivision[];
}

const ItemInitialState: ICompanyIteamState = {
  division: undefined,
  subDivision: undefined
};

export const AddCompanyReducer = reducerWithInitialState(ItemInitialState)
  .case(AddCompanyAction.loadCategories.started, (state) => ({
    division: state.division,
    subDivision: state.subDivision
  }))
  .case(AddCompanyAction.loadCategories.done, (state, payload) => ({
    division: payload.result,
    subDivision: state.subDivision
  }))
  .case(AddCompanyAction.loadCategories.failed, (state) => ({
    division: state.division,
    subDivision: state.subDivision
  }))

  .case(AddCompanyAction.loadSubCategories.started, (state) => ({
    division: state.division,
    subDivision: state.subDivision
  }))
  .case(AddCompanyAction.loadSubCategories.done, (state, payload) => ({
    division: state.division,
    subDivision: payload.result
  }))
  .case(AddCompanyAction.loadSubCategories.failed, (state) => ({
    division: state.division,
    subDivision: state.subDivision
  }));
