import {CalendarApiRequest} from "../../api/CalendarApiRequest.g";
import {DayType} from "../../api/dto/DayType.g";
import {IThunkAction} from "../../core/store/mainReducer";
import {calendar, calendarAllFiles} from "./calendarAction";

export const CalendarCelebrateData = {
  LoadItem(month: number, year: number): IThunkAction {
    const api = new CalendarApiRequest();

    return async (dispatch) => {
      dispatch(calendar.started({params: {}}));
      try {
        let result = await api.getByMonth(month, year);
        result = result.map(item => {
          if (item.type === DayType.ShortDay) {
            return {...item, description: "Сокращенный рабочий день"};
          } else {
            return item;
          }
        });
        dispatch(calendar.done({params: "", result}));
      } catch (error) {
        dispatch(calendar.failed({params: "", error}));
      }
    };
  },

  LoadAllFile(): IThunkAction {
    const api = new CalendarApiRequest();

    return async (dispatch) => {
      dispatch(calendarAllFiles.started);
      try {
        const result = await api.getAllFiles();
        dispatch(calendarAllFiles.done({params: "", result}));
      } catch (error) {
        dispatch(calendarAllFiles.failed({params: "", error}));
      }
    };
  }
};
