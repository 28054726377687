import {actionCreatorFactory} from "typescript-fsa";
import {ICity} from "../../api/dto/City.g";

const searchActionFactory = actionCreatorFactory("search");

export const searchText = searchActionFactory<string>("SEARCH_TEXT");

export const searchCity = searchActionFactory<string>("SEARCH_CITY");

export const initializationCity = searchActionFactory.async<{}, ICity[], Error>("SEARCH_START_PARAM");

export const suggestions =  searchActionFactory.async<undefined, string, Error>("SUGGESTIONS");
