import * as moment from "moment";
import React from "react";
import {useHistory} from "react-router";
import {styled} from "../../../../core/styledComponents";
import directoryBook from "../../../../shared/img/directoryBook.png";

interface IProps {
  IsActive?: string;
}

export const DirectoryLink: React.FC<IProps> = (props) => {
  const history = useHistory();
  const onClick = () => {
    history.push("/");
  };

  return (
    <Container IsActive={!!props.IsActive} onClick={onClick}>
      <Image src={directoryBook} alt={"bookLogo"}/>
      <div>
        <TopText>СПРАВОЧНИК</TopText>
        <BodyText>
          <b>ПРИДНЕСТРОВЬЕ</b> <b style={{color: "red"}}>{moment().format("YYYY")}</b>
        </BodyText>
        <BottomText>Бизнес &nbsp;&nbsp;&nbsp; Общество &nbsp;&nbsp;&nbsp; Власть</BottomText>
      </div>
    </Container>
  );
};


const Container = styled.div<{IsActive?: boolean}>`
    display: flex;
    align-items: center;
    align-self: flex-start;
    cursor: pointer;

    margin: auto 10px;
    flex: 1;
    justify-content: ${(prop) => (prop.IsActive ? "start" : "center")};

    img {
        margin-right: 10px;
    }

    @media (max-width: 420px) {
        img {
            display: none;
        }
    }
    @media (max-width: 1000px) {
        padding: 10px;
    }
`;
const TopText = styled.div`
    color: #888888;
    font-size: 8.5px;
    font-family: SFUIText, sans-serif;
`;
const BodyText = styled.div`
    font-family: SFUIText, sans-serif;
`;
const BottomText = styled.div`
    color: #888888;
    font-size: 8.5px;
    font-family: SFUIText, sans-serif;
`;
const Image = styled.img`
    width: 30px;
`;
