import queryString from "query-string";
import React from "react";
import {useHistory} from "react-router";
import {UrlBackend} from "../../../../common/constants";
import {IUrlRubricator} from "../../../../common/createURL/urlRubricator";
import {styled} from "../../../../core/styledComponents";
import {theme} from "../../../../core/theme";
import {IconCategoryAll} from "../../../../shared/icons/IconCategoryAll";
import {NavLink} from "../../../../ui/NavLink";
import {DropdownMobile} from "./dropdownMobile";

interface IProps {
  category: string;
  id: string;
  iconPath?: string;
  activeIconPath?: string;
  categoryName?: string;
  categoryId?: string;
}

export const RubricatorItem: React.FC<IProps> = (props) => {
  const history = useHistory();
  const attributes: IUrlRubricator = React.useMemo(() => (queryString.parse(history.location.search)), [history.location.search]);
  const active = React.useMemo(() => attributes.active === props.id, [attributes, props.id]);

  const Icon = React.useMemo(() => {
    return props.category === "Все категории"
      ? <div style={{flexShrink: 0}}>
        <IconCategoryAll color={active ? theme.colors.blueAccent : theme.colors.mainGrey}/>
      </div>
      : <div style={{flexShrink: 0}}>
        <img src={UrlBackend + (active ? "/" + props.activeIconPath : "/" + props.iconPath)} alt={props.iconPath}/>
      </div>;
  }, [props.category, attributes.active]);

  return <Container>
    <Category>
      {Icon}
      <Item to={`/rubricator?active=${props.id}`} color={active ? theme.colors.blueAccent : theme.colors.mainGrey}>
        {props.category}
      </Item>
    </Category>
    {active && <DropdownMobile data={props.id}
                               categoryName={props.categoryName || ""}
                               categoryId={props.categoryId || ""}/>}
  </Container>;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 18px;
    padding-bottom: 18px;
    cursor: pointer;
    @media (max-width: 420px) {
        padding: 8px 0;
    }
`;

const Item = styled(NavLink)`
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
    width: auto;
    @media (max-width: 420px) {
        margin-left: 12px;
        font-size: 16px;
    }
    :hover {
        text-decoration: none;
        cursor: pointer;
    }
`;

const Category = styled.div`
    display: flex;
    align-items: center;
`;
