import React from "react";
import {css, styled} from "../core/styledComponents";
import backgroundImage from "../shared/img/main@3x.png";

interface IProps {
    children: any;
    style?: any;
    isWhite?: boolean;
    isHeader?: boolean;
    noPadding?: boolean;
    background?: boolean;
}

export const ScreenContainer = ({children, style, isWhite, isHeader, noPadding, background}: IProps) => (
    <Container style={style} isWhite={isWhite} isHeader={isHeader} padding={noPadding ? 0 : 15} background={background}>
        {children}
    </Container>
);

const Container = styled.div<{isWhite?: boolean; isHeader?: boolean; padding: number; background?: boolean}>`
    background: ${(props) =>
        props.background
            ? `url(${backgroundImage})`
            : props.isWhite
                ? props.theme.colors.white
                : props.theme.colors.beige};
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
        width: 100%;
        padding: 0 ${(props) => props.padding}px !important;
    }
    @media (max-width: 420px) {
        ${({isWhite}) =>
            isWhite &&
            css`
                margin: 0 ${(props) => props.padding}px;
            `};
        background: transparent;
    }
    @media (min-width: 768px) {
        > * {
            width: ${({isHeader}) => (isHeader ? 760 : 750)}px;
            padding: 0 ${(props) => props.padding}px;
        }
    }
    @media (min-width: 992px) {
        > * {
            width: ${({isHeader}) => (isHeader ? 980 : 960)}px;
            padding: 0 ${(props) => props.padding}px;
        }
    }
    @media (min-width: 1200px) {
        > * {
            width: ${({isHeader}) => (isHeader ? 1170 : 1140)}px;
            padding: 0 ${(props) => props.padding}px;
        }
    }
`;
