import React from "react";
import styled from "styled-components";
import {theme} from "../../../../core/theme";
import {IconMobileCalendar} from "../../../../shared/icons/IconMobileCalendar";
import {IconMobileContact} from "../../../../shared/icons/IconMobileContact";
import {IconMobileHomePage} from "../../../../shared/icons/IconMobileHomePage";
import {CustomNavLink, NavContainer} from "./navBar";

const activeStyle = {
  color: theme.colors.blueAccent
};

interface IProps {
  onClick: () => void;
}

export const NavBarMobile: React.FC<IProps> = (props) => {
  return <NavContainer className={"ContainerNavMob"}>
    <div onClick={props.onClick} style={{marginTop: "26px"}}>
      <CustomNavLink activeStyle={activeStyle} to="/newfirm">
        <ActiveStyle> Добавить предприятие</ActiveStyle>
      </CustomNavLink>
      <CustomNavLink activeStyle={activeStyle} to="/" exact={true}>
        <MenuItem>
          <IconMobileHomePage/>
          Главная
        </MenuItem>
      </CustomNavLink>
      <CustomNavLink activeStyle={activeStyle} to="/news">
        <MenuItem>
          <IconMobileCalendar/>
          Новости
        </MenuItem>
      </CustomNavLink>
      <CustomNavLink activeStyle={activeStyle} to="/siteindex">
        <MenuItem>
          <IconMobileCalendar/>
          Список предприятий
        </MenuItem>{" "}
      </CustomNavLink>
      <CustomNavLink activeStyle={activeStyle} to="/blackList">
        <MenuItem>
          <IconMobileContact/>
          Черный список ПМР
        </MenuItem>
      </CustomNavLink>
      <CustomNavLink activeStyle={activeStyle} to="/calendar">
        {" "}
        <MenuItem>
          {" "}
          <IconMobileCalendar/> Календарь{" "}
        </MenuItem>{" "}
      </CustomNavLink>
      <CustomNavLink activeStyle={activeStyle} to="/contacts">
        {" "}
        <MenuItem>
          <IconMobileContact/> Контакты{" "}
        </MenuItem>{" "}
      </CustomNavLink>
    </div>
  </NavContainer>;
};

const ActiveStyle = styled.div`
    color: ${theme.colors.blueAccent};
    font-size: 18px;
`;
const MenuItem = styled.div`
    font-size: 16px;
`;
