import {reducerWithInitialState} from "typescript-fsa-reducers";
import {INews} from "../../api/dto/News.g";
import {IPageResult} from "../../api/dto/PageResult.g";
import {initialNews, searchTag} from "./newsSearchAction";

export interface INewsSearchState {
    loading: boolean;
    data: IPageResult<INews>;
    error: boolean;
}

const newsSearchInitialState: INewsSearchState = {
    data: {count: 0, items: []},
    error: false,
    loading: false
};

export const newsSearchReducer = reducerWithInitialState(newsSearchInitialState)
    .case(searchTag.started, (state, payload) => ({
        data: {count: 0, items: []},
        error: false,
        loading: true
    }))
    .case(searchTag.done, (state, payload) => ({
        data: payload.result.data,
        error: false,
        loading: false
    }))
    .case(searchTag.failed, (state, error) => ({
        data: state.data,
        error: true,
        loading: false
    }))

    .case(initialNews.started, (state) => ({
        data: {count: 0, items: []},
        error: false,
        loading: true
    }))
    .case(initialNews.done, (state, payload) => ({
        data: payload.result.data,
        error: false,
        loading: false
    }))
    .case(initialNews.failed, (state) => ({
        data: state.data,
        error: true,
        loading: false
    }));
