import React from "react";
import {IManager} from "../../api/dto/Manager.g";
import {ISocialNetworks} from "../../api/dto/SocialNetworks.g";
import {styled} from "../../core/styledComponents";
import {IconFacebook} from "../../shared/icons/IconFacebook";
import {IconInstagram} from "../../shared/icons/IconInstagram";
import {IconOdnoclasniki} from "../../shared/icons/IconOdnoklasniki";
import {IconVK} from "../../shared/icons/IconVK";
import {buttonCss} from "../../ui/Button";
import {Item} from "../componentAllPage/footer/socialNetwork";

interface IProps {
    address: string;
    city: string;
    phone: string;
    mail?: boolean;
    mapCode?: string;
    site?: string;
    activeTime?: string;
    director: IManager[];
    division: string;
    salesDepartment: string;
    kindOfActivity: string;
    tapButtonOpenMap: (mapVisible: boolean) => void;
    socialNetwork?: ISocialNetworks;
    additionInfo?: string;
    skype?: string;
    icq?: string;
    fax?: string;
}

export const ContentCompany: React.FC<IProps> = (props) => {
    const [site, setSite] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (props.site) {
            setSite(props.site.split(", "));
        }
    }, [props.site]);

    const tapButton = () => {
        props.tapButtonOpenMap(true);
    };

    const formatNumber = (str: string) => {
        const numberItem = /([0-9])/g.test(str)
            ? str.slice(0, str.includes("(", 5) || /[a-zA-Z,а-яА-Я]/g.test(str) ? str.lastIndexOf("(") : str.length)
            : "";
        return numberItem;
    };

    const formatNumberDesc = (str: string) => {
        const textItem =
            /[a-zA-Z,а-яА-Я]/g.test(str) || str.includes("24/7")
                ? /\) \(/g.test(str)
                    ? str.slice(0, str.lastIndexOf(")") + 1)
                    : str.includes("(")
                    ? str.slice(str.lastIndexOf("("), str.length)
                    : str
                : "";
        return textItem;
    };

    return (
        <Container>
            <Block>
                <Label>Адрес:</Label>
                <Description>
                    {props.address && props.address.length !== 0
                        ? props.city + ", " + props.address
                        : "У данной компании адрес не указан"}

                    {props.mapCode && (
                        <ButtonLink onClick={tapButton} href={"#Map"}>
                            {" "}
                            Показать на карте{" "}
                        </ButtonLink>
                    )}
                </Description>
            </Block>
            <Block>
                <Label>Телефон:</Label>
                <PhoneBlock>
                    {props.phone.match(/(0 )?(\(?\d{3}\)?[\- ]?)?[\d\- ]{3,10} ?(\([a-zA-Z,' ]{0,}\))?/g) !== null ? (
                        props.phone.split(",").map((item, index) => {
                            const numberItem = formatNumber(item);
                            const descItem = formatNumberDesc(item);

                            return (
                                <DescriptionPhone key={index}>
                                    <DescriptionLink href={`tel: ${numberItem}`}>{numberItem}</DescriptionLink>
                                    <Description
                                        style={{
                                            margin: `${
                                                /\) \(/g.test(item)
                                                    ? "0 8px 0 0"
                                                    : item.includes("(", 4)
                                                    ? "0 8px "
                                                    : "0 8px 0 0"
                                            }`
                                        }}>
                                        {descItem}
                                        {index === props.phone.split(",").length - 1 ? "" : ", "}
                                    </Description>
                                </DescriptionPhone>
                            );
                        })
                    ) : (
                        <Description>номер телефона не указан</Description>
                    )}
                </PhoneBlock>
            </Block>
            {props.fax && (
                <Block style={{marginTop: 10}}>
                    <Label>Факс:</Label>
                    <Description>{props.fax}</Description>
                </Block>
            )}
            {props.mail && (
                <Block>
                    <Label>E-mail:</Label>
                    <DescriptionEmail href={window.location.pathname + `/message`}>
                        отправить сообщение
                    </DescriptionEmail>
                </Block>
            )}
            {props.skype && (
                <Block style={{marginTop: 10}}>
                    <Label>Skype:</Label>
                    <Description>{props.skype}</Description>
                </Block>
            )}
            {props.icq && (
                <Block style={{marginTop: 10}}>
                    <Label>ICQ:</Label>
                    <Description>{props.icq}</Description>
                </Block>
            )}
            {props.activeTime && (
                <Block>
                    <Label>Рабочие часы:</Label>
                    <Description>{props.activeTime}</Description>
                </Block>
            )}
            {props.director &&
                props.director.length !== 0 &&
                props.director.map((item, index) => (
                    <Block key={index}>
                        <Label>{item.post}:</Label>
                        <Description>
                            <NameDirector>
                                {item.fullName}
                                {item.fullName.length > 4 && item.phone.length !== 0 ? ", " : ""}
                            </NameDirector>
                            {item.phone && item.phone !== "|" && (
                                <>
                                    {item.phone.split(",").map((phone, ind) => {
                                        const numberItem = formatNumber(phone);
                                        const descItem = formatNumberDesc(phone);
                                        return (
                                            <DescriptionPhone key={ind}>
                                                <DescriptionLink href={`tel: ${numberItem}`}>
                                                    {numberItem}
                                                </DescriptionLink>
                                                <Description
                                                    style={{
                                                        margin: `${phone.includes("(", 5) ? "0 6px " : "0 6px 0 0"}`
                                                    }}>
                                                    {descItem}
                                                    {ind === item.phone.split(",").length - 1 ? "" : ", "}
                                                </Description>
                                            </DescriptionPhone>
                                        );
                                    })}
                                </>
                            )}
                        </Description>
                    </Block>
                ))}
            <Block>
                {props.kindOfActivity.replace("<br/>", "").length !== 0 ? (
                    <>
                        <Label>Вид деятельности:</Label>
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: props.kindOfActivity.replace(/\n/g, "<br/>")
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Label>Раздел:</Label>
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: props.division.replace(new RegExp("[0-9]{1,3}.?-? ?", "g"), "")
                            }}
                        />
                    </>
                )}
            </Block>
            {props.site && (
                <Block>
                    <Label>Сайт:</Label>
                    <div>
                        {site.map((item, index) => (
                            <DescriptionLink key={index} href={"http://" + item} target={"_blank"}>
                                {item}
                                {index !== site.length - 1 ? "," : ""}
                            </DescriptionLink>
                        ))}
                    </div>
                </Block>
            )}
            {props.socialNetwork &&
                (props.socialNetwork.facebookUrl ||
                    props.socialNetwork.odkUrl ||
                    props.socialNetwork.vkUrl ||
                    props.socialNetwork.instagramUrl) && (
                    <Block>
                        <Label>Социальные сети:</Label>
                        <DescriptionSocialNetwork>
                            {props.socialNetwork.facebookUrl && (
                                <Item target={"_blank"} href={props.socialNetwork.facebookUrl} noTitle={true}>
                                    <IconFacebook height={"32px"} width={"32px"} />
                                </Item>
                            )}
                            {props.socialNetwork.odkUrl && (
                                <Item target={"_blank"} href={props.socialNetwork.odkUrl} noTitle={true}>
                                    <IconOdnoclasniki height={"32px"} width={"32px"} />
                                </Item>
                            )}
                            {props.socialNetwork.vkUrl && (
                                <Item target={"_blank"} href={props.socialNetwork.vkUrl} noTitle={true}>
                                    <IconVK width={"32px"} height={"32px"} />
                                </Item>
                            )}
                            {props.socialNetwork.instagramUrl && (
                                <Item target={"_blank"} href={props.socialNetwork.instagramUrl} noTitle={true}>
                                    <IconInstagram width={"32px"} height={"32px"} />
                                </Item>
                            )}
                        </DescriptionSocialNetwork>
                    </Block>
                )}
            {props.additionInfo && (
                <Block style={{marginTop: 10}}>
                    <Label>Дополнительная информация:</Label>
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: props.additionInfo.replace(/\n/g, "<br/>")
                        }}
                    />
                </Block>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Block = styled.div`
    display: grid;
    grid-template-columns: 255px auto;
    grid-column-gap: 20px;
    padding: 6px 0;
    margin-bottom: 10px;

    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

const Label = styled.div`
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    color: #000;
    font-weight: 500;
`;

const Description = styled.div`
    font-family: SFUIText, sans-serif;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #3e4c5b;
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const DescriptionPhone = styled.div`
    display: flex;
    align-items: center;
`;

const DescriptionSocialNetwork = styled(Description)`
    @media (max-width: 420px) {
        flex-direction: row;
    }
`;

const DescriptionLink = styled.a`
    font-size: 16px;
    font-weight: 500;
    color: #00377f;
    font-family: SFUITextMedium, sans-serif;
    :hover {
        color: #00377f;
    }
`;

const DescriptionEmail = styled.a`
    font-size: 16px;
    font-weight: 500;
    color: #00377f;
    font-family: SFUITextMedium, sans-serif;
    cursor: pointer;
`;

const PhoneBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
`;
const ButtonLink = styled.a`
    ${buttonCss};
    margin-left: auto;

    @media (max-width: 600px) {
        margin-left: 0;
    }
`;

const NameDirector = styled.span`
    margin-right: 6px;
`;
