import React from "react";
import {codes, IOption} from "../../components/screenAddCompanyComponent/validation";
import {css, styled} from "../../core/styledComponents";
import {Checkbox} from "../Checkbox";
import {Description} from "../Description";
import {SelectCustom} from "../selectCustom";

interface IProps {
  reff: any;
  codeRef?: any;
  name: string;
  label: string;
  hasError?: boolean;
  required?: boolean;
  type: "input" | "select" | "textarea" | "select & input" | "input & checkbox" | "date" | "number";
  maxLength?: number;
  options?: IOption[];
  description?: string;
  checkboxLabel?: string;
  checkboxName?: string;
  checkRef?: any;
}

export const InputForReactForm: React.FC<IProps> = (props) => {
  const [value, setValue] = React.useState("");
  const onSelectChange = (event:IOption) => {
    setValue(event.value.toString());
  };

  return <ReactFormInputContainer>
    <ReactFormLabel hasError={props.hasError}>
      {props.label}:
      {props.required && <span>*</span>}
    </ReactFormLabel>

    {props.type === "date" && <>
        <ReactFormInput name={props.name} ref={props.reff} hasError={props.hasError} type={"date"}/>
      {props.description && <>
          <div/>
          <Description>{props.description}</Description>
      </>}
    </>}

    {props.type === "number" && <>
        <ReactFormInput name={props.name} ref={props.reff} hasError={props.hasError} type={"number"}/>
      {props.description && <>
          <div/>
          <Description>{props.description}</Description>
      </>}
    </>}

    {props.type === "input" && <>
        <ReactFormInput name={props.name} ref={props.reff} hasError={props.hasError}/>
      {props.description && <>
          <div/>
          <Description>{props.description}</Description>
      </>}
    </>}

    {props.type === "select" && <>
        <SelectCustom options={props.options || []} defaultValue={value} onSelect={onSelectChange}/>
        <ReactFormInput name={props.name} ref={props.reff} defaultValue={value} style={{display: "none"}}/>
      {props.description && <>
          <div/>
          <Description>{props.description}</Description>
      </>}
    </>}

    {props.type === "textarea" && <>
        <ReactFormTextArea name={props.name} ref={props.reff} hasError={props.hasError}/>
      {props.description && <>
          <div/>
          <Description>{props.description}</Description>
      </>}
    </>}


    {props.type === "select & input" && <>
        <SelectInputContainer>
            <>
                <SelectCustom options={codes} defaultValue={value} onSelect={onSelectChange}/>
                <ReactFormInput name={props.name + "Code"} ref={props.codeRef} defaultValue={value}
                                style={{display: "none"}}/>
            </>
            <ReactFormInput name={props.name} ref={props.reff} hasError={props.hasError}/>
        </SelectInputContainer>
      {props.description && <>
          <div/>
          <Description>{props.description}</Description>
      </>}
    </>}

    {props.type === "input & checkbox" && <>
        <CheckboxInputContainer>
            <ReactFormInput name={props.name} ref={props.reff} hasError={props.hasError}/>
            <>
                <Checkbox>
                  {props.checkboxLabel}
                    <input type="checkbox" value="" name={props.checkboxName} ref={props.checkRef}/>
                    <span className="checkmark"/>
                </Checkbox>
            </>
        </CheckboxInputContainer>
      {props.description && <>
          <div/>
          <Description>{props.description}</Description>
      </>}
    </>}

  </ReactFormInputContainer>;
};

export const ReactFormInputContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  margin: 40px 0;
  
  @media (max-width: 768px){
    grid-template-columns: 100%;
  }
`;

const SelectInputContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 75%;
  grid-column-gap: 5%;
  
  @media (max-width: 420px){
    grid-template-columns: 45% 50%;
  }
`;

const CheckboxInputContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  
  @media (max-width: 991px){
    display: flex;
    flex-direction: column;
  }
`;

export const ReactFormLabel = styled.label<{hasError?: boolean}>`
  font-family: SFUITextMedium,sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: ${(props) => (props.hasError ? props.theme.colors.errorText : props.theme.colors.slate)};
  
  span{
    color: #d0021b;
    font-size: 30px;
    font-family: SFUITextMedium,sans-serif;
  }
  
  @media(max-width: 420px){
    margin-bottom: 8px;
  }
`;

export const ReactFormInput = styled.input<{hasError?: boolean}>`
  height: 48px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  background-color: ${(props) => (props.hasError ? props.theme.colors.errorBackground : props.theme.colors.white)};
  ${(props) => props.hasError && css`caret-color: ${props.theme.colors.white};color: ${props.theme.colors.white};`};
  border: none;
  outline: none;
  padding: 5px 20px;
  font-family: SFUIText,sans-serif;
  font-size: 18px;
  transition: background-color 0.5s ease;
  
  // убрал стрелки
  ::-webkit-inner-spin-button { display: none; }
`;

export const ReactFormTextArea = styled.textarea<{hasError?: boolean}>`
    height: 128px;
    resize: none;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    background-color: ${(props) => (props.hasError ? props.theme.colors.errorBackground : props.theme.colors.white)};
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    border: none;
    padding: 10px;
    outline: none;
    ${(props) => props.hasError && css`caret-color: ${props.theme.colors.white};color: ${props.theme.colors.white};`};
    transition: background-color 0.5s ease;
`;
