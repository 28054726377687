import React from "react";
import {styled} from "../../../core/styledComponents";

export const CalendarRow: React.FC = (props) => {
  return <Container>{props.children}</Container>;
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 3%;
`;

export const TooltipsCalendar = styled.div<{visible: boolean | 0 | undefined}>`
    display: ${(props) => (props.visible ? "flex" : "none")};
    position: absolute;
    opacity: 0.75;
    border-radius: 4px;
    background-color: #0f77ff;
    color: #ffffff;
    text-align: center;
    width: 350px;
    font-size: 20px;
    padding: 10px;
    font-family: SFUIText, sans-serif;
    z-index: 1;
    @media (max-width: 1199px) {
        width: 290px;
    }
    @media (max-width: 991px) {
        width: 316px;
    }
    @media (max-width: 766px) {
        width: 360px;
    }
    @media (max-width: 420px) {
        width: 92.2%;
    }
    @media (max-width: 370px) {
        width: 91%;
    }
`;
