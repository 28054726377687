export const declinationWord = (value: number): string => {
  if (value < 100) {
    if ((value >= 5 && value <= 20) || value % 10 === 0) {
      return "ответов";
    } else if (value % 10 === 1) {
      return "ответ";
    }
    return "ответа";
  } else {
    if ((value % 10 >= 5 && value % 10 <= 9) || (value % 100 >= 10 && value % 100 <= 20) || value % 10 === 0) {
      return "ответов";
    } else if (value % 10 === 1) {
      return "ответ";
    }
    return "ответа";
  }
};
