// import React from "react";
import {css, styled} from "../../core/styledComponents";
// import {ScreenContainer} from "../../ui/ScreenContainer";

export const SitesListContainer = styled.div`
    padding-bottom: 40px;
    @media screen and (max-width: 420px) {
        padding-bottom: 0;
    }
`;

export const SitesListContentContainer = styled.div`
    display: flex;
    @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
    }
`;

export const SiteListRightColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
`;

export const SitesListTitle = styled.div`
    margin-left: 65px;
    > h1 {
        font-family: SFUITextMedium, sans-serif;
        font-size: 36px;
        font-weight: 500;
        color: ${(props) => props.theme.colors.slate};
        margin: 0 0 30px;
    }
    @media screen and (max-width: 420px) {
        margin: 0 15px;
        > h1 {
            margin: 20px;
            font-size: 20px;
        }
    }
`;

export const SitesListMain = styled("main")`
    display: flex;
    flex-direction: column;
    margin: 0 15px 15px 15px;
`;

export const SitesList = styled("ul")`
    margin: 0;
`;

export const SitesListItem = styled("li")`
    font-size: 24px;
    a {
        padding-left: 10px;
        font-family: SFUIText, sans-serif;
        cursor: pointer;
        line-height: 1.67;
        color: ${({theme}) => theme.colors.black};
        :hover {
            color: ${({theme}) => theme.colors.blueAccent};
        }
    }
`;

export const SiteListNotItem = styled.div`
    font-size: 24px;
    text-align: center;
    padding-top: 25px;
`;

export const SiteListFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 420px) {
        > div {
            margin-bottom: 10px;
        }
    }
`;

export const SiteListFilterRowContainer = styled.div<{first?: boolean}>`
    display: flex;
    margin-bottom: ${({first}) => (first ? 40 : 20)}px;
    max-width: 100%;
    flex-wrap: wrap;
    span {
        margin-right: 16px;
    }
    span:nth-child(1) {
        margin-right: 25px;
    }
    span:nth-child(2) {
        margin-right: 25px;
    }
    span:nth-last-child {
        margin-right: 0;
    }
`;

export const SiteListFilterItem = styled.span<{isTitle?: boolean; isActive?: boolean}>`
    font-family: SFUIText, sans-serif;
    font-size: 24px;
    color: ${({isTitle, isActive, theme}) => (isTitle || isActive ? theme.colors.black : theme.colors.blueAccent)};

    ${({isTitle}) =>
        !isTitle
            ? css`
                  :hover {
                      color: ${({theme}) => theme.colors.blueAccent};
                  }
                  cursor: pointer;
                  @media (max-width: 420px) {
                      margin-left: 20px;
                      margin-top: 8px;
                      font-family: SFUITextMedium, sans-serif;
                      text-decoration: underline;
                  }
              `
            : css`
                  @media (max-width: 420px) {
                      font-size: 15px;
                      color: #495664;
                      display: block;
                      margin-left: 20px;
                      margin-right: 100% !important;
                  }
              `};
`;
export const SiteListFilter = styled.div`
    font-family: SFUIText, sans-serif;
    font-size: 15px;
    line-height: 1.6;
    color: #3e4c5b;

    @media (min-width: 421px) {
        display: none;
    }
`;

export const AlphabitBlock = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 420px) {
        display: none;
    }
`;
export const SelectBlock = styled.div`
    flex: 1;
    margin-left: 20px;
    margin-bottom: 20px;

    @media (min-width: 421px) {
        display: none;
    }
`;
