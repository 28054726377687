import {styled} from "../core/styledComponents";

export const Title = styled.h1<{cursorPointer?: boolean}>`
  font-family: SFUITextMedium, sans-serif;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  color: #3e4c5b;
  margin: 64px 0 0 0;
  cursor: ${(props) => props.cursorPointer ? "pointer" : "default"} ;
  z-index: 2;
  width: auto;
  align-self: flex-start;

  @media (max-width: 420px) {
    font-size: 20px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
`;

