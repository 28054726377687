import React from "react";
import {connect} from "react-redux";
import {styled} from "../../../core/styledComponents";
import {Select} from "../../../ui/reactSelect";
import {
  Menu as MenuControl,
  SingleValue as SingleValueControl,
  ValueContainerElement
} from "../../../ui/reactSelect/defaultComponents";
import {Span} from "../../../ui/span";
import {Currencies, defaultCurrency} from "../exchangeRates/currencySettings";
import {CalculatDispatch, CalculatState} from "../exchangeRates/selectors";
import {CurrencyAmount} from "./CurrencyAmount";

interface IProps extends ReturnType<typeof CalculatState>, ReturnType<typeof CalculatDispatch> {
}

interface IState {
  bank: string;
  currency1: string;
  currency2: string;
  amount: number;
}

class CurrencyConverterConstructor extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {amount: 0, bank: "", currency1: defaultCurrency.key, currency2: defaultCurrency.key};
  }

  public render(): JSX.Element {
    const {amount, bank, currency1, currency2} = this.state;

    const formatter = new Intl.NumberFormat("ru", {
      currency: "RUB",
      minimumFractionDigits: 0
    });
    const banks = this.props.data.map((key) => ({value: key.title, label: key.title}));

    const exchangeRate1 =
      this.props.data && this.props.data.length !== 0
        ? this.getExchangeRate(currency1, bank ? bank : this.props.data[0].title, true)
        : 1;
    const exchangeRate2 =
      this.props.data && this.props.data.length !== 0
        ? this.getExchangeRate(currency2, bank ? bank : this.props.data[0].title, false)
        : 1;

    return this.props.data && this.props.data.length !== 0 ? (
      <Container>
        <Title>Калькулятор валют</Title>
        <Separator/>
        <BankContainer>
          <Bank>{`Расчет по курсу : `}</Bank>
          <div style={{width: 10}}/>
          <div>
            <Select
              options={banks}
              value={{
                value: bank ? bank : this.props.data[0].title,
                label: bank ? bank : this.props.data[0].title
              }}
              onChange={this.onChangeBank}
              components={{SingleValue, Menu, ValueContainer}}
            />
          </div>
        </BankContainer>
        <Separator/>
        <div style={{display: "flex", flexDirection: "row"}}>
          <CurrencyAmount
            get={true}
            currency={currency1}
            onChangeCurrency={this.onChangeCurrency(true)}
            options={Currencies}
            value={amount}
            onChange={this.onChangeAmount}
          />
          <CurrencyAmount
            get={false}
            currency={currency2}
            onChangeCurrency={this.onChangeCurrency(false)}
            options={Currencies}
            value={(amount * exchangeRate1) / exchangeRate2}
          />
        </div>
        <Separator/>
        <WayContainer>
          {exchangeRate1 !== 1 &&
          exchangeRate2 !== 1 && (
            <>
              <WayText children={`По курсу ${formatter.format(exchangeRate1)}`}/>
              <WayArrow children="->"/>
              <WayText children={`RUP`}/>
              <WayArrow children="->"/>
              <WayText children={`${formatter.format(exchangeRate2)}`}/>
            </>
          )}
          {exchangeRate1 === 1 && <WayText children={`По курсу ${formatter.format(exchangeRate2)}`}/>}
          {exchangeRate2 === 2 && <WayText children={`По курсу ${formatter.format(exchangeRate1)}`}/>}
        </WayContainer>
        <Separator/>
        {exchangeRate1 !== 1 && exchangeRate2 !== 1 && <Warning>Внимание! Двойной обмен.</Warning>}
      </Container>
    ) : (
      <></>
    );
  }

  private onChangeBank = (bank: string) => {
    this.setState({bank});
  };

  private onChangeAmount = (amount: number) => {
    this.setState({amount});
  };

  private onChangeCurrency = (isFirst: boolean) => (currency: string) => {
    if (isFirst) {
      this.setState({currency1: currency});
    } else {
      this.setState({currency2: currency});
    }
  };

  private getExchangeRate = (currency: string, bank: string, buySell: boolean) => {
    if (defaultCurrency.key === currency) {
      return defaultCurrency.exchangeRate;
    } else {
      if (buySell) {
        return this.props.data
          .filter((item) => item.title === bank)[0]
          .currencies.filter((item) => item.currencyCode === currency)[0].buyCourse;
      } else {
        return this.props.data
          .filter((item) => item.title === bank)[0]
          .currencies.filter((item) => item.currencyCode === currency)[0].sellCourse;
      }
    }
  };
}

export const CurrencyConverter = connect(
  CalculatState,
  CalculatDispatch
)(CurrencyConverterConstructor);

const SingleValue = styled(SingleValueControl)`
    margin: 0 5px 0 5px;
    position: relative;
`;
const Menu = styled(MenuControl)`
    min-width: 160px;
`;
const ValueContainer = styled(ValueContainerElement)`
    height: 14px;
`;

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding: 10px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: ${(props) => props.theme.boxShadow};
    border-radius: 4px;
    margin-bottom: 10px;
`;

const Title = styled(Span)`
    font-size: 20px;
    color: ${(props) => props.theme.colors.mainGrey};
`;

const BankContainer = styled.div`
    display: flex;
`;

const Bank = styled(Span)`
    font-size: 12px;
    color: ${(props) => props.theme.colors.slate};
    font-family: SFUIText, sans-serif;
`;

const Warning = styled(Span)`
    font-size: 10px;
    font-family: SFUITextMedium, sans-serif;
    font-weight: 500;
    color: ${(props) => props.theme.colors.rose};
`;

const Separator = styled.div`
    height: 6px;
`;

const WayContainer = styled.div`
    display: flex;
`;

const WayText = styled(Span)`
    font-size: 12px;
    font-family: SFUITextMedium, sans-serif;
    font-weight: 500;
    color: ${(props) => props.theme.colors.slate};
`;

const WayArrow = styled(Span)`
    font-size: 12px;
    font-weight: 900;
    color: ${(props) => props.theme.colors.blueAccent};
    margin-left: 10px;
    margin-right: 10px;
    font-family: SFUITextMedium, sans-serif;
`;
