import React from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router";
import {useParams} from "react-router-dom";
import {ButtonGroup} from "src/ui/ButtonGroup";
import {IFirm} from "../api/dto/Firm.g";
import {IAppState} from "../core/store/mainReducer";
import {CompanyItemData} from "../modules/companyItem/companyItemActionAsync";
import imgPortal from "../shared/img/imgPortal.png";
import {InputForReactForm, ReactFormInputContainer} from "../ui/elementReactForm/inputForReactForm";
import {ReCaptcha} from "./componentAllPage/captcha/ReCaptcha";
import {CompanyInfo} from "./screenSendMessageCompanyComponent/CompanyInfo";
import {
  LeaveMessageButton,
  LeaveMessageContainer,
  LeaveMessageHeader
} from "./screenSendMessageCompanyComponent/styled";

export const ScreenLeaveMessage: React.FC = () => {
  const {register, handleSubmit, errors} = useForm();
  const state = useSelector((stateRedux: IAppState) => stateRedux.companyItem);
  const {id} = useParams();
  const history = useHistory();
  const match = useRouteMatch();

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (id) {
      dispatch(CompanyItemData.LoadItem(id));
    }
  }, [id]);

  const onSubmit = (data: any) => {
    match.path === "/messageForPmrmd"
      ? dispatch(CompanyItemData.SendToPMRMassage(data))
      : dispatch(CompanyItemData.SendMassage(id || "", data));
  };

  const onCancel = () => {
    history.goBack();
  };


  return (
    <LeaveMessageContainer className={"container"}>
      <CompanyInfo data={
        match.path !== "/messageForPmrmd"
          ? state.data
          : ({banner: imgPortal, name: "Бизнес-портал Приднестровья \n" + "ООО \"ДИВА и К\""} as IFirm)
      }/>
      <LeaveMessageHeader>
        <span>Отправка сообщения:</span>
        <span>ВНИМАНИЕ! Для отправки сообщения необходимо заполнить все поля. Заранее благодарим.</span>
      </LeaveMessageHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputForReactForm
          reff={register({required: true})}
          name={"name"}
          label={"Ваше имя"}
          hasError={errors.name}
          required={true}
          type={"input"}
        />

        <InputForReactForm
          reff={register({
            required: true,
            pattern: {value: /^\+?([\ \-\:\(\)]?\d+[\ \-\:\(\)]?){5}$/i, message: "invalid phone"}
          })}
          name={"phoneNumber"}
          label={"Ваш номер телефона"}
          hasError={errors.phoneNumber}
          required={true}
          type={"input"}
        />

        <InputForReactForm
          reff={register({
            required: true,
            pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "invalid email address"}
          })}
          name={"email"}
          label={"Ваш e-mail"}
          hasError={errors.email}
          required={true}
          type={"input"}
        />

        <InputForReactForm
          reff={register({required: true})}
          name={"text"}
          label={"Сообщение"}
          hasError={errors.text}
          required={true}
          type={"textarea"}
        />
        <ReactFormInputContainer>
          <ReCaptcha name={"captcha"} reff={register({required: true})} hasError={errors.captcha}/>
        </ReactFormInputContainer>
        <ButtonGroup>
          <LeaveMessageButton type="submit">
            Отправить
          </LeaveMessageButton>
          <LeaveMessageButton type="button" onClick={onCancel}>
            Отмена
          </LeaveMessageButton>
        </ButtonGroup>

      </form>
    </LeaveMessageContainer>
  );
};

