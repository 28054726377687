import {CityApiRequest} from "../../api/CityApiRequest.g";
import {ICity} from "../../api/dto/City.g";
import {FirmApiRequest} from "../../api/FirmApiRequest.g";
import {IThunkAction} from "../../core/store/mainReducer";
import {initializationCity, suggestions} from "./searchAction";

export const AllSearch = {
  InitialState(): IThunkAction {
    const api = new CityApiRequest();

    let result: ICity[] = [];
    return async (dispatch) => {
      dispatch(initializationCity.started({}));
      result = await api.getAll();

      try {
        const orderCities: ICity[] = [];
        result.map((item) => {
          if (item.id !== 9) {
            orderCities.push(item);
          }
        });
        orderCities.push(result.filter((item) => item.id === 9)[0]);
        orderCities.push({id: 0, zip: "", title: "Всё Приднестровье"});

        result = orderCities;

        dispatch(initializationCity.done({params: {}, result}));
      } catch (error) {
        dispatch(initializationCity.failed({params: {}, error}));
      }
    };
  },

  GetSuggestions(searchText): IThunkAction {
    const api = new FirmApiRequest();

    return async (dispatch) => {
      try {
      dispatch(suggestions.started(undefined));
      const result = await api.GetSuggestions(searchText);

        dispatch(suggestions.done({params: undefined, result}));
      } catch (error) {
        dispatch(suggestions.failed({params: undefined, error}));
      }
    };
  },
};
