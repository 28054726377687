import moment from "moment";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../core/store/mainReducer";
import {styled} from "../../../core/styledComponents";
import {CalendarCelebrateData} from "../../../modules/calendar/calendarActionAsync";
import {HeaderWithScroll} from "../../../ui/HeaderWithScroll";
import {Days} from "./days";
import {DaysOfWeek} from "./daysOfWeek";

export const Calendar: React.FC = () => { /* extends React.Component<IProps, IState> {*/
  const [month] = React.useState(moment().startOf("month"));
  const [currentDay, setCurrentDay] = React.useState(moment());
  const loading = useSelector((state: IAppState) => state.calendarReducer.loading);
  const [descriptionTooltips, setDescriptionTooltips] = React.useState("");
  const [visibleTooltips, setVisibleTooltips] = React.useState(moment(0));


  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(CalendarCelebrateData.LoadItem(month.month() + 1, month.year()));
  }, []);

  const clickDay = (description: string, activeData: moment.Moment) => {
    if (activeData === currentDay) {
      setVisibleTooltips(visibleTooltips === activeData ? moment(0) : activeData);
    } else {
      setVisibleTooltips(activeData);
    }
    setCurrentDay(activeData);
    setDescriptionTooltips(description);
  };

  const prevMonth = () => {
    month.add("month", -1);
    dispatch(CalendarCelebrateData.LoadItem(Number(month.format("MM")), Number(month.format("YYYY"))));
    setVisibleTooltips(moment(0));
  };
  const nextMonth = () => {
    month.add("month", 1);
    dispatch(CalendarCelebrateData.LoadItem(Number(month.format("MM")), Number(month.format("YYYY"))));
    setVisibleTooltips(moment(0));
  };

  const weeks = React.useMemo(() => {
    return [1, 2, 3, 4, 5, 6].map((item, week) => (
      <Days
        key={week}
        startDay={month}
        week={week}
        currentDay={currentDay}
        clickDay={clickDay}
        visibleTooltips={visibleTooltips}
        descriptionTooltips={descriptionTooltips}
      />
    ));
  }, [month, currentDay, visibleTooltips, descriptionTooltips]);

  return (
    <Container>
      <HeaderWithScroll month={month} onClickLeft={prevMonth} onClickRight={nextMonth}/>
      {loading ? <></> : <ContentContainer><DaysOfWeek/>{weeks}</ContentContainer>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.boxShadow};
  border-radius: 4px;
  animation-timing-function: step-end;
`;

const ContentContainer = styled.div`
  padding: 0 0 10px 0;
  overflow: hidden;
  animation-name: appearance;
  animation-duration: 1s;

  @keyframes appearance {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 400px;
    }
  }
`;
