export interface IUrlPageDirectory {
  page?: number;
  category?: string;
  filter?: string;
  city?: string;
  text?: string;
}

export const createUrlSearchDirectory = (params: IUrlPageDirectory) => {
  return `${params.page ? "?page=" + params.page : ""}${params.filter ? "&filter=" + params.filter : ""}${
    params.city ? "&city=" + params.city : ""
    }${params.text ? "&text=" + params.text : ""}`;
};

export const createUrlPaginationDirectory = (params: IUrlPageDirectory) => {
  return `${params.page ? "?page=" + params.page : ""}${params.category ? "&category=" + params.category : ""}${
    params.filter ? "&filter=" + params.filter : ""
    }${params.city ? "&city=" + params.city : ""}${params.text ? "&text=" + params.text : ""}`;
};
