import React from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import {UrlBackend} from "../../common/constants";
import {IconCancelCarousel} from "../../shared/icons/IconCancelCarousel";
import {IconFullScreen} from "../../shared/icons/IconFullScreen";
import {IconSquare} from "../../shared/icons/IconSquare";
import {IconStartAutoplay} from "../../shared/icons/IconStartAutoplay";
import {IconStopAutoplay} from "../../shared/icons/IconStopAutoplay";

interface IProps {
  photos: string[];
  close: (value: number | undefined) => void;
  activeImage: number | undefined;
}

interface IState {
  autoplay: boolean;
  activeImage: number | undefined;
  visibleSmallIcons: boolean;
}

export class FullScreenCarousel extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {autoplay: false, activeImage: this.props.activeImage, visibleSmallIcons: false};
  }

  public render() {
    const {photos} = this.props;
    return (
      <>
        <ContainerImage>
          <div className={"outside-click"}/>
          <Container margin={this.state.visibleSmallIcons ? "200px" : "0"}>
            <ContainerSettingsCarousel>
              <CarouselButtonPlay onClick={this.autoplay}>
                {this.state.autoplay ? <IconStopAutoplay/> : <IconStartAutoplay/>}
              </CarouselButtonPlay>
              <CarouselButtonPlay onClick={this.fullScreen}>
                <IconFullScreen/>
              </CarouselButtonPlay>
              <CarouselButtonPlay onClick={this.visibleSmallIcon}>
                <IconSquare/>
              </CarouselButtonPlay>
              <CarouselButtonClose onClick={this.close}>
                <IconCancelCarousel/>
              </CarouselButtonClose>
            </ContainerSettingsCarousel>
            <Carousel
              className={"container-carousel"}
              showThumbs={false}
              infiniteLoop={true}
              selectedItem={this.state.activeImage}
              emulateTouch={true}
              autoPlay={this.state.autoplay}
              stopOnHover={false}
              onChange={this.activeImage}
            >
              {photos.map((item, index) => (
                <img key={index} src={UrlBackend + "/" + item} alt={""}/>
              ))}
            </Carousel>
          </Container>
          <SmallIconsContainer visible={this.state.visibleSmallIcons}>
            <SmallIconsBlock>
              {photos.map((item, index) => (
                <SmallIconsItem key={index} active={index === this.state.activeImage}>
                  <img
                    src={UrlBackend + "/" + item}
                    alt={""}
                    // tslint:disable-next-line
                    onClick={() => this.activeImage(index)}
                  />
                </SmallIconsItem>
              ))}
            </SmallIconsBlock>
          </SmallIconsContainer>
        </ContainerImage>
      </>
    );
  }

  private close = () => {
    this.setState({autoplay: false});
    if (document.fullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    this.props.close(undefined);
  };

  private visibleSmallIcon = () => {
    this.setState({visibleSmallIcons: !this.state.visibleSmallIcons});
  };

  private autoplay = () => {
    this.setState({autoplay: !this.state.autoplay});
  };

  private fullScreen = () => {
    const html = document.documentElement;
    if (document.fullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else {
      if (html && html.requestFullscreen()) {
        html.requestFullscreen();
      }
    }
  };
  private activeImage = (value: number) => {
    this.setState({activeImage: value});
  };
}

const ContainerImage = styled.div`
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-carousel {
        position: absolute;
        height: 90vh;
        z-index: 1;
        width: 100%;
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    .outside-click {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        opacity: 0.7;
    }

    .carousel {
        .slide img {
            width: auto;
            max-height: 90vh;
            max-width: 100%;
        }

        .slide {
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .carousel-status {
            top: -36px;
            right: 50%;
        }

        .control-dots {
            display: none;
        }

        .slider-wrapper,
        .slider-wrapper.axis-horizontal .slider .slide {
            height: 90vh;
        }
    }

    .carousel.carousel-slider {
        overflow: visible;
    }
`;

const Container = styled.div<{margin: string}>`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 40px;

    margin-right: ${(props) => props.margin};
`;

const ContainerSettingsCarousel = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
`;

const CarouselButtonPlay = styled.div`
    padding: 8px 12px;

    &:hover {
        background: #000;
    }
    @media (max-width: 767px) {
        display: none;
    }
`;

const CarouselButtonClose = styled.div`
    padding: 8px 12px;

    &:hover {
        background: #000;
    }
`;

const SmallIconsContainer = styled.div<{visible: boolean}>`
    position: absolute;
    display: ${(props) => (props.visible ? "block" : "none")};
    right: 0;
    width: 200px;
    background: white;
    height: 100%;
`;

const SmallIconsBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const SmallIconsItem = styled.div<{active: boolean}>`
    width: 100px;
    height: 75px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 4px solid ${(props) => (props.active ? "#4ea7f9" : "white")};
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
    }
`;
