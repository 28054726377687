import  React from "react";

/* tslint:disable:max-line-length */
export const IconArrowSearch = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
        <g fill="none" fillRule="evenodd">
            <path fill="#D8D8D8" fillOpacity="0" d="M-1-4h12V8H-1z"/>
            <path fill="#A1A9B3" d="M.094.781C.03.72 0 .625 0 .5 0 .354.052.234.156.14A.541.541 0 0 1 .531 0H9.47c.146 0 .27.047.375.14A.462.462 0 0 1 10 .5a.338.338 0 0 1-.094.25l-.062.094-4.25 4.875C5.406 5.906 5.208 6 5 6c-.208 0-.406-.094-.594-.281L.156.844.094.78z"/>
        </g>
    </svg>
);
