import {reducerWithInitialState} from "typescript-fsa-reducers";
import {IFirm} from "../../api/dto/Firm.g";
import {companyItem, companySimilarItem} from "./companyItemAction";

export interface ICompanyItemState {
  loading: boolean;
  data: IFirm;
  error: string;
  similarOrganization: IFirm[]
}

const ItemInitialState: ICompanyItemState = {
  data: {} as IFirm,
  error: "",
  loading: false,
  similarOrganization: []

};
export const CompanyItemReducer = reducerWithInitialState(ItemInitialState)
  .case(companyItem.started, (state) => ({
    data: state.data,
    error: state.error,
    loading: true,
    similarOrganization: state.similarOrganization
  }))
  .case(companyItem.done, (state, payload) => ({
    data: payload.result,
    error: state.error,
    loading: false,
    similarOrganization: state.similarOrganization
  }))
  .case(companyItem.failed, (state) => ({
    data: state.data,
    error: "Ошибка загрузки предприятия",
    loading: false,
    similarOrganization: state.similarOrganization
  }))

  .case(companySimilarItem.started, (state) => ({
    data: state.data,
    error: state.error,
    loading: true,
    similarOrganization: []
  }))
  .case(companySimilarItem.done, (state, payload) => ({
    data: state.data,
    error: state.error,
    loading: false,
    similarOrganization: payload.result
  }))
  .case(companySimilarItem.failed, (state) => ({
    data: state.data,
    error: "Ошибка загрузки предприятия",
    loading: false,
    similarOrganization: []
  }));
