import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {styled} from "../../../core/styledComponents";

interface IProps {
  name?: string;
  reff?: any;
  hasError?: boolean
}

export const ReCaptcha: React.FC<IProps> = (props) => {
  const [value, setValue] = React.useState("");

  const verifyCallback = async () => {
    setValue("1");
  };

  return <div>
    <ReCAPTCHA sitekey="6Le7HH8UAAAAANEYXBX1QRe75ORUStXvWZBiRaAx" onChange={verifyCallback}/>
    {props.name && props.reff
      ? <input name={props.name} ref={props.reff} defaultValue={value} style={{display: "none"}}/>
      : <></>}
    { props.hasError && <Error>Докажите что вы не робот</Error>}
  </div>;
};

const Error = styled.div`
    display: flex;
    margin-top: 4px;
    font-size: 12px;
    color: red;
`;
