import {createSelector} from "reselect";
import {IAppDispatch, IAppState} from "../../../core/store/mainReducer";
import {CalculateCelebrateData} from "../../../modules/exchangeCourse/exchangeCourseActionAsync";

export const CalculatState = createSelector(
    (state: IAppState) => state.calculateReduser.data,
    (state: IAppState) => state.calculateReduser.loading,
    (data, loading) => {
        return {data, loading};
    }
);

export const CalculatDispatch = (dispatch: IAppDispatch) => ({
    initialExchange: () => dispatch(CalculateCelebrateData.LoadItems())
});
