import {styled} from "../../core/styledComponents";

export const NewsContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    max-width: 1152px;
    @media (max-width: 766px) {
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        flex-direction: column;
    }
    @media (min-width: 421px) {
        padding: 0 !important;
    }
    background-color: ${(props) => props.theme.colors.beige};
    margin-top: 20px;
    margin-bottom: 44px;
`;
