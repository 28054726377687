import React from "react";

/* tslint:disable:max-line-length */
export const IconFlagUSA = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <path fill="none" d="M0 0h32v32H0z" />
            <circle cx="16.324" cy="16.324" r="14.324" fill="#F5F3F3" fillRule="nonzero" />
            <g fill="#FC1C44" fillRule="nonzero">
                <path d="M15.7 16.324h14.947a14.34 14.34 0 0 0-.493-3.737H15.7v3.737zM15.7 8.85h12.845a14.4 14.4 0 0 0-3.305-3.736H15.7V8.85zM16.324 30.647c3.37 0 6.47-1.165 8.916-3.114H7.407a14.262 14.262 0 0 0 8.917 3.114zM4.102 23.797h24.443a14.238 14.238 0 0 0 1.609-3.737H2.494a14.238 14.238 0 0 0 1.608 3.737z" />
            </g>
            <path
                fill="#0052B4"
                fillRule="nonzero"
                d="M8.635 4.237H9.94l-1.214.882.464 1.427-1.214-.882-1.214.882.4-1.233A14.402 14.402 0 0 0 4.384 8.41h.418l-.772.562c-.12.2-.236.405-.347.612l.37 1.136-.69-.5c-.17.362-.327.733-.468 1.111l.407 1.252h1.5l-1.214.882.464 1.427-1.214-.882-.727.529c-.073.585-.111 1.18-.111 1.786h14.324V2c-2.83 0-5.468.82-7.689 2.237zM9.19 14.89l-1.214-.882-1.214.882.464-1.427-1.215-.882h1.501l.464-1.427.464 1.427h1.5l-1.214.882.464 1.427zm-.464-5.6l.464 1.428-1.214-.882-1.214.882.464-1.428L6.01 8.41h1.501l.464-1.427.464 1.427h1.5l-1.214.882zm5.602 5.6l-1.214-.882-1.214.882.463-1.427-1.214-.882h1.5l.465-1.427.463 1.427h1.501l-1.214.882.464 1.427zm-.464-5.6l.464 1.428-1.214-.882-1.214.882.463-1.428-1.214-.882h1.5l.465-1.427.463 1.427h1.501l-1.214.882zm0-4.172l.464 1.427-1.214-.882-1.214.882.463-1.427-1.214-.882h1.5l.465-1.427.463 1.427h1.501l-1.214.882z"
            />
        </g>
    </svg>
);
