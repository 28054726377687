import {createSelector} from "reselect";
import {amountOfFirms} from "../../common/constants";
import {IAppState} from "../../core/store/mainReducer";

export const searchResultState = createSelector(
  (state: IAppState) => state.directorySearch.data,
  (state) => state.directorySearch.loading,
  (state) => state.search,
  (directorySearchData, loading, state) => {
    const countPage = Math.ceil(directorySearchData.count / amountOfFirms);

    const options = state.city.filter((item) => item.id !== 9);
    state.city.map((item) => {
      if (item.id === 9) {
        options.push(item);
      }
    });

    const searchText = state.text;

    return {directorySearchData, countPage, loading, options, searchText};
  }
);
