import {INewsFilter} from "../../api/dto/NewsFilter.g";
import {NewsApiRequest} from "../../api/NewsApiRequest.g";
import {amountOfSimilarNews} from "../../common/constants";
import {IThunkAction} from "../../core/store/mainReducer";
import {similarNewsItems} from "./similarNewsAction";

export const GetSimilarNews = {
  LoadItem(params: string, newsId: string): IThunkAction {
    const api = new NewsApiRequest();

    const request: INewsFilter = {
      title: "",
      categoryId: params,
      exceptNewsId: newsId
    };

    return async (dispatch) => {
      dispatch(similarNewsItems.started(params));
      try {
        const result = await api.getByFilter(1, amountOfSimilarNews, request);
        dispatch(similarNewsItems.done({params, result}));
      } catch (error) {
        dispatch(similarNewsItems.failed({params, error}));
      }
    };
  }
};
