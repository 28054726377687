import React, {ChangeEvent} from "react";
import {styled} from "../../../core/styledComponents";
import {CustomInput} from "../../../ui/CustomInput";
import {Select} from "../../../ui/reactSelect";
import {Span} from "../../../ui/span";

interface IProps {
  get: boolean;
  currency: string;
  value: number;
  onChange?: (value: number) => void;
  options: any[];
  onChangeCurrency: (currency: string) => void;
}

export class CurrencyAmount extends React.Component<IProps> {
  public render(): JSX.Element {
    const {get, value, options, currency, onChangeCurrency} = this.props;

    return (
      <Container get={get}>
        <Title>{get ? "Меняю" : "Получаю"}</Title>
        <div style={{boxSizing: "border-box", display: "flex", flexDirection: "row", alignItems: "center"}}>
          <Select
            options={options}
            value={{value: currency, label: currency.toUpperCase()}}
            onChange={onChangeCurrency}
          />
          <AmountContainer>
            <Amount
              type="number"
              value={(Math.round(value * 100) / 100).toString()}
              onChange={this.onChange}
            />
          </AmountContainer>
        </div>
      </Container>
    );
  }

  private onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange != null) {
      this.props.onChange(+e.target.value);
    }
  };
}

const Container = styled.div<{get: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: ${(props) => (props.get ? "0px" : "10px")};
`;

const Title = styled(Span)`
    font-size: 16px;
    color: ${(props) => props.theme.colors.slate};
    margin-bottom: 4px;
    font-family: SFUIText, sans-serif;
`;

const Amount = styled(CustomInput)`
    box-sizing: border-box;
    font-size: 16px;
    color: ${(props) => props.theme.colors.mainGrey};
    background-color: ${(props) => props.theme.colors.lightGrey};
    height: 38px;
    border-radius: 136px;
    width: 100%;
    text-align: center;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const AmountContainer = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    height: 38px;
    border-radius: 136px;
    margin-left: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
`;
