import React from "react";
import {IFirm} from "../../api/dto/Firm.g";
import {styled} from "../../core/styledComponents";

export const BlackListItem: React.FC<IFirm> = (props) => {
  return (
    <Container>
      <Title>{props.name}</Title>
      <Text>{props.blacklistDescription}</Text>
    </Container>
  );
};

const Title = styled.h3`
    font-family: FiraSansMedium, sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    padding-bottom: 7px;
    @media (max-width: 991px) {
        font-size: 16px;
    }
`;

const Text = styled.p`
    font-family: SFUIText, sans-serif;
    font-size: 16px;
    color: #495664;
    margin: 8px 0 8px 0;
    white-space: pre-line;
`;

const Container = styled.div`
    margin: 9px 0;
    display: flex;
    cursor: pointer;
    background: #ffffff;
    border-radius: 8px;
    flex-direction: column;
    padding: 16px;
`;
