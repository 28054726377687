/*tslint:disable*/
import {BaseRequest} from "./BaseRequest";
import {IMessage} from "./dto/Message.g";

export class EmailApiRequest extends BaseRequest {
  sendMessage(webId: string, message: IMessage, config?: Object): Promise<void> {
    return this.fetch(
      `/allApi/Email/SendMessage/${webId}`,
      Object.assign(
        {
          method: "POST",
          body: JSON.stringify(message)
        },
        config
      )
    ).catch(BaseRequest.handleError);
  }

  sendErrorMessage(message: IMessage, config?: Object): Promise<void> {
    return this.fetch(
      `/allApi/Email/SendErrorMessage`,
      Object.assign(
        {
          method: "POST",
          body: JSON.stringify(message)
        },
        config
      )
    ).catch(BaseRequest.handleError);
  }

  sendInfoMessage(message: IMessage, config?: Object): Promise<void> {
    return this.fetch(
      `/allApi/Email/SendInfoMessage`,
      Object.assign(
        {
          method: "POST",
          body: JSON.stringify(message)
        },
        config
      )
    ).catch(BaseRequest.handleError);
  }
}
