import {RouterState} from "connected-react-router";
import {Action, combineReducers} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AddCompanyReducer} from "../../modules/addCompany/addCompanyReducer";
import {advertisingReducer} from "../../modules/advertising/advertisingReducer";
import {BankReducer} from "../../modules/bank/bankReducer";
import {BlackListReducer} from "../../modules/blackList/blackListReducer";
import {CalendarReducer} from "../../modules/calendar/calendarReducer";
import {CompanyItemReducer} from "../../modules/companyItem/companyItemReduser";
import {directorySearchReducer} from "../../modules/directorySearch/directorySearchReducer";
import {CalculateReducer} from "../../modules/exchangeCourse/exchangeCourseReduser";
import {NewsItemReducer} from "../../modules/newsItem/newsItemReducer";
import {newsSearchCategoryReduser} from "../../modules/newsSearch/newsCategory/categoryReduser";
import {newsSearchReducer} from "../../modules/newsSearch/newsSearchReducer";
import {PopupReducer} from "../../modules/popup/popupReducer";
import {rubricatorReducer} from "../../modules/rubricator/rubricatorReduser";
import {searchReducer} from "../../modules/search/serachReduser";
import {SimilarNewsReducer} from "../../modules/similarNews/similarNewsReduser";

export const mainReducer = combineReducers({
    directorySearch: directorySearchReducer,
    newsSearch: newsSearchReducer,
    newsCategory: newsSearchCategoryReduser,
    search: searchReducer,
    advertising: advertisingReducer,
    newsItem: NewsItemReducer,
    rubricator: rubricatorReducer,
    similar: SimilarNewsReducer,
    companyItem: CompanyItemReducer,
    addCompany: AddCompanyReducer,
    blackList: BlackListReducer,
    calendarReducer: CalendarReducer,
    calculateReduser: CalculateReducer,
    bankReducer: BankReducer,
    popupReducer: PopupReducer,
});

export interface IAppState extends ReturnType<typeof mainReducer> {
    router: RouterState;
}

export interface IAppDispatch extends ThunkDispatch<IAppState, Error, Action> {}

// export type SimpleThunk = (dispatch: IAppDispatch, getState: () => IAppState) => Promise<void>;
export interface IThunkAction extends ThunkAction<Promise<void>, IAppState, Error, Action> {}
