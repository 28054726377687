import {AdvertisementApiRequest} from "../../api/AdvertisementApiRequest.g";
import {IFirmFilter} from "../../api/dto/FirmFilter.g";
import {IAppDispatch, IThunkAction} from "../../core/store/mainReducer";
import {AdvertisingAction} from "./advertisingAction";

export const AllAdvertising = {
  GetAdvertising(text?: string): IThunkAction {
    const api = new AdvertisementApiRequest();

    return async (dispatch: IAppDispatch, getState) => {
      const cityId = getState().search.city.filter((key) => key.title === getState().search.cityTitle);
      const reqest: IFirmFilter = {
        searchString: text,
        cityId: cityId.length !== 0 && cityId[0].id !== 9 ? cityId[0].id : undefined
      };
      dispatch(AdvertisingAction.advertising.started(""));
      try {
        const result = await api.getAdvertisingFirms(4, reqest);
        dispatch(AdvertisingAction.advertising.done({params: "", result}));
      } catch (error) {
        dispatch(AdvertisingAction.advertising.failed({params: "", error}));
      }
    };
  }
};
