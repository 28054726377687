import React from "react";
import {styled} from "../../core/styledComponents";

interface IProps {
  color: string
}

export const IconNavigator = (props: IProps) => (
  <Svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2652 15.718L16.7914 0.655554C16.8679 0.47896 16.8289 0.273461 16.6932 0.137305C16.5574 0.0011798 16.3524 -0.0378826 16.1764 0.038836L1.15732 6.58382C0.985939 6.65851 0.876195 6.82789 0.876195 7.0136C0.876195 7.02245 0.876444 7.03136 0.876942 7.0402C0.888004 7.23623 1.01969 7.40457 1.20689 7.46198L5.78263 8.86548C6.84113 9.19013 7.66637 10.0178 7.99012 11.0793L9.38957 15.6683C9.44684 15.856 9.6147 15.9881 9.81014 15.9992C10.0056 16.0103 10.1872 15.8981 10.2652 15.718Z"
      fill={props.color}/>
  </Svg>
);

const Svg = styled.svg`
  margin-right: 10px;
`;
