import React from "react";
import {styled} from "../../../../core/styledComponents";

interface IProps {
  wide?: boolean;
  img?: string;
  src?: string;
  index?: number;
}

export const FooterAdvertisingElement: React.FC<IProps> = (props) => {
  React.useEffect(() => {
    const div = document.createElement("div");
    div.innerHTML =
      "<script type=\"text/javascript\">" +
      "function ilogik_showAd165450128(){" +
      "ilogik_clr=\"000000;FFFFFF;0000FF;000000;008000\";" +
      "ilogik_or=0;" +
      "ilogik_uid=681;" +
      "ilogik_rid=285;" +
      "ilogik_sid=\"234x60\";" +
      "ilogik_nid=158;" +
      "ilogik_rnd=54503661;" +
      "ilogik_height=60;" +
      "ilogik_width=234;" +
      `postscribe("#tan-item-${
        props.index
        }", '<script type="text/javascript" src="//tan.pridnestrovie.com/js/displayer.js"></s'+'cript>');` +
      "}ilogik_showAd165450128();</script>";

    const script = div.children[0] as HTMLScriptElement;
    const mapScript = document.createElement("script");
    mapScript.text = script.text;

    const mapElement = document.getElementById(`tan-item-${props.index}`);
    if (mapElement != null) {
      mapElement.appendChild(mapScript);
    }
  }, []);

  return <Container className={"tan-item"} id={`tan-item-${props.index}`}/>;
};

const Container = styled.div<{wide?: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 17%;
    overflow: hidden;
    flex-grow: 1;
    min-width: 250px;

    @media (max-width: 1199px) {
        margin-bottom: 16px;
        align-items: center;
    }
`;
