import moment from "moment";
import "moment/locale/ru";
import React from "react";
import {Provider} from "react-redux";
import {Route, Router, Switch} from "react-router-dom";
import {Dispatch} from "redux";
import "./app.css";
import "./bootstrap.min.css";
import {Footer} from "./components/componentAllPage/footer/footer";
import {PopUp} from "./components/componentAllPage/header/componentHeader/popup/popUp";
import {Header} from "./components/componentAllPage/header/header";
import {ScrollTop} from "./components/componentAllPage/scrollTop";
import {ScreenAddCompany} from "./components/screenAddCompany";
import {ScreenBlackList} from "./components/screenBlackList";
import {ScreenCalendar} from "./components/ScreenCalendar";
import {ScreenCompany} from "./components/ScreenCompany";
import {ScreenContacts} from "./components/screenContacts";
import {ScreenErrorPage} from "./components/screenErrorPage";
import {ScreenHome} from "./components/screenHome";
import {RubricatorMobile} from "./components/screenHomeComponent/categories/rubrikatorMobile/rubricatorMobile";
import {ScreenNews} from "./components/screenNews";
import {ScreenNewsItem} from "./components/screenNewsItem";
import {ScreenResultSearchCompany} from "./components/screenResultSearchCompany";
import {ScreenLeaveMessage} from "./components/screenSendMessageCompany";
import {ScreenSitesList} from "./components/screenSitesList";
import {configureStore} from "./core/store/configureStore";
import {ThemeProvider} from "./core/styledComponents";
import {theme} from "./core/theme";
import {GetRubricator} from "./modules/rubricator/rubricatorActionAsync";
import {AllSearch} from "./modules/search/searchActionAsync";
import {AppContainer} from "./ui/AppContainer";

moment.locale("ru");
const {store, history} = configureStore();


export const App: React.FC = () => {
  React.useEffect(() => {
    (store.dispatch as Dispatch<any>)(AllSearch.InitialState());
    (store.dispatch as Dispatch<any>)(GetRubricator.Initial());
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <AppContainer>
            <Header/>
            <PopUp/>
            <Switch>
              <Route exact={true} path="/" component={ScreenHome}/>
              <Route path="/siteindex" component={ScreenSitesList}/>
              <Route path="/search" component={ScreenResultSearchCompany}/>
              <Route path="/calendar" component={ScreenCalendar}/>
              <Route path="/contacts" component={ScreenContacts}/>
              <Route path="/blackList" component={ScreenBlackList}/>
              <Route path="/newfirm" component={ScreenAddCompany}/>
              <Route exact={true} path="/news/item" component={ScreenNewsItem}/>
              <Route path="/news/search" component={ScreenNews}/>
              <Route path="/news" component={ScreenNews}/>
              <Route exact={true} path="/:id/:translate" component={ScreenCompany}/>
              <Route exact={true} path="/edit/:id/:translate" component={ScreenAddCompany}/>
              <Route exact={true} path="/:id/:translate/message" component={ScreenLeaveMessage}/>
              <Route exact={true} path="/messageForPmrmd" component={ScreenLeaveMessage}/>
              <Route path="/rubricator" component={RubricatorMobile}/>
              <Route component={ScreenErrorPage}/>
            </Switch>
            <Footer/>
            <ScrollTop/>
          </AppContainer>
        </ThemeProvider>
      </Router>
    </Provider>
  );
};
