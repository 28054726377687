import {reducerWithInitialState} from "typescript-fsa-reducers";
import {ICity} from "../../api/dto/City.g";
import {initializationCity, searchCity, searchText, suggestions} from "./searchAction";

export interface ISearchState {
  city: ICity[];
  cityTitle: string;
  text: string;
  suggestionsText: string;
}

export const searchInitialState: ISearchState = {
  city: [],
  cityTitle: "",
  text: "",
  suggestionsText: ""
};

export const searchReducer = reducerWithInitialState(searchInitialState)
  .case(searchText, (state, payload) => ({
    city: state.city,
    cityTitle: state.cityTitle,
    text: payload,
    suggestionsText: state.suggestionsText
  }))
  .case(searchCity, (state, payload) => ({
    city: state.city,
    cityTitle: payload,
    text: state.text,
    suggestionsText: state.suggestionsText
  }))

  .case(initializationCity.started, (state, payload) => ({
    city: state.city,
    cityTitle: "",
    text: state.text,
    suggestionsText: state.suggestionsText
  }))
  .case(initializationCity.done, (state, payload) => ({
    city: payload.result,
    cityTitle: "Всё Приднестровье",
    text: state.text,
    suggestionsText: state.suggestionsText
  }))
  .case(initializationCity.failed, (state, payload) => ({
    city: state.city,
    cityTitle: "",
    text: state.text,
    suggestionsText: state.suggestionsText
  }))

  .case(suggestions.started, (state, payload) => ({
    city: state.city,
    cityTitle: state.cityTitle,
    text: state.text,
    suggestionsText: state.suggestionsText
  }))
  .case(suggestions.done, (state, payload) => ({
    city: state.city,
    cityTitle: state.cityTitle,
    text: state.text,
    suggestionsText: payload.result
  }))
  .case(suggestions.failed, (state, payload) => ({
    city: state.city,
    cityTitle: state.cityTitle,
    text: state.text,
    suggestionsText: state.suggestionsText
  }));
