import React from "react";
import {IFirm} from "../../api/dto/Firm.g";
import {UrlBackend} from "../../common/constants";
import {css, styled} from "../../core/styledComponents";
import defaultImage from "../../shared/img/defoltImage.png";

export const SearchResultItem: React.FC<IFirm> = (props) => {
  const {name, phone, city, addressName} = props;
  return (
    <Container isPriority={props.isPriority}>
      {props.banner ? <ImageContainer isPriority={props.isPriority}>
          <Image src={UrlBackend + "/" + props.banner}/>
        </ImageContainer>
        : <ImageContainer isPriority={props.isPriority}>
          <Image src={defaultImage}/>
        </ImageContainer>
      }
      <Content>
        <Title>
          {name}
        </Title>
        <Text>{`${addressName && addressName + "."} Телефон: ${phone}`}</Text>
        <City>Город: {city ? city.title : "не указан"}</City>
      </Content>
    </Container>
  );
};

const ImageContainer = styled.div<{isPriority: boolean}>`
    @media (min-width: 992px) {
        display: ${props => props.isPriority ? "flex" : "none"};
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 102px;
    margin-right: 16px;
`;

const Image = styled.img`
    max-width: 100%;
`;

const Title = styled.span`
    display: inline-block;
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.blueAccent};
    cursor: pointer;
    text-decoration: underline;
    text-underline-position: under;
    @media (max-width: 991px) {
        font-size: 16px;
        color: #3e4c5b;
        text-decoration: none;
    }
    padding-bottom: 3px;
    text-decoration-color: ${(props) => props.theme.colors.blueAccent};
    `;

const Text = styled.p`
    font-family: SFUIText, sans-serif;
    font-size: 16px;
    color: #3e4c5b;
    margin: 8px 0 8px 0;
    @media (max-width: 991px) {
        display: none;
    }
`;

const City = styled.div`
    font-family: SFUITextMedium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #a1a9b3;
    display: inline;
    @media (max-width: 991px) {
        font-size: 12px;
        margin-top: 12px;
    }
`;

const Container = styled.div<{isPriority: boolean}>`
    margin-bottom: 15px;
    display: flex;
    cursor: pointer;
    padding: 15px;
    @media (max-width: 991px) {
        background: white;
    }
    
    ${(props) => props.isPriority ? css`background: white;border: 1px solid #0F77FF;` : ""}
`;

const Content = styled.div`
    width: 100%;
    @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        justify-content: space-between;
    }
`;
