import {IFirmFilter} from "../../api/dto/FirmFilter.g";
import {IFirmNameFilter} from "../../api/dto/FirmNameFilter.g";
import {FirmApiRequest} from "../../api/FirmApiRequest.g";
import {amountOfFirms} from "../../common/constants";
import {replaceSymbolSearch} from "../../common/replaceSymbolSearch";
import {IThunkAction} from "../../core/store/mainReducer";
import {initialization, searchSubCategories} from "./directorySearchAction";

export const SearchDirectory = {
  DirectoryAlphabetFilter(pageNumber: number, pageSize: number, symbol?: string): IThunkAction {
    const api = new FirmApiRequest();

    return async (dispatch, getState) => {
      const filter =
        symbol === "123"
          ? IFirmNameFilter.Digit
          : symbol === "ABCD"
          ? IFirmNameFilter.EnglishLetter
          : symbol === ""
            ? null
            : IFirmNameFilter.RussianLetter;
      dispatch(initialization.started(""));
      try {
        const result = await api.getList(
          pageNumber,
          pageSize,
          filter,
          filter === IFirmNameFilter.RussianLetter ? symbol : null
        );
        dispatch(initialization.done({params: "", result}));
      } catch (error) {
        dispatch(initialization.failed({params: "", error}));
      }
    };
  },

  RubricatorSearch(page: number, divisionId?: string, text?: string, city?: string): IThunkAction {
    const api = new FirmApiRequest();


    return async (dispatch, getState) => {
      const cityId = getState().search.city.filter((item) => item.title === city);

      const request: IFirmFilter = {
        searchString: replaceSymbolSearch(text || ''),
        cityId: cityId.length !== 0 && cityId[0].id !== 0 ? cityId[0].id : undefined,
        divisionId: divisionId || undefined
      };

      dispatch(searchSubCategories.started(""));
      try {
        const result = await api.getByFilter(page, amountOfFirms, request);
        dispatch(searchSubCategories.done({params: "", result}));
      } catch (error) {
        dispatch(searchSubCategories.failed({params: "", error}));
      }
    };
  }
};
