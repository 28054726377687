/*tslint:disable*/
import {BaseRequest} from "./BaseRequest";
import {IFirm} from "./dto/Firm.g";
import {IFirmFilter} from "./dto/FirmFilter.g";
import {IPageResult} from "./dto/PageResult.g";

export class AdvertisementApiRequest extends BaseRequest {
  getAdvertisingFirms(limit: number, filter: IFirmFilter, config?: Object): Promise<IPageResult<IFirm>> {
    const p: {[key: string]: any} = {};
    p["limit"] = limit;
    p["filter"] = filter;
    return this.fetch(
      `/allApi/Advertisement?${this.getObjectFlatter()(p)}`,
      Object.assign(
        {
          method: "GET"
        },
        config
      )
    )
      .then((response) => response.json())
      .catch(BaseRequest.handleError);
  }
}
