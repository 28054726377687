import React from "react";
import {useSelector} from "react-redux";
import {IAppState} from "../../../core/store/mainReducer";
import {styled} from "../../../core/styledComponents";
import {ScreenContainer} from "../../../ui/ScreenContainer";
import {Category} from "./components/category";
import {DropdownDesctop} from "./dropdownDesctop";
import {RubricatorMobile} from "./rubrikatorMobile/rubricatorMobile";

export const Categories: React.FC = () => {

  const [currentKey, setCurrentKey] = React.useState<string>();
  const searchResult = useSelector((state: IAppState) => state.rubricator.baseData);

  const onClick = (value: string) => {
    if (value === "Все категории") {
      setCurrentKey(currentKey === "0" ? undefined : "0");
    } else {
      const key = searchResult.filter((item) => item.name === value);
      setCurrentKey(key[0].id === currentKey ? undefined : key[0].id);
    }
  };

  const categories = React.useMemo(() => {
    return searchResult.map((key) => (
      <Category
        active={currentKey === key.id}
        key={key.id}
        category={key.name}
        onClick={onClick}
        iconPath={key.iconPath}
        activeIconPath={key.activeIconPath}
      />
    ));
  }, [searchResult, currentKey]);

  return (
    <ScreenContainer isWhite={true}>
      <OuterContainer id={"rubricator"}>
        <InnerContainer className={"col-sm-12"} style={{paddingLeft: 0, paddingRight: 0}}>
          <Category active={currentKey === "0"} key={0} category={"Все категории"} onClick={onClick}/>
          {categories}
        </InnerContainer>
        {currentKey !== undefined && <DropdownDesctop key={currentKey} data={currentKey}/>}
      </OuterContainer>

      <RubricatorMobile/>
    </ScreenContainer>
  );
};

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    @media (max-width: 420px) {
        display: none;
        padding: 0 15px !important;
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1152px;
    background-color: ${(props) => props.theme.colors.white};
    padding: 18px 15px;
    @media (max-width: 420px) {
        padding: 16px;
        flex-direction: column;
    }
`;
