import React from "react";
import {MetaTags} from "react-meta-tags";
import { replaceTranslate } from 'src/common/replaceTranslate';
import {IFirm} from "../../api/dto/Firm.g";
import {styled} from "../../core/styledComponents";
import {ContentCompany} from "./contentCompany";
import {FullScreenCarousel} from "./FullScreenCarousel";
import {LocationMap} from "./locationMap";
import {PhotoCompany} from "./photoCompany";
import {TitleCompany} from "./titleCompany";
import {VisitCompany} from "./visitCompany";

interface IProps {
  data: IFirm;
}

export const PageCompany: React.FC<IProps> = (props) => {
  const [activeImage, setActiveImage] = React.useState<number>();
  const [mapVisible, setMapVisible] = React.useState(true);
  
  const translateUrl = (id: string, name: string): string => {
    const newName = replaceTranslate(name);
    return `${window.location.origin}/${id}/${newName}.html`;
  };

  const clickButtonMap = (value: boolean) => {
    setMapVisible(value);
  };

  const clickImage = (value: number | undefined) => {
    setActiveImage(value);
  };

  let kindOfActivity = "";
  props.data.occupations
    .filter((item) => item.isShowForSite)
    .map((item) => (item.description.length !== 0 ? (kindOfActivity += `${item.description}<br/><br/>`) : ""));
  return (
    <Container>
      <MetaTags>
        <title>{props.data.name}</title>
        <meta name="description" content={`${props.data.tagDescription}`}/>
        <meta name="keywords" content={`${props.data.tagKeywords}`}/>

        {/*Метя теги для facebook и instagram*/}
        <meta name="og:title" content={props.data.name}/>
        <meta name="og:description" content={props.data.tagDescription}/>
        <meta property="og:image" content={window.location.host + "/" + props.data.banner}/>
        <link rel="canonical" href={translateUrl(props.data.webId, props.data.name)}/>
      </MetaTags>

      <TitleCompany
        image={props.data.banner}
        title={props.data.name}
        blackList={props.data.blacklistDescription}
        workCount={props.data.workCount}
        founded={props.data.founded}
      />
      <ContentCompany
        address={props.data.addressName}
        city={props.data.city.title}
        mapCode={props.data.mapCode}
        phone={props.data.phone}
        mail={props.data.hasEmail}
        site={props.data.site}
        activeTime={props.data.workHours}
        director={props.data.managers}
        salesDepartment={props.data.phone}
        tapButtonOpenMap={clickButtonMap}
        socialNetwork={props.data.socialNetworks}
        kindOfActivity={kindOfActivity}
        division={props.data.occupations ? props.data.occupations[0].subdivision ? props.data.occupations[0].subdivision.name : props.data.occupations[0].division.name : ""}
        additionInfo={props.data.description}
        skype={props.data.skype}
        icq={props.data.icq}
        fax={props.data.fax}
      />
      {props.data.photos && props.data.photos.length !== 0 &&
      <PhotoCompany clickImage={clickImage} photoCompany={props.data.photos} autoplay={props.data.isPriority}/>}

      <LocationMap
        mapVisible={mapVisible}
        tapButtonOpenMap={clickButtonMap}
        mapCode={props.data.mapCode}
        address={props.data.addressName}
        city={props.data.city.title}
      />

      <VisitCompany
        countOfViewsAll={props.data.countOfViewsAll}
        countOfViewsThisMonth={props.data.countOfViewsThisMonth}
        countOfViewsThisYear={props.data.countOfViewsThisYear}
      />

      {activeImage !== undefined &&
      <FullScreenCarousel close={clickImage} photos={props.data.photos} activeImage={activeImage}/>}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 41px;
  display: flex;
  flex-direction: column;
`;
