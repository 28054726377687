import React from "react";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {styled} from "../../core/styledComponents";
import {CompanyItemData} from "../../modules/companyItem/companyItemActionAsync";
import cross from "../../shared/img/cross.png";
import {ReCaptcha} from "../componentAllPage/captcha/ReCaptcha";

interface IPrors {
    errorVisible: boolean;
    closeError: () => void;
}

export const ErrorMessage: React.FC<IPrors> = (props) => {
    const {register, handleSubmit, errors} = useForm();
    const location = useLocation();
    const dispatch = useDispatch();

    const onSubmit = async (values: any) => {
        closeError();
        dispatch(
            CompanyItemData.SendErrorMassage({
                ...values,
                messageSenderLink: document.location.origin + location.pathname
            })
        );
    };

    const closeError = () => {
        props.closeError();
    };

    return (
        <Container className={"errorMessage"} style={{display: props.errorVisible ? "flex" : "none"}}>
            <div onClick={closeError}>
                <img
                    style={{position: "absolute", right: "18px", top: "10px", cursor: "pointer"}}
                    src={cross}
                    alt={"close"}
                />
            </div>
            <Title style={{marginBottom: "11px", marginTop: "28px"}}>Отправка сообщения об ошибке</Title>
            <Description>
                ВНИМАНИЕ! Для отправки сообщения необходимо заполнить все поля. Заранее благодарим.
            </Description>

            <form style={{display: "flex", flexDirection: "column", width: "100%"}} onSubmit={handleSubmit(onSubmit)}>
                <Block hasError={errors.name}>
                    <Title>Имя:</Title>
                    <input name="name" ref={register({required: true})} />
                </Block>
                <Block hasError={errors.phoneNumber}>
                    <Title>Тел.:</Title>
                    <input
                        name="phoneNumber"
                        ref={register({
                            required: true,
                            pattern: {value: /^\+?([\ \-\:\(\)]?\d+[\ \-\:\(\)]?){5}$/i, message: "invalid phone"}
                        })}
                    />
                </Block>
                <Block hasError={errors.email}>
                    <Title>E-mail:</Title>
                    <input name="email" ref={register({required: true})} />
                </Block>
                <Block hasError={errors.text}>
                    <textarea name="text" ref={register({required: true})} />
                </Block>

                <Block hasError={false}>
                    <ReCaptcha name={"captcha"} reff={register({required: true})} hasError={errors.captcha} />
                </Block>
                <Button type="submit">Отправить</Button>
            </form>
        </Container>
    );
};

const Container = styled.div`
    overflow: hidden;
    flex-direction: column;
    position: fixed;
    align-items: center;
    top: 100px;
    align-self: center;
    z-index: 2;
    width: 352px;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 16px;
    @media (max-width: 420px) {
        top: 0;
        max-height: 568px;
    }
    @media (max-width: 340px) {
        max-width: 300px;
    }
`;

const Title = styled.div`
    white-space: nowrap;
    font-family: SFUIText, sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
`;

const Description = styled.div`
    font-family: SFUIText, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
`;

const Block = styled.div<{hasError: boolean}>`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;

    input {
        width: 269px;
        height: 41px;
        border-radius: 8px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #9b9b9b;
        outline: none;
        padding: 0 10px;
        background-color: ${(props) => props.hasError && "#ff7283"};
        transition: background-color 1s ease;
        @media (max-width: 340px) {
            max-width: 200px;
        }
    }

    textarea {
        width: 316px;
        height: 123px;
        border-radius: 8px;
        border: solid 1px #9b9b9b;
        outline: none;
        padding: 0 10px;
        background-color: ${(props) => props.hasError && "#ff7283"};
        transition: background-color 1s ease;
        resize: none;
        @media (max-width: 420px) {
            height: 75px;
        }
    }
`;

const Button = styled("button")`
    border: solid 2px #0f77ff;
    width: 214px;
    margin-bottom: 11px;
    cursor: pointer;
    border-radius: 20px;
    padding: 10px 20px;
    color: #0f77ff;
    font-size: 16px;
    font-weight: 600;
    background: border-box;
    text-align: center;
    align-self: center;
    outline: none;
    :hover,
    :active {
        text-decoration: none !important;
        color: #fff !important;
        background: #0f77ff;
    }
`;
