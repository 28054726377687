import React from "react";

interface IProps {
    width: string;
    height: string;
}

export const IconOdnoclasniki = ({width, height}: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height={width} width={height}>
        <g id="icons8-odnoklassniki" transform="translate(-0.333)">
            <g id="Footer" transform="translate(-525 -216)">
                <g id="icons8-odnoklassniki-2" data-name="icons8-odnoklassniki" transform="translate(525.333 216)">
                    <path
                        id="Shape"
                        className="cls-1"
                        fill={"#ff9800"}
                        d="M32,27.556A4.4,4.4,0,0,1,27.556,32H4.444A4.4,4.4,0,0,1,0,27.556V4.444A4.4,4.4,0,0,1,4.444,0H27.556A4.4,4.4,0,0,1,32,4.444Z"
                    />
                    <path
                        id="Shape-2"
                        data-name="Shape"
                        className="cls-2"
                        fill={"#fff"}
                        d="M18.578,21.689a10.214,10.214,0,0,0,3.644-1.511,1.875,1.875,0,0,0-1.956-3.2,8.185,8.185,0,0,1-8.533,0,1.875,1.875,0,0,0-1.956,3.2,11.75,11.75,0,0,0,3.644,1.511L9.867,25.244a1.933,1.933,0,0,0,0,2.667,1.809,1.809,0,0,0,1.333.533,1.96,1.96,0,0,0,1.333-.533L16,24.444l3.467,3.467a1.886,1.886,0,0,0,2.667-2.667ZM16,3.556a6.222,6.222,0,1,0,6.222,6.222A6.184,6.184,0,0,0,16,3.556Zm0,8.889a2.667,2.667,0,1,1,2.667-2.667A2.619,2.619,0,0,1,16,12.444Z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const IconOdnoclasnikiCircle = ({width, height}: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" height={height} width={width}>
        <g id="Новость" transform="translate(-216 -1402)">
            <g id="Group-2" transform="translate(216 1402)">
                <circle id="Oval-4" fill={"#f5a623"} cx="17" cy="17" r="17" />
                <g id="icons8-odnoklassniki" transform="translate(10 6)">
                    <path
                        id="Shape"
                        fill={"#fff"}
                        d="M7,3.328A2.35,2.35,0,1,1,4.648,5.68,2.354,2.354,0,0,1,7,3.328Zm0,8.027A5.678,5.678,0,1,0,1.324,5.68,5.684,5.684,0,0,0,7,11.355Zm2.3,4.633a10.706,10.706,0,0,0,3.3-1.367A1.664,1.664,0,1,0,10.82,11.8a7.215,7.215,0,0,1-7.645,0,1.663,1.663,0,1,0-1.77,2.816,10.706,10.706,0,0,0,3.3,1.367L1.527,19.16a1.664,1.664,0,0,0,2.355,2.352L7,18.395l3.121,3.117a1.663,1.663,0,0,0,2.352-2.352Z"
                    />
                </g>
            </g>
        </g>
    </svg>
);
