// tslint:disable:jsx-no-lambda
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {IAppState} from "../../../core/store/mainReducer";
import {styled} from "../../../core/styledComponents";
import {AddCompany} from "../../../modules/addCompany/addCompanyActionAsync";
import {IconPlus} from "../../../shared/icons/IconPlus";
import {
  ReactFormInput,
  ReactFormInputContainer,
  ReactFormLabel,
  ReactFormTextArea
} from "../../../ui/elementReactForm/inputForReactForm";
import {ListRemoveElement} from "../../../ui/ListRemoveElement";
import {SelectCustom} from "../../../ui/selectCustom";
import {IOption} from "../validation";

export const addCompanyCategoriesState = createSelector(
  (state: IAppState) => state.addCompany.division,
  (state: IAppState) => state.addCompany.subDivision,
  (division, subDivision) => ({division, subDivision})
);

interface IProps {
  reff: any;
  selectReff: any;
  hasError?: any;
}

export const Activities: React.FC<IProps> = (props) => {
  const [occupations, setOccupations] = React.useState<Array<{id: number, divisionId: string, subdivisionId: string, description: string}>>([]);
  const [occupationsValue, setOccupationsValue] = React.useState("");
  const [options, setOptions] = React.useState<IOption[]>([]);
  const [subOptions, setSubOptions] = React.useState<IOption[]>([]);

  const state = useSelector(addCompanyCategoriesState);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(AddCompany.LoadCategories());
  }, []);

  React.useEffect(() => {
    if (state.division) {
      setOptions(state.division.map((params) => ({value: params.id, label: params.name})));
    }
  }, [state.division]);

  React.useEffect(() => {
    if (state.subDivision) {
      setSubOptions(state.subDivision.map((params) => ({value: params.id, label: params.name})));
    }
  }, [state.subDivision]);

  const add = () => {
    setOccupationsValue("1");
    setOccupations([...occupations, {divisionId: "", subdivisionId: "", description: "", id: occupations.length}]);
  };

  const remove = (value: number) => () => {
    setOccupations(occupations.filter(elem => elem.id !== value));
    if (occupations.length === 1) {
      setOccupationsValue("");
    }
  };

  const getCategories = (value: string, id: number) => {
    setOccupations(occupations.map(elem => {
      if (elem.id === id) {
        return {...elem, divisionId: value};
      } else {
        return elem;
      }
    }));

    const param = state.division && state.division.filter((params) => params.id === value);
    if (param) {
      dispatch(AddCompany.LoadSubCategories(param[0].id));
    }
  };

  const getSubCategories = (value: string, id: number) => {
    setOccupations(occupations.map(elem => {
      if (elem.id === id) {
        return {...elem, subdivisionId: value};
      } else {
        return elem;
      }
    }));
  };

  return (
    <ReactFormInputContainer>
      <Label hasError={props.hasError.occupations}>
        <div>Вид деятельности<span>*</span></div>
        <MobilePlus onClick={add}>
          <IconPlus width={"32px"} height={"32px"}/>
        </MobilePlus>
      </Label>
      <DesctopPlus onClick={add}>
        <IconPlus width={"46px"} height={"46px"}/>
      </DesctopPlus>
      <div/>
      <ReactFormInput
        name={"occupations"}
        ref={props.reff}
        defaultValue={occupationsValue}
        style={{display: "none"}}
      />

      <DataContainer>
        {occupations.map((item) =>
          <React.Fragment key={item.id}>
            <ContainerLine>
              <ContainerInput>
                <ReactFormLabel>Рубрика:</ReactFormLabel>
                <SelectCustom
                  options={options}
                  defaultValue={item.divisionId}
                  onSelect={(value) => getCategories(value.value.toString(), item.id)}
                />
                <ReactFormInput
                  name={`occupations[${item.id}].divisionId`}
                  ref={props.selectReff}
                  defaultValue={item.divisionId}
                  style={{display: "none"}}
                />
              </ContainerInput>

              <ContainerInput>
                <ReactFormLabel>Подрубрика:</ReactFormLabel>
                <SelectCustom
                  options={subOptions}
                  defaultValue={item.subdivisionId}
                  onSelect={(value) => getSubCategories(value.value.toString(), item.id)}
                />
                <ReactFormInput
                  name={`occupations[${item.id}].subdivisionId`}
                  ref={props.selectReff}
                  defaultValue={item.subdivisionId}
                  style={{display: "none"}}
                />
              </ContainerInput>


              <ContainerInput>
                <ReactFormLabel>Описание:</ReactFormLabel>
                <ReactFormTextArea
                  name={`occupations[${item.id}].description`}
                  ref={props.reff}
                  hasError={props.hasError.occupations && props.hasError.occupations[`${item.id}`] && props.hasError.occupations[`${item.id}`].description}
                />
              </ContainerInput>
            </ContainerLine>
            <ListRemoveElement onClick={remove(item.id)}/>
          </React.Fragment>
        )}
      </DataContainer>
    </ReactFormInputContainer>
  );
};


const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 88% 10%;
  grid-column-gap: 2%;
  margin-top: 20px;
`;

const ContainerInput = styled(ReactFormInputContainer)`
  margin: 16px 0;
`;

const ContainerLine = styled.div`
  display: grid;
  grid-template-columns: 100%;
  
  margin-bottom: 20px;
  
  @media(max-width: 768px){
    grid-template-columns: 100% ;
  }
`;

const MobilePlus = styled.span`
  @media(min-width: 769px){
    display: none;
  }
`;

const DesctopPlus = styled.span`
  @media(max-width: 768px){
    display: none;
  }
`;

const Label = styled(ReactFormLabel)`
  @media(max-width: 768px){
    display: flex;
    justify-content: space-between;
  }
`;
