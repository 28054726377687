export const replaceTranslate = (name: string) => {
  let newName = name.toLowerCase();
  newName = newName.replace(/а/g, "a");
  newName = newName.replace(/б/g, "b");
  newName = newName.replace(/в/g, "v");
  newName = newName.replace(/г/g, "g");
  newName = newName.replace(/д/g, "d");
  newName = newName.replace(/е/g, "e");
  newName = newName.replace(/ё/g, "e");
  newName = newName.replace(/з/g, "z");
  newName = newName.replace(/и/g, "i");
  newName = newName.replace(/й/g, "y");
  newName = newName.replace(/к/g, "k");
  newName = newName.replace(/л/g, "l");
  newName = newName.replace(/м/g, "m");
  newName = newName.replace(/н/g, "n");
  newName = newName.replace(/о/g, "o");
  newName = newName.replace(/п/g, "p");
  newName = newName.replace(/р/g, "r");
  newName = newName.replace(/с/g, "s");
  newName = newName.replace(/т/g, "t");
  newName = newName.replace(/у/g, "u");
  newName = newName.replace(/ф/g, "f");
  newName = newName.replace(/х/g, "h");
  newName = newName.replace(/ъ/g, "j");
  newName = newName.replace(/ы/g, "i");
  newName = newName.replace(/э/g, "e");
  newName = newName.replace(/ж/g, "zh");
  newName = newName.replace(/ц/g, "ts");
  newName = newName.replace(/ч/g, "ch");
  newName = newName.replace(/ш/g, "sh");
  newName = newName.replace(/щ/g, "shch");
  newName = newName.replace(/ь/g, "");
  newName = newName.replace(/ю/g, "yu");
  newName = newName.replace(/я/g, "ya");
  newName = newName.replace(/\./g, "");
  newName = newName.replace(/\,/g, "");
  newName = newName.replace(/\-/g, "_");
  newName = newName.replace(/ /g, "_");
  newName = newName.replace(/\//g, "");
  newName = newName.replace(/#/g, "");
  newName = newName.replace(/\?/g, "");

  return newName;
};
