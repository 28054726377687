import React from "react";
import {styled} from "../../../core/styledComponents";

export const UsefulLinks: React.FC = () => {
  return <Container>
    <Title>Полезные ссылки:</Title>
    <Link><a target={"_blank"} href="http://president.gospmr.org/"> Президент ПМР </a></Link>
    <Link><a target={"_blank"} href="http://www.vspmr.org/"> Верховный Совет ПМР </a></Link>
    <Link><a target={"_blank"} href="https://pravopmr.ru/"> Законодательство ПМР </a></Link>
    <Link><a target={"_blank"} href="http://gov-pmr.org/"> Правительство ПМР </a></Link>
    <Link><a target={"_blank"} href="http://customs.gospmr.org/"> Таможенный комитет ПМР </a></Link>
    <Link><a target={"_blank"} href="http://minfin-pmr.org/"> Минфин ПМР </a></Link>
    <Link><a target={"_blank"} href="http://mer.gospmr.org/"> Минэкономразвития ПМР </a></Link>
    <Link><a target={"_blank"} href="http://minjust.org/web.nsf"> Минюст ПМР </a></Link>
    <Link><a target={"_blank"} href="http://arbitr.gospmr.org/"> Арбитражный суд ПМР </a></Link>
  </Container>;
};

const Container = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  @media (max-width: 1200px) {
    display: none;
  }
`;
const Title = styled.div`
  margin-right: 5px;
  margin-bottom: 4px;
  font-family: SFUITextSemibold, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #3e4c5b;
`;
const Link = styled.li`
  margin: 4px 0;
  a {
    font-family: SFUITextMedium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: rgba(74, 74, 74, 0.8);
    line-height: normal;
    cursor: pointer;
    :hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.blueAccent};
    }
  }
`;
