import {reducerWithInitialState} from "typescript-fsa-reducers";
import {IExchangeCourse} from "../../api/dto/ExchangeCourse.g";
import {exchangeCourse} from "./exchangeCourseAction";

interface ICalculate {
    data: IExchangeCourse[];
    error: boolean;
    loading: boolean;
}

const CalculateInitialState: ICalculate = {
    data: [],
    error: false,
    loading: true
};

export const CalculateReducer = reducerWithInitialState(CalculateInitialState)
    .case(exchangeCourse.started, (state) => ({data: state.data, error: false, loading: true}))
    .case(exchangeCourse.done, (state, payload) => ({data: payload.result, error: false, loading: false}))
    .case(exchangeCourse.failed, (state) => ({data: state.data, error: true, loading: false}));
