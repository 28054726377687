import moment from "moment";
import React from "react";
import styled, {css} from "styled-components";
import {EnumCalendarDayTypes} from "../../../common/enums/calendarDayTypes";
import {Span} from "../../../ui/span";

interface IProps {
  dayType?: EnumCalendarDayTypes;
  value: string;
  day?: moment.Moment;
  description?: string;
  onTooltips?: (description: string, visibleTooltips?: moment.Moment) => void;
  clickDay?: (desription: string, visibleTooltips?: moment.Moment) => void;
}

export const CalendarCell: React.FC<IProps> = (props) => {
  const onClick = () => {
    if (props.clickDay) {
      props.clickDay(props.description || "", props.day);
    }
  };

  return (
    <Container>
      <InnerContainer dayType={props.dayType} onClick={onClick}>
        <Text dayType={props.dayType != null ? props.dayType : EnumCalendarDayTypes.Standard}>
          {props.value}
        </Text>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 2px;
    padding-right: 2px;
    cursor: pointer;
    @media (max-width: 420px) {
        padding: 0;
    }
`;

const InnerContainer = styled.div<{dayType?: EnumCalendarDayTypes}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.white};

    ${(props) => props.dayType === EnumCalendarDayTypes.DayOff && css`border: solid 2px rgba(74, 74, 74, 0.49);`};
    ${(props) => props.dayType === EnumCalendarDayTypes.Selected && css`background-color: ${props.theme.colors.blueAccent};`};
    ${(props) => props.dayType === EnumCalendarDayTypes.Holiday && css`border: solid 2px ${props.theme.colors.rose};`};
    ${(props) => props.dayType === EnumCalendarDayTypes.ProfessionalHoliday && css`background: #efefef;`};
    ${(props) => props.dayType === EnumCalendarDayTypes.Interval && css`border: solid 2px ${props.theme.colors.mainGrey};`};
    ${(props) => props.dayType === EnumCalendarDayTypes.HoliHoliday && css`border: solid 2px ${props.theme.colors.rose};background: ${props.theme.colors.rose};`};

    @media (max-width: 1199px) {
        width: 35px;
        height: 35px;
    }
    @media (max-width: 991px) {
        width: 38px;
        height: 38px;
    }
    @media (max-width: 370px) {
        width: 35px;
        height: 35px;
    }
`;

const Text = styled<{dayType?: EnumCalendarDayTypes}>(({dayType, ...rest}) => (
  <Span {...rest} />
))`
color: ${({dayType, theme}) => {
  switch (dayType) {
    case EnumCalendarDayTypes.Inactive:
      return theme.colors.mainGrey;
    case EnumCalendarDayTypes.Holiday:
      return theme.colors.rose;
    case EnumCalendarDayTypes.HoliHoliday:
      return theme.colors.white;
    case EnumCalendarDayTypes.Weekend:
      return theme.colors.rose;
    case EnumCalendarDayTypes.Selected:
      return theme.colors.white;
    default:
      return theme.colors.slate;
  }
}};
position: relative;
font-size: ${({dayType}) => dayType === EnumCalendarDayTypes.DayOfWeek || dayType === EnumCalendarDayTypes.Weekend ? "16px" : "14px"};
`;
