import {styled} from "../core/styledComponents";

export const Checkbox = styled.label`
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 20px;
    color: #3e4c5b;
    vertical-align: middle;
    margin-left: 40px;
    width: 220px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid #979797;
        border-radius: 4px;
    }
    :hover input ~ .checkmark {
        background-color: #ccc;
    }
    input:checked ~ .checkmark {
        background-color: #2196f3;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
    .checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    @media screen and (max-width: 991px) {
        margin-top: 16px;
        margin-left: 0;
    }
`;
