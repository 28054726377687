import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {IAppState} from "../../../../../core/store/mainReducer";
import {styled} from "../../../../../core/styledComponents";
import {PopupAction} from "../../../../../modules/popup/popupAction";
import {IconError, IconLoading, IconSuccess} from "../../../../../shared/icons/iconLoading";

export const PopUp: React.FC = () => {
    const state = useSelector((stateRedux: IAppState) => stateRedux.popupReducer);
    const [show, setShow] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        if (state.visible) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [state.visible]);

    const dispatch = useDispatch();
    const hidePopup = () => {
        dispatch(PopupAction.updateDate.failed({params: null, error: new Error()}));
        history.push("/");
    };

    return show ? (
        <Container>
            {state.loading && (
                <>
                    <ContainerLoading>
                        <IconLoading />
                    </ContainerLoading>
                    <Outside />
                </>
            )}
            {state.type === "success" && (
                <>
                    <ContainerPass onClick={hidePopup}>
                        <IconSuccess />
                        <span>Успешно отправлено</span>
                    </ContainerPass>
                    <Outside onClick={hidePopup} />
                    {setTimeout(() => {
                        hidePopup();
                    }, 3000)}
                </>
            )}
            {state.type === "error" && (
                <>
                    <ContainerFail onClick={hidePopup}>
                        <IconError />
                        <span>Ошибка!</span>
                    </ContainerFail>
                    <Outside onClick={hidePopup} />
                    {setTimeout(() => {
                        hidePopup();
                    }, 3000)}
                </>
            )}
        </Container>
    ) : (
        <></>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const Outside = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #333333;
    opacity: 0.1;
`;

const ContainerFail = styled.div`
    padding: 10px !important;
    background-color: red;
    color: white;
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 400px;
    height: auto;
    z-index: 1;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    @media (max-width: 420px) {
        width: 150px;
        height: 100px;
        font-size: 14px;
        z-index: 999;
    }
`;

const ContainerPass = styled.div`
    padding: 10px !important;
    background-color: #0f77ff;
    color: white;
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 400px;
    height: auto;
    z-index: 1;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    @media (max-width: 420px) {
        width: 150px;
        height: 100px;
        font-size: 14px;
        z-index: 999;
    }
`;

const ContainerLoading = styled.div`
    display: flex;
    background: #ffffff;
    padding: 30px;
    border-radius: 12px;
    svg {
        animation: rotate123 1000ms infinite;

        @keyframes rotate123 {
            from {
                transform: rotate(0);
            }
            to {
                transform: rotate(-360deg);
            }
        }
    }
`;
