import React from "react";
import {ICity} from "../api/dto/City.g";
import {styled} from "../core/styledComponents";
import {IconArrowSearch} from "../shared/icons/IconArrowSearch";

interface IProps {
  options: ICity[];
  onSelect: (value: ICity) => void;
  defaultValue: string;
}

export const SelectSearch: React.FC<IProps> = (props) => {
  const [value, setValue] = React.useState(props.defaultValue);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("click", closeList);
    return () => document.removeEventListener("click", closeList);
  });

  React.useEffect(()=>{
    setValue(props.defaultValue);
  },[props.defaultValue]);

  const openList = () => {
    setOpen(true);
  };

  const closeList = () => {
    if (open) {
      setOpen(false);
    }
  };

  const onSelect = (option: ICity) => {
    setValue(option.title);
    props.onSelect(option);
    closeList()
  };

  const options = React.useMemo(() => {
    return <List open={open}>
      {/*tslint:disable-next-line*/}
      {props.options.map(item => <ListItem key={item.id} onClick={() => onSelect(item)}>
        {item.title}
      </ListItem>)}
    </List>;
  }, [props, open]);

  return <SelectContainer>
    <Field onClick={openList} open={open}>
      {/*<span>{value ? value : "..."}</span>*/}
      {/*<IconArrowSearch/>*/}

      <div style={{flexDirection: "column", position: "relative", width: "100%"}}>
        <div>
          <SmallText>Город, район</SmallText>
          <IconArrowSearch/>
        </div>
        <Value>{value}</Value>
      </div>
    </Field>
    {options}
  </SelectContainer>;

};

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 230px;
  
  @media(max-width: 700px){
    display: none;
  }
`;
const Field = styled.div<{open: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  height: 100%;
  
  font-size: 14px;
  cursor: pointer;
`;
const List = styled.ul<{open: boolean;}>`
  display:${(props) => props.open ? "block" : "none"}  ;
  position: absolute;
  width: 100%;
  background: white;
  box-sizing: border-box;
  list-style: none;
  z-index: 10;
  padding: 0;
  margin: 0;
  top: 66px;
  box-shadow: rgba(0, 0, 0, 0.2)  0 11px 9px 0;
`;

const ListItem = styled.li`
  font-family: SFUIText, sans-serif;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  color: #a1a9b3;
  &:hover {
    background: #0f77ff;
    color: white;
  }
`;

const SmallText = styled.span`
  font-family: SFUIText, sans-serif;
  font-size: 10px;
  margin-right: 4px;
  user-select: none;
  text-decoration: none;
  color: ${(props) => props.theme.colors.mainGrey};
`;

const Value = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #3e4c5b;
  font-family: ${(props) => props.theme.fontFamily};
`;
