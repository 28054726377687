import React from "react";
import {useHistory} from "react-router";
import styled from "styled-components";
import imgPortal from "../../shared/img/imgPortal.png";
import {Button} from "../../ui/Button";
import {ScreenContainer} from "../../ui/ScreenContainer";
import {SharedContent} from "../componentAllPage/footer/sharedLinks";
import {SocialNetwork} from "../componentAllPage/footer/socialNetwork";
import {TitleCompany} from "../screenCompanyComponent/titleCompany";

export const PageContacts: React.FC = () => {
  const history = useHistory();

  const sendMail = () => {
    history.push("/messageForPmrmd");
  };

  return (
    <ScreenContainer isWhite={true} style={{marginBottom: 44, paddingBottom: 28, paddingTop: 21}}>
      <TitleCompany
        pmrmd={true}
        image={imgPortal}
        title={"Бизнес-портал Приднестровья, \n" + "ООО \"ДИВА и К\""}
      />
      <Content>
        <Block>
          <Title>Адрес:</Title>
          <Description>г. Тирасполь, ул. 1 Мая, 2 (ЦДА "Азимут"), корпус "А", к. № 6</Description>
        </Block>
        <Block>
          <Title>Адрес для писем:</Title>
          <Description>MD-Приднестровье, 3300, г. Тирасполь, г/п, а/я № 17</Description>
        </Block>
        <Block>
          <Title>Телефоны:</Title>
        </Block>
        <Block>
          <SubTitle>для звонков из ПМР:</SubTitle>
          <PhonesContainer>
            <Phone href={`tel: ${"0 (775) 8-11-11"}`} children={"0 (775) 8-11-11"}/>
            <Phone href={`tel: ${"0 (533) 8-65-65"}`} children={"0 (533) 8-65-65"}/>
            <Phone href={`tel: ${"0 (777) 80-116"}`} children={"0 (777) 80-116"}/>
          </PhonesContainer>
        </Block>
        <Block>
          <SubTitle>для звонков из-за границы:</SubTitle>
          <div style={{display: "flex", flexDirection: "column"}}>
            <PhonesContainer>
              <Phone href={`tel: ${"+(373-775) 8-11-11"}`} children={"+(373-775) 8-11-11"}/>
              <Phone href={`tel: ${"+(373-533) 8-65-65"}`} children={"+(373-533) 8-65-65"}/>
            </PhonesContainer>
            <MessangerContainer>
              <SharedContent noTitle={true} noHideOnMobile={true}/>
            </MessangerContainer>
          </div>
        </Block>
        <BlockWorkHours>
          <Title>Рабочие часы:</Title>
          <Description>9:00-17:00, вых. сб.-вс.</Description>
        </BlockWorkHours>
      </Content>
      <div style={{display: "flex", flexDirection: "column"}}>
        <Button onClick={sendMail}>Отправить сообщение</Button>
      </div>
      <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
        <TitleNetworks>Мы в социальных сетях: </TitleNetworks>
        <SocialNetworkContainer>
          <SocialNetwork noTitle={true} noHideOnMobile={true}/>
        </SocialNetworkContainer>
      </div>
    </ScreenContainer>
  );
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

const PhonesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    a {
        ::after {
            content: ",\00a0";
        }
    }
    a:nth-last-child(1) {
        ::after {
            content: "";
        }
    }
`;

const Phone = styled.a`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    color: #00377f;
    font-family: SFUITextMedium, sans-serif;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
`;

const Block = styled.div`
    display: flex;
    @media (max-width: 770px) {
        flex-direction: column;
    }
`;

const BlockWorkHours = styled(Block)`
    margin-bottom: 40px;
    @media (max-width: 420px) {
        margin-bottom: 20px;
    }
`;

const Title = styled.div`
    width: 255px;
    margin-right: 30px;
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    color: #000;
    font-weight: 500;
    margin-bottom: 9px;
`;

const SubTitle = styled.div`
    width: 255px;
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    color: #000;
    font-weight: 500;
    margin: 0 30px 9px 40px;
`;

const TitleNetworks = styled.div`
    font-family: Helvetica, sans-serif;
    font-size: 24px;
    color: #4a4a4a;
    margin-top: 32px;
    @media (max-width: 420px) {
        margin-top: 20px;
        text-align: center;
    }
`;

const Description = styled.div`
    font-family: SFUIText, sans-serif;
    font-size: 16px;
    color: #3e4c5b;
    margin-bottom: 16px;
`;

const SocialNetworkContainer = styled.div`
    display: flex;
    margin-top: 21px;
    @media (max-width: 420px) {
        margin-top: 10px;
    }
`;

const MessangerContainer = styled.div`
    display: flex;
    margin-bottom: 21px;
    @media (max-width: 420px) {
        margin-bottom: 0;
        justify-content: center;
    }
`;
