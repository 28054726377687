import {createBrowserHistory} from "history";
import {applyMiddleware, compose, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import {mainReducer} from "./mainReducer";

const composeEnhancers = process.env.NODE_ENV === "production"
  ? compose
  : composeWithDevTools({
    shouldHotReload: false
  });

export function configureStore() {
  const history = createBrowserHistory();
  const enhancer = composeEnhancers(applyMiddleware(thunk));
  const store = createStore(mainReducer, enhancer);

  return {store, history};
}
