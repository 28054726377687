import queryString from "query-string";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {IUrlRubricator} from "../../../../common/createURL/urlRubricator";
import {IAppState} from "../../../../core/store/mainReducer";
import {styled} from "../../../../core/styledComponents";
import {GetRubricator} from "../../../../modules/rubricator/rubricatorActionAsync";
import {DropdownColumn} from "../dropdownColumn";

interface IProps {
    data: string;
    categoryName: string;
    categoryId: string;
}

export const DropdownMobile: React.FC<IProps> = (props) => {
    const history = useHistory();
    const attributes: IUrlRubricator = React.useMemo(() => queryString.parse(history.location.search), [
        history.location.search
    ]);
    const searchResult = useSelector((state: IAppState) => state.rubricator.data);
    const [current, setCurrent] = React.useState<string>();
    const [categoryId, setCategoryId] = React.useState(props.categoryId);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (props.data === "0") {
            dispatch(GetRubricator.AllCategory());
        } else {
            if (attributes.id && attributes.name) {
                dispatch(GetRubricator.SubCategory(attributes.id));
                setCurrent(attributes.name);
                setCategoryId(attributes.id);
            } else {
                setCurrent(undefined);
            }
            // dispatch(GetRubricator.SubCategory(props.data));
        }
    }, [attributes]);

    const onClick = async (key: string) => {
        const name = searchResult.filter((item) => item.id === key)[0].name;

        // await dispatch(GetRubricator.SubCategory(key));

        // try {
        history.push(window.location.pathname + `?active=${props.data}&id=${key}&name=${name}`);
        //   setCurrent(name);
        // } catch (e) {
        //   history.push(`./search?category=${key}`);
        // }
    };

    return (
        <Container className="container">
            <div className="row">
                <DropdownColumn
                    key={props.data}
                    data={searchResult}
                    onClick={onClick}
                    current={categoryId}
                    offset={20}
                    title={current}
                />
            </div>
        </Container>
    );
};

const Container = styled.div`
    padding: 20px 15px;

    @media (min-width: 421px) {
        display: none;
    }
`;
