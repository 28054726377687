import React from "react";
import styled from "styled-components";

export class IconScrol extends React.Component<IEmpty> {
    public render() {
        return (
            <Container>
                <div id="section10" className="demo">
                    <a href={"#rubricator"}>
                        <span />
                    </a>
                </div>
            </Container>
        );
    }
}

const Container = styled.div`
    #section10 a span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 30px;
        height: 50px;
        margin-left: -15px;
        border: 2px solid #a1a9b3;
        border-radius: 50px;
        box-sizing: border-box;
    }

    #section10 a span::before {
        position: absolute;
        top: 10px;
        left: 50%;
        content: "";
        width: 6px;
        height: 6px;
        margin-left: -3px;
        background-color: #a1a9b3;
        border-radius: 100%;
        -webkit-animation: sdb10 2s infinite;
        animation: sdb10 2s infinite;
        box-sizing: border-box;
    }

    #section10 a span::after {
        position: absolute;
        bottom: -18px;
        left: 50%;
        width: 18px;
        height: 18px;
        content: "";
        margin-left: -9px;
        border-left: 1px solid #a1a9b3;
        border-bottom: 1px solid #a1a9b3;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        box-sizing: border-box;
    }

    @-webkit-keyframes sdb10 {
        0% {
            -webkit-transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            -webkit-transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes sdb10 {
        0% {
            transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
`;
