/* tslint:disable:prefer-for-of */
import moment from "moment";
import React from "react";
import {useSelector} from "react-redux";
import {DayType} from "../../../api/dto/DayType.g";
import {EnumCalendarDayTypes} from "../../../common/enums/calendarDayTypes";
import {IAppState} from "../../../core/store/mainReducer";
import {CalendarCell} from "./calendarCell";
import {CalendarRow, TooltipsCalendar} from "./calendarRow";

interface IProps {
  startDay: moment.Moment;
  week: number;
  currentDay: moment.Moment;
  clickDay: (desription: string, day: moment.Moment) => void;
  descriptionTooltips?: string;
  visibleTooltips: moment.Moment
}

export const Days: React.FC<IProps> = (props) => {

  const [visibleTooltips, setVisibleTooltips] = React.useState(false);

  const calendarData = useSelector((state: IAppState) => state.calendarReducer.data);
  const startDayIndex = props.startDay.day() === 0 ? 7 : props.startDay.day();

  React.useEffect(() => {
    setVisibleTooltips(props.currentDay.date() > 7 * props.week - startDayIndex + 1 && props.currentDay.date() <= 7 * props.week - startDayIndex + 8);
  }, [props.currentDay]);

  const getDayType = React.useMemo(() => (day: moment.Moment, startDay: moment.Moment): EnumCalendarDayTypes => {
    if (props.currentDay.clone().startOf("day").toString() === day.clone().startOf("day").toString()) {
      return EnumCalendarDayTypes.Selected;
    }
    if (day.month() !== startDay.month()) {
      return EnumCalendarDayTypes.Inactive;
    }

    for (let i = 0; i < calendarData.length; i++) {
      if (day.date() === moment(calendarData[i].date).date()) {
        const weekDay = moment(calendarData[i].date).isoWeekday();
        const typeDay = calendarData[i].type;
        if (typeDay === DayType.Holiday || typeDay === DayType.OffDay) {
          if (typeDay === DayType.Holiday && (weekDay === 6 || weekDay === 7)) {
            return EnumCalendarDayTypes.HoliHoliday;
          } else {
            return EnumCalendarDayTypes.Holiday;
          }
        } else {
          if (typeDay === DayType.ShortDay) {
            return EnumCalendarDayTypes.Interval;
          } else {
            if (typeDay === DayType.ProfessionalHoliday && (weekDay === 6 || weekDay === 7)) {
              return EnumCalendarDayTypes.HoliHoliday;
            } else {
              return EnumCalendarDayTypes.ProfessionalHoliday;
            }
          }
        }
      }
    }

    return EnumCalendarDayTypes.Standard;
  }, [calendarData, props.currentDay]);

  const days = React.useMemo(() => {
    return Array(7)
      .fill(1)
      .map((item, index) => props.startDay.clone().add(7 * props.week + index - startDayIndex + 1, "days"));
  }, [props.week]);

  const tooltips = React.useMemo(() => {
    return props.descriptionTooltips
      && props.visibleTooltips
      && visibleTooltips
      && <TooltipsCalendar
            visible={props.visibleTooltips.week() - moment(props.visibleTooltips).startOf("month").week() === props.week}
        >
        {props.descriptionTooltips}
        </TooltipsCalendar>;
  }, [props.visibleTooltips, visibleTooltips]);

  const daysComponents = React.useMemo(() => {
    return days.map((day) => {
      const data = calendarData.filter(
        (value) => moment(value.date).format("DD.MM.YY") === day.format("DD.MM.YY")
      );

      return (
        <CalendarCell
          key={day.format("D")}
          value={day.format("D")}
          dayType={getDayType(day, props.startDay)}
          day={day}
          description={data && data.length !== 0 ? data[0].description : undefined}
          clickDay={props.clickDay}
        />
      );
    });
  }, [props.currentDay, props.clickDay]);

  return React.useMemo(() => {
    if (days[0].toDate() <= props.startDay.clone().endOf("month").toDate()) {
      return <><CalendarRow>{daysComponents}</CalendarRow> {tooltips}</>;
    } else {
      return null;
    }
  }, [tooltips, daysComponents]);

};

