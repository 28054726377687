import {DivisionApiRequest} from "../../api/DivisionApiRequest.g";
import {IFirmShort} from "../../api/dto/FirmShort.g";
import {FirmApiRequest} from "../../api/FirmApiRequest.g";
import {IThunkAction} from "../../core/store/mainReducer";
import {PopupAction} from "../popup/popupAction";
import {AddCompanyAction} from "./addCompanyAction";

export const AddCompany = {
  CreatingCompany(params: IFirmShort): IThunkAction {
    const api = new FirmApiRequest();

    return async (dispatch) => {
      try {
        dispatch(PopupAction.updateDate.started(null));
        await api.sendCreateEmail(params);
        // const result = "Запрос на добавление предприятия отправлен.\n Ожидайте, вскоре с вами свяжутся";

        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "success"
        }));
      } catch (error) {
        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "error"
        }));
      }
    };
  },

  UpdatingCompany(id: string, params: IFirmShort, url: string): IThunkAction {
    const api = new FirmApiRequest();

    return async (dispatch) => {
      try {
        dispatch(PopupAction.updateDate.started(null));
        await api.sendUpdateEmail(url, params);
        // const result =          "Запрос на обновление информации о предприятии отправлен. Ожидайте, вскоре с вами свяжутся";
        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "success"
        }));
      } catch (error) {
        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "error"
        }));
      }
    };
  },

  LoadCategories(): IThunkAction {
    const api = new DivisionApiRequest();

    return async (dispatch) => {
      dispatch(AddCompanyAction.loadCategories.started({params: {}}));
      try {
        const result = await api.getRoot();
        dispatch(AddCompanyAction.loadCategories.done({params: {}, result}));
      } catch (error) {
        dispatch(AddCompanyAction.loadCategories.failed({params: {}, error}));
      }
    };
  },

  LoadSubCategories(id: string): IThunkAction {
    const api = new DivisionApiRequest();

    return async (dispatch) => {
      dispatch(AddCompanyAction.loadSubCategories.started({param: {}}));
      try {
        const result = await api.getAllSub(id);
        dispatch(AddCompanyAction.loadSubCategories.done({params: {}, result}));
      } catch (error) {
        dispatch(AddCompanyAction.loadSubCategories.failed({params: {}, error}));
      }
    };
  }
};
