import {reducerWithInitialState} from "typescript-fsa-reducers";
import {IFirm} from "../../api/dto/Firm.g";
import {IPageResult} from "../../api/dto/PageResult.g";
import {AdvertisingAction} from "./advertisingAction";

export interface IAdvertisingGet {
  loading: boolean;
  data: IPageResult<IFirm>;
  error: boolean;
}

const AdvertisingInitialState: IAdvertisingGet = {
  loading: false,
  data: {
    count: 0,
    items: []
  },
  error: false
};

export const advertisingReducer = reducerWithInitialState(AdvertisingInitialState)
  .case(AdvertisingAction.advertising.started, (state) => ({data: state.data, loading: true, error: false}))
  .case(AdvertisingAction.advertising.done, (state, payload) => ({data: payload.result, loading: false, error: false}))
  .case(AdvertisingAction.advertising.failed, (state) => ({data: state.data, loading: false, error: true}));
