import React from "react";
import {IDivision} from "../../../api/dto/Division.g";
import {styled} from "../../../core/styledComponents";
import {DropdownItem} from "./dropdownItem";

interface IProps {
  data: IDivision[] | null;
  current?: string;
  onClick: (key: string, offset: number) => void;
  offset: number;
  title?: string | null;
}

export const DropdownColumn: React.FC<IProps> = (props) => {
  const containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  const onClick = (key: string, offset: number) => {
    props.onClick(key, offset);
  };

  React.useEffect(()=>{
    const {offset} = props;
    const current = containerRef.current;
    if (current != null) {
      current.style.marginTop = `${offset}px`;
    }
  },[props.offset]);

  return props.data === null
    ? null
    : <div className="col-md-3" ref={containerRef} style={{paddingRight: 25}}>
      {props.title && <DropdownTitle>{props.title}</DropdownTitle>}
      {props.title && <SearchPreviewCategory href={`./search?category=${props.current}`}>
        {props.title}
      </SearchPreviewCategory>}
      {props.data.map((key) => <DropdownItem key={key.id}
                                             id={key.id}
                                             name={key.name}
                                             active={props.current === key.id}
                                             onClick={onClick}
                                             childrenCount={key.childrenCount}/>)}
    </div>;
};

const DropdownTitle = styled.div`
    font-family: SFUIText, sans-serif;
    font-size: 14px;
    color: #a1a9b3;
    padding: 0 0 20px 0;
    display: block;
    cursor: default;
`;
const SearchPreviewCategory = styled.a`
    font-family: SFUIText, sans-serif;
    font-size: 14px;
    color: #3e4c5b;

    :hover,
    :active,
    :focus {
        text-decoration: none;
        color: #0f77ff;
    }
`;
