import React from "react";
import {styled} from "../../../../core/styledComponents";
import {FooterAdvertisingElement} from "./footerAdvertisingElement";

export const FooterAdvertising: React.FC = () => {
  return (
    <Container className={"tan-list"}>
      <FooterAdvertisingElement wide={false} index={1}/>
      <FooterAdvertisingElement wide={false} index={2}/>
      <FooterAdvertisingElement wide={false} index={3}/>
    </Container>
  );
};


const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
    @media (max-width: 770px) {
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 770px) {
        margin-bottom: 50px;
    }
`;
