import {styled} from "../core/styledComponents";

export const CustomInput = styled.input`
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-decoration: none;
    color: ${(props) => props.theme.colors.slate};
    border: 0;
    &::placeholder {
        color: ${(props) => props.theme.colors.mainGrey};
        opacity: 1;
    }
    &:focus {
        outline: none;
    }
    caret-color: ${(props) => props.theme.colors.blueAccent};
`;
