// @flow

import omit from "lodash/omit";
import React from "react";
import ReactSelect from "react-select";
import ReactAsyncSelect from "react-select/lib/Async";
import * as defaultComponents from "./defaultComponents";

interface IOption {
    value: any;
    label: string;
    disabled?: boolean;
}

interface IProps {
    value?: string | IOption;
    hasError?: boolean;
    disabled?: boolean;
    searchable?: boolean;
    async?: boolean;
    options?: IOption[] | string[];
    onChange?: (option: string | any) => any;
    getOptionValue?: (option: any) => any;
    components?: {[key: string]: any};
    onSelect?: (option: string | any) => any;
    placeholder?: string;
}

interface IState {
    value: string | IOption | undefined;
}

export class Select extends React.Component<IProps, IState> {
    public static defaultProps = {
        disabled: false,
        searchable: false,
        hasError: false,
        onChange: () => null,
        async: false,
        getOptionValue: (option: IOption): any => (option && option.value ? option.value : option)
    };

    public static getDerivedStateFromProps(nextProps: IProps): IState | null {
        // const {value, options, getOptionValue} = nextProps;
        const {value} = nextProps;

        if (value) {
            // Suggest we have an options array
            // if (Array.isArray(options) && options != null && getOptionValue != null) {
            //     return {value: (options.find(getOptionValue) as string)};
            // }

            return {value};
        }

        return null;
    }

    public state: IState = {
        value: undefined
    };

    private components: {[key: string]: any};

    constructor(props: IProps) {
        super(props);
        const {components} = this.props;
        this.components = {
            ...defaultComponents,
            ...(components != null ? components : {})
        };
    }

    public render() {
        const {disabled, searchable, hasError, async, ...props} = this.props;
        const rest: IProps = omit(props, ["onChange", "getOptionValue", "value", "components"]);

        const {value} = this.state;

        const FinalComponent = async ? ReactAsyncSelect : ReactSelect;

        return (
          // @ts-ignore
            <FinalComponent
                value={value}
                onChange={this.handleChange}
                components={this.components}
                isDisabled={disabled}
                isSearchable={searchable}
                isClearable={false}
                isMulti={false}
                // theme={theme}
                // hasError={hasError}
                noOptionsMessage={this.getNoOptionsMessage}
                loadingMessage={this.getLoadingMessage}
                {...rest}
            />
        );
    }

    private handleChange = (option: any) => {
        const {onChange, getOptionValue, onSelect} = this.props;
        if (onChange != null && getOptionValue != null) {
            const value = getOptionValue(option);
            onChange(value);
            if (onSelect) {
                onSelect(value);
            }
        }
    };

    private getNoOptionsMessage = (): string => "Ничего не найдено";

    private getLoadingMessage = (): string => "Загрузка...";
}

// Select.Async = (props) => <Select cacheOptions {...props} searchable clearable async />;
