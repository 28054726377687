import styled from "styled-components";
import {Button} from "../../ui/Button";

export const LeaveMessageContainer = styled.div`
margin: 80px auto;
`;

export const LeaveMessageButton = styled(Button)`
    width: 214px;
    margin-right: 24px;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
        margin: 0 0 5px 0;
        width: 45%;
    }
    @media screen and (max-width: 420px) {
        width: 100%;
    }
`;

export const CompanyInfoContainer = styled.div`
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
    }
`;

export const CompanyInfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        align-items: center;
        margin-left: 0;
    }
`;

export const CompanyInfoImage = styled.div`
    img {
        height: auto;
        max-width: 255px;
        border-radius: 4px;
        border-image-repeat: stretch;
        object-fit: contain;
        margin-right: 30px;
    }
    @media (max-width: 768px) {
        margin-top: 10px;
    }
`;

export const CompanyInfoTitle = styled.span`
    font-family: SFUITextMedium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #3e4c5b;
    margin-bottom: 15px;
    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const CompanyInfoChecked = styled.span`
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a1a9b3;
    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const CompanyInfoWebsite = styled.a`
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0f77ff;
    text-decoration: none;
    margin-bottom: 32px;
    cursor: pointer;
    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const LeaveMessageHeader = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
  margin: 10px 0;
  
  span:nth-child(1) {
    font-family: Helvetica, sans-serif;
    font-size: 24px;
  }
  span:nth-child(2) {
    font-family: SFUITextMedium, sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;
