import React from "react";

interface IProps {
    color: string;
}
export const IconCategoryAll = ({color}: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g fill="none" fillRule="evenodd">
            <path fill="none" d="M0 0h34v34H0z" />
            <rect width="8" height="8" x="2" y="3" fill={color || "#a1a9b3"} fillOpacity="1.677" rx="3" />
            <rect width="8" height="8" x="2" y="14" fill={color || "#a1a9b3"} fillOpacity="1.677" rx="3" />
            <rect width="8" height="8" x="2" y="25" fill={color || "#a1a9b3"} fillOpacity="1.677" rx="3" />
            <rect width="8" height="8" x="13" y="3" fill={color || "#a1a9b3"} fillOpacity="1.677" rx="3" />
            <rect width="8" height="8" x="13" y="14" fill={color || "#a1a9b3"} fillOpacity="1.677" rx="3" />
            <rect width="8" height="8" x="13" y="25" fill={color || "#a1a9b3"} fillOpacity="1.677" rx="3" />
            <rect width="8" height="8" x="24" y="3" fill={color || "#a1a9b3"} fillOpacity="1.677" rx="3" />
            <rect width="8" height="8" x="24" y="14" fill={color || "#a1a9b3"} fillOpacity="1.677" rx="3" />
            <rect width="8" height="8" x="24" y="25" fill={color || "#a1a9b3"} fillOpacity="1.677" rx="3" />
        </g>
    </svg>
);
