import React from "react";

/* tslint:disable:max-line-length */
interface IProps {
    color: string;
}
export const IconChevron = ({color}: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
        <g fill="none" fillRule="evenodd">
            <path
                fill={color}
                fillRule="nonzero"
                d="M13.737 10.73l-5.271 5.265a.859.859 0 0 1-1.215-1.213l4.665-4.66-4.664-4.658a.857.857 0 1 1 1.214-1.213l5.271 5.266a.854.854 0 0 1 0 1.212z"
            />
        </g>
    </svg>
);
