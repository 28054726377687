import queryString from "query-string";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {UrlBackend} from "../../common/constants";
import {IUrlPageDirectory} from "../../common/createURL/urlPageDirectory";
import {replaceTranslate} from "../../common/replaceTranslate";
import {IAppState} from "../../core/store/mainReducer";
import {styled} from "../../core/styledComponents";
import {AllAdvertising} from "../../modules/advertising/advertisingActionAsync";


export const AdvertisingSearchNoBootstrap: React.FC = () => {
  const attributes: IUrlPageDirectory = React.useMemo(() => (queryString.parse(window.location.search)), [window.location.search]);
  const result = useSelector((state: IAppState) => (state.advertising.data.items));

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(AllAdvertising.GetAdvertising(attributes.text));
  }, [attributes]);

  const href = (id: string, name: string) => {
    const newName = replaceTranslate(name);
    return "/" + id + "/" + newName + ".html";
  };

  const elemAdvertising = React.useMemo(() => {
    return result.map((item, index) => (
      <Content key={index} isCorrespond={item.isCorrespond}>
        <Block href={href(item.webId, item.name)}>
          {item.banner && (<Image src={UrlBackend + "/" + item.banner}/>)}
          <Title>{item.cdTitle}</Title>
          <Description>
            {item.cdDescription.substr(0, 100)}
            {item.cdDescription.length > 100 ? "..." : ""}
          </Description>
          {item.cdPhone && <Title>тел.: {item.cdPhone}</Title>}
        </Block>
        <Site
          padding={!!(item.cdSite && item.cdSite.length !== 0)}
          href={"http://" + item.cdSite.split(", ")[0]}
          target={"_blank"}
        >
          {item.cdSite.split(", ")[0]}
        </Site>
      </Content>
    ));
  }, [result]);

  return (
    <Container>
      {elemAdvertising}
    </Container>
  );
};

const Image = styled.img`
    border-radius: 4px;
`;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  @media (max-width: 991px) {
    display:flex;
    align-items: center;
  }
`;

const Content = styled.div<{isCorrespond?: boolean}>`
  display: flex;
  margin: 0 15px 24px;
  flex-direction: column;
  padding: 17px 30px 8px 30px;
  background-color: white;
  cursor: pointer;
  align-items: center;
  
  @media screen and (max-width: 991px){
    justify-content: center;
    align-items: center;
    flex-basis: auto;
    width: 320px;
    
    display:${props => props.isCorrespond ? "flex" : "none"};
  }
`;

const Title = styled.div`
    font-family: SFUITextMedium, sans-serif;
    font-size: 20px;
    color: #3e4c5b;
    padding-top: 8px;
    width: 100%;
`;
const Description = styled.div`
    font-family: SFUIText, sans-serif;
    font-size: 16px;
    color: #3e4c5b;
    padding-top: 8px;
    width: 100%;
`;
const Site = styled.a<{padding: boolean}>`
    font-family: SFUITextMedium, sans-serif;
    background-color: white;
    font-size: 16px;
    color: #3e4c5b;
    padding: ${(props) => (props.padding ? "17px 30px" : "0")};
    margin: 0 15px;
`;
const Block = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: none;
    }
`;
