import React from "react";

interface IProps {
    width: string;
    height: string;
}

export const IconInstagram = ({width, height}: IProps) => (
    <svg
        id="icons8-instagram_new_2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width={width}
        height={height}>
        <g id="Footer" transform="translate(-632 -216)">
            <g id="icons8-instagram_new-2" transform="translate(632 216)">
                <g id="icons8-instagram_new">
                    <path
                        id="Shape"
                        className="cls-1"
                        fill={"#304ffe"}
                        d="M23.111,32H8.889A8.915,8.915,0,0,1,0,23.111V8.889A8.915,8.915,0,0,1,8.889,0H23.111A8.915,8.915,0,0,1,32,8.889V23.111A8.915,8.915,0,0,1,23.111,32Z"
                    />
                    <path
                        id="Shape-2"
                        className="cls-2"
                        fill={"rgba(48,79,254,0.29)"}
                        d="M0,8.889V23.111A8.915,8.915,0,0,0,8.889,32H23.111A8.915,8.915,0,0,0,32,23.111V8.889A7.669,7.669,0,0,0,31.644,6.4,29.185,29.185,0,0,0,13.333,0,28.492,28.492,0,0,0,3.822,1.6,8.846,8.846,0,0,0,0,8.889Z"
                    />
                    <path
                        id="Shape-3"
                        className="cls-3"
                        fill={"#6200ea"}
                        d="M13.333,1.778A28.275,28.275,0,0,0,1.067,4.622,8.933,8.933,0,0,0,0,8.889V23.111A8.915,8.915,0,0,0,8.889,32H23.111A8.915,8.915,0,0,0,32,23.111V9.067A27.377,27.377,0,0,0,13.333,1.778Z"
                    />
                    <path
                        id="Shape-4"
                        className="cls-4"
                        fill={"#673ab7"}
                        d="M32,11.556a25.829,25.829,0,0,0-18.667-8A25.423,25.423,0,0,0,.178,7.2,9.845,9.845,0,0,0,0,8.889V23.111A8.915,8.915,0,0,0,8.889,32H23.111A8.915,8.915,0,0,0,32,23.111Z"
                    />
                    <path
                        id="Shape-5"
                        className="cls-5"
                        fill={"#8e24aa"}
                        d="M32,14.222A23.888,23.888,0,0,0,13.333,5.333,23.645,23.645,0,0,0,0,9.422V23.111A8.915,8.915,0,0,0,8.889,32H23.111A8.915,8.915,0,0,0,32,23.111Z"
                    />
                    <path
                        id="Shape-6"
                        className="cls-6"
                        fill={"#c2185b"}
                        d="M32,23.111V17.244A22.406,22.406,0,0,0,13.333,7.111,22.45,22.45,0,0,0,0,11.556V23.111A8.915,8.915,0,0,0,8.889,32H23.111A8.915,8.915,0,0,0,32,23.111Z"
                    />
                    <path
                        id="Shape-7"
                        className="cls-7"
                        fill={"#d81b60"}
                        d="M32,23.111V20.978A20.416,20.416,0,0,0,0,13.867v9.244A8.915,8.915,0,0,0,8.889,32H23.111A8.915,8.915,0,0,0,32,23.111Z"
                    />
                    <path
                        id="Shape-8"
                        className="cls-8"
                        fill={"#f50057"}
                        d="M31.644,25.6A18.686,18.686,0,0,0,0,16.267v6.844A8.915,8.915,0,0,0,8.889,32H23.111A8.88,8.88,0,0,0,31.644,25.6Z"
                    />
                    <path
                        id="Shape-9"
                        className="cls-9"
                        fill={"#ff1744"}
                        d="M30.133,28.444A16.814,16.814,0,0,0,0,19.022v4.089A8.915,8.915,0,0,0,8.889,32H23.111A8.837,8.837,0,0,0,30.133,28.444Z"
                    />
                    <path
                        id="Shape-10"
                        className="cls-10"
                        fill={"#ff5722"}
                        d="M13.333,14.222A15.064,15.064,0,0,0,0,22.222v.889A8.915,8.915,0,0,0,8.889,32H23.111a8.311,8.311,0,0,0,5.244-1.778c0-.267.089-.622.089-.889A15.1,15.1,0,0,0,13.333,14.222Z"
                    />
                    <path
                        id="Shape-11"
                        className="cls-11"
                        fill={"#ff6f00"}
                        d="M13.333,16A13.26,13.26,0,0,0,.444,25.956,8.839,8.839,0,0,0,8.889,32H23.111a8.509,8.509,0,0,0,3.378-.711,14.535,14.535,0,0,0,.178-1.956A13.315,13.315,0,0,0,13.333,16Z"
                    />
                    <path
                        id="Shape-12"
                        className="cls-12"
                        fill={"#ff9800"}
                        d="M13.333,17.778A11.41,11.41,0,0,0,1.867,28.444,8.837,8.837,0,0,0,8.889,32H23.111a8.758,8.758,0,0,0,1.511-.178,11.245,11.245,0,0,0,.267-2.489A11.533,11.533,0,0,0,13.333,17.778Z"
                    />
                    <path
                        id="Shape-13"
                        className="cls-13"
                        fill={"#ffc107"}
                        d="M22.756,32a9.176,9.176,0,0,0,.356-2.667,9.778,9.778,0,1,0-19.556,0,3.024,3.024,0,0,0,.089.889A9.081,9.081,0,0,0,8.889,32Z"
                    />
                    <path
                        id="Shape-14"
                        className="cls-14"
                        fill={"#ffd54f"}
                        d="M13.333,21.333a7.967,7.967,0,0,0-8,8A7.577,7.577,0,0,0,5.6,31.378,8.035,8.035,0,0,0,8.889,32h12a8.379,8.379,0,0,0,.444-2.667A7.967,7.967,0,0,0,13.333,21.333Z"
                    />
                    <path
                        id="Shape-15"
                        className="cls-15"
                        fill={"#ffe082"}
                        d="M13.333,23.2a6.184,6.184,0,0,0-6.222,6.222,5.965,5.965,0,0,0,.533,2.489A5.868,5.868,0,0,0,8.889,32H19.022a6.376,6.376,0,0,0,.533-2.578A6.184,6.184,0,0,0,13.333,23.2Z"
                    />
                    <path
                        id="Shape-16"
                        className="cls-16"
                        fill={"#ffecb3"}
                        d="M13.333,24.978a4.4,4.4,0,0,0-4.444,4.444A4.3,4.3,0,0,0,9.778,32h7.111a4.3,4.3,0,0,0,.889-2.578A4.4,4.4,0,0,0,13.333,24.978Z"
                    />
                    <path
                        id="Shape-17"
                        className="cls-17"
                        fill={"#fff"}
                        d="M21.333,28.444H10.667a7.132,7.132,0,0,1-7.111-7.111V10.667a7.132,7.132,0,0,1,7.111-7.111H21.333a7.132,7.132,0,0,1,7.111,7.111V21.333A7.132,7.132,0,0,1,21.333,28.444ZM10.667,5.333a5.349,5.349,0,0,0-5.333,5.333V21.333a5.349,5.349,0,0,0,5.333,5.333H21.333a5.349,5.349,0,0,0,5.333-5.333V10.667a5.349,5.349,0,0,0-5.333-5.333Z"
                    />
                    <path
                        id="Shape-18"
                        className="cls-17"
                        fill={"#fff"}
                        d="M16,22.222A6.222,6.222,0,1,1,22.222,16,6.184,6.184,0,0,1,16,22.222Zm0-10.667A4.444,4.444,0,1,0,20.444,16,4.4,4.4,0,0,0,16,11.556Z"
                    />
                    <path
                        id="Shape-19"
                        className="cls-17"
                        fill={"#fff"}
                        d="M23.111,8.889a.889.889,0,1,1-1.778,0,.889.889,0,1,1,1.778,0Z"
                    />
                </g>
            </g>
        </g>
    </svg>
);
