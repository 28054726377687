import React from "react";

export const IconFounded: React.FC = () => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 2H13.3334V0.666626C13.3334 0.298706 13.0347 0 12.6666 0H12C11.632 0 11.3334 0.298706 11.3334 0.666626V2H4.66663V0.666626C4.66663 0.298706 4.36804 0 4 0H3.33337C2.96533 0 2.66663 0.298706 2.66663 0.666626V2H2C0.897339 2 0 2.89734 0 4V14C0 15.1027 0.897339 16 2 16H14C15.1027 16 16 15.1027 16 14V4C16 2.89734 15.1027 2 14 2ZM14.6666 14C14.6666 14.3673 14.3673 14.6666 14 14.6666H2C1.63269 14.6666 1.33337 14.3673 1.33337 14V6.69336H14.6666V14Z"
      fill="#A4ACB5"/>
  </svg>;

};
