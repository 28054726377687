import React from "react";

interface IProps {
    width: string;
    height: string;
}

/* tslint:disable:max-line-length */
export const IconFacebook = ({width, height}: IProps) => (
    <svg id="icons8-facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={width} height={height}>
        <g id="Footer" transform="translate(-472 -216)">
            <g id="icons8-facebook-2" transform="translate(472 216)">
                <path
                    id="Shape"
                    fill={"#3f51b5"}
                    d="M32,27.556A4.444,4.444,0,0,1,27.556,32H4.444A4.444,4.444,0,0,1,0,27.556V4.444A4.444,4.444,0,0,1,4.444,0H27.556A4.444,4.444,0,0,1,32,4.444Z"
                />
                <path
                    id="Shape-2"
                    fill={"#fff"}
                    d="M25.216,16.889H22.222V28.444H17.778V16.889H15.111V13.333h2.667V11.191c0-3.118,1.3-4.969,4.971-4.969h3.029V9.778H23.745c-1.43,0-1.523.533-1.523,1.532v2.024h3.556Z"
                />
            </g>
        </g>
    </svg>
);

export const IconFacebookCircle = ({width, height}: IProps) => (
    <svg
        id="Oval_5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 34 34"
        width={width}
        height={height}
        style={{borderRadius: "50%"}}>
        <g id="Новость">
            <image
                width="34"
                height="34"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABGdBTUEAALGOfPtRkwAAAAlwSFlzAAAD2AAAA9gBbkdjNQAABOVJREFUeAHtmzmIFEEYhb0zFVHBREETETQzWDQzEUUw8kBENzE3FwMDMyNBTARX0GRFUEzURAw2Eg9MjUS8QUHEA7z+kmUZpntma6rr4T9VX0PhTHXVm7/e+6ZmeqdduOPA5T8LOKp1YFG1K2fh/xwAgMpBAAAAqNyBypfPDgAAlTtQ+fLZAQCgcgcqXz47AABU7kDly2cHAIDKHah8+ewAAFC5A5Uvnx0AACp3oPLlswMAQOUOVL78JZWvX7H8HyZ639pDa6+tvZpt7+zfX9a6HGtt8rMuAv1zAaDfkbTnn23ardl2d2Z68kuazPBZOw9ODR+QcBYAEkzrmRLe7ResnbXQP/b0j81DAEiLKtxJfdXaaQv+RZqEj1kAMHoOX23KMQv+xuhT/c0AgNEyeWnD91v4T0ab5nc0AMRn89iG7rXww7f5Yg7+EBQXZbiU21da+GHpADA/AN9sSNj238w/dPxGAMD8mU1a+I/mHzaeIwBgeG7XLfzp4UPG+ywADM7vp506Nfh0GWcAYHCOl+zd/3zw6TLOAEB7jt+t+0z7qbJ6AaA9z3v27n/bfqqsXgBozzP8slfFAQDNmH9b1+1md5k9ANDMdca2/w/N7jJ7+C2gmWv4m7/ssJs61pn4LmvbrW21tsxa7LE0dmDsOABoOhVu45IcFv4JEz5nbYXkBRJEAaBpWvjhJ/th4V8z0SPZhTsK8h2gaWB2ACz8ox7DD0sHgCYA75td6T0W/kqbfT5dQTsTAJr+hsvAnMeEia3KKZhTCwByutmuFb7tuz0AQB/NFv1LpL8CAKR7FzvT9ZUWAMTGWOg4ACg02NhlAUCsU4WOA4BCg41dFgDEOlXoOAAoNNjYZQFArFOFjgOAQoONXRYAxDpV6DgAKDTY2GUBQKxThY4DgEKDjV2W6x8qYhfRMy7cz3+z53nKw9z/DfyiFXEnpZCWObut73BLf3JXaQA8tVu6p5LdEEy0eh6YbGidD7u7aFtnkT4BPgL6DHH+dFPu+gAgt6NaPQDQ+utefWPuCtkBcjsq0rPP/zUmvTy3PADkdlSnl337D6UCgC6w3MrZt38AyB2RVo8dQOuve3UAcB+RtkAA0PrrXp3vAO4jEhVol4DhT/brFfJcBShcza+5wSQlv9sAQP6wFIqS7T8UCgCKuPJrSr4AAkD+oFSKAKBydkx0+QgYk6BUZbIDqJwdE10ZAJJLi/9o6oRdM5/s+PpX7DauTx015qZbPXvsyea5jtEfLLYpq0efFjejNADCTZOhdTnCDZzZADCt49YOdSlIOXeRUhxt/w4AgP+MpBUCgNRe/+IA4D8jaYUAILXXvzgA+M9IWiEASO31Lw4A/jOSVggAUnv9iwOA/4ykFQKA1F7/4gDgPyNphQAgtde/OAD4z0haIQBI7fUvDgD+M5JWCABSe/2LA4D/jKQVAoDUXv/iAOA/I2mFACC11784APjPSFohAEjt9S8OAP4zklYIAFJ7/YsDgP+MpBUCgNRe/+IA4D8jaYUAILXXvzgA+M9IWiEASO31Lw4A/jOSVggAUnv9iwOA/4ykFQKA1F7/4gDgPyNphQAgtde/OAD4z0haIQBI7fUvDgD+M5JWCABSe/2LA4D/jKQVAoDUXv/iAOA/I2mFACC11784APjPSFohAEjt9S8OAP4zklb4F4dkb8M08AKrAAAAAElFTkSuQmCC"
            />
        </g>
    </svg>
);
