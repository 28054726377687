import React from "react";
import {INews} from "../../api/dto/News.g";
import {IPageResult} from "../../api/dto/PageResult.g";
import {styled} from "../../core/styledComponents";
import {SimilarNewsItem} from "./similarNewsItems";

interface IProps {
    data: IPageResult<INews>;
}

export class SimilarNews extends React.Component<IProps> {
    public render(): JSX.Element {
        const items = this.props.data.items.map((item) => (
            <SimilarNewsItem key={item.id} index={Number(item.id)} data={item} />
        ));
        return (
            <Container>
                <Title>Также в рубрике:</Title>
                <NewsContainer>{items}</NewsContainer>
            </Container>
        );
    }
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: ${({theme}) => theme.mainContentWidth}px) {
        align-items: center;
    }
    @media (max-width: ${({theme}) => theme.mobileWidth}px) {
        display: none;
    }
`;

const NewsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    @media (max-width: ${({theme}) => theme.mainContentWidth}px) {
        flex-direction: row;
        justify-content: space-around;
    }
    @media (max-width: ${({theme}) => theme.mobileWidth}px) {
        flex-direction: column;
    }
`;
const Title = styled.div`
    font-size: 20px;
    margin-bottom: 16px;
`;
