import React from "react";
import {EnumCalendarDayTypes} from "../../../common/enums/calendarDayTypes";
import {CalendarCell} from "./calendarCell";
import {CalendarRow} from "./calendarRow";

export const DaysOfWeek: React.FC = () => {
  const daysOfWeek = React.useMemo(() => {
    return ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"].map((day) => (
      <CalendarCell
        key={day}
        value={day}
        dayType={day === "СБ" || day === "ВС" ? EnumCalendarDayTypes.Weekend : EnumCalendarDayTypes.DayOfWeek}
      >
        {day}
      </CalendarCell>
    ));
  }, []);

  return <CalendarRow>{daysOfWeek}</CalendarRow>;

};
