import React from "react";
import {css, styled} from "../../../core/styledComponents";
import {IconFacebook} from "../../../shared/icons/IconFacebook";
import {IconInstagram} from "../../../shared/icons/IconInstagram";
import {IconOdnoclasniki} from "../../../shared/icons/IconOdnoklasniki";
import {IconVK} from "../../../shared/icons/IconVK";

interface IProps {
  noTitle?: boolean;
  noHideOnMobile?: boolean;
}

export const SocialNetwork: React.FC<IProps> = (props) => {
  return (
    <Container noHideOnMobile={props.noHideOnMobile}>
      {!props.noTitle && <Title>Мы в социальных сетях:</Title>}
      <div style={{display: "flex"}}>
        <Item target={"_blank"} href={"https://www.facebook.com/groups/pmr.business/"} noTitle={props.noTitle}>
          <IconFacebook height={"32px"} width={"32px"}/>
        </Item>
        <Item target={"_blank"} href={"https://ok.ru/group/58352620732526"} noTitle={props.noTitle}>
          <IconOdnoclasniki height={"32px"} width={"32px"}/>
        </Item>
        <Item target={"_blank"} href={"https://vk.com/pmr_business"} noTitle={props.noTitle}>
          <IconVK width={"32px"} height={"32px"}/>
        </Item>
        <Item target={"_blank"} href={"https://www.instagram.com/pmr_business/"} noTitle={props.noTitle}>
          <IconInstagram width={"32px"} height={"32px"}/>
        </Item>
      </div>
    </Container>
  );
};

const Container = styled.div<{noHideOnMobile?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: flex-start;
  margin-right: 23px;
  @media (max-width: 420px) {
    ${(props) => props.noHideOnMobile !== true && css`display: none;`};
  }
  @media (max-width: 991px) {
    align-self: center;
    align-items: center;
    padding-bottom: 20px;
    margin-right: 0;
  }
`;

const Title = styled.div`
  margin-right: 5px;
  font-family: SFUITextMedium, sans-serif;
  font-size: 20px;
  margin-bottom: 8px;
  color: #4a4a4a;
`;

export const Item = styled.a<{noTitle?: boolean}>`
  margin-right: 15px;
  cursor: pointer;
  img {
    height: ${(props) => (props.noTitle ? 36 : 32)}px;
    width: auto;
  }
`;
