import React from "react";
import {MetaTags} from "react-meta-tags";
import {styled} from "../core/styledComponents";
import imageToSocialNetwork from "../shared/img/main.png";
import backgroundImage from "../shared/img/main@3x.png";
import {Title as BaseTitle} from "../ui/title";
import {Search} from "./componentAllPage/search/search";
import {BlockNews} from "./screenHomeComponent/blockNews/blockNews";
import {Calendar} from "./screenHomeComponent/calendar/calendar";
import {Categories} from "./screenHomeComponent/categories/categories";
import {CurrencyConverter} from "./screenHomeComponent/currencyConverter/CurrencyConverter";
import {ExchangeRates} from "./screenHomeComponent/exchangeRates/ExchangeRates";
import {ExchangesContainer} from "./screenHomeComponent/exchangesContainer";
import {IconScrol} from "./screenHomeComponent/iconScrol";
import {NewsContainer} from "./screenHomeComponent/newsContainer";

export const ScreenHome: React.FC = () => {
  return <div>
    <MetaTags>
      <title>Бизнес-портал Приднестровья</title>
      <meta
        name="description"
        content={`Новости ПМР, Новости Приднестровья, Предприятия ПМР, Предприятия Приднестровья`}
      />
      <meta name="keywords" content={`ПМР, Приднестровье, Бизнес ПМР, Бизнес Приднестровья`}/>
      {/*Метя теги для facebook и instagram*/}
      <meta
        property="og:title"
        content={`Новости ПМР, Новости Приднестровья, Предприятия ПМР, Предприятия Приднестровья`}
      />
      <meta property="og:image" content={imageToSocialNetwork}/>
      <meta property="og:description" content={`ПМР, Приднестровье, Бизнес ПМР, Бизнес Приднестровья`}/>

    </MetaTags>
    <SearchContainer className={"container"}>
      <Title>Бизнес-портал Приднестровья</Title>
      <SearchInput className={"col-lg-9"}>
        <Search hideClassContainer={true}/>
      </SearchInput>
      <IconIcMouseContainer>
        <IconScrol/>
      </IconIcMouseContainer>
    </SearchContainer>

    <Categories/>

    <NewsContainer className={"container"} style={{padding: 0}}>
      <BlockNews/>
      <ExchangesContainer className="col-md-4 col-sm-6">
        <ExchangeRates/>
        <CurrencyConverter/>
        <Calendar/>
      </ExchangesContainer>
    </NewsContainer>
  </div>;
};

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  position: relative;
  height: calc(100vh - 77px);
  background: url(${backgroundImage}) no-repeat right 105%;
  background-size: 1250px 800px;
  
  @media (min-width: 421px) {
      min-height: 600px;
  }
  @media (max-width: 420px) {
    flex-direction: column-reverse;
    height: auto;
    margin-top: 8px;
  }
`;

const IconIcMouseContainer = styled.div`
    align-self: center;

    position: absolute;
    bottom: 145px;
    z-index: 1;
    @media (max-width: 767px) {
        display: none;
    }
`;

const Title = styled(BaseTitle)`
    font-size: 50px;
    @media (max-width: 420px) {
        display: none;
    }
`;
const SearchInput = styled.div`
    z-index: 2;
    padding: 0;
    @media (max-width: 420px) {
      z-index: 3;
      margin-top: -10px;
    }
`;
