import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {createSelector} from "reselect";
import styled from "styled-components";
import {IDivision} from "../../../api/dto/Division.g";
import {IAppDispatch, IAppState} from "../../../core/store/mainReducer";
import {GetRubricator} from "../../../modules/rubricator/rubricatorActionAsync";
import {DropdownColumn} from "./dropdownColumn";

const subCategoryDispatch = (dispatch: IAppDispatch) => ({
  getCategory: (id: string) => dispatch(GetRubricator.SubCategory(id)),
  allCategory: () => dispatch(GetRubricator.AllCategory())
});

const subCategoryState = createSelector(
  (state: IAppState) => state.rubricator.data,
  (searchResult) => {
    return {
      searchResult
    };
  }
);

interface IProps extends ReturnType<typeof subCategoryDispatch>, ReturnType<typeof subCategoryState> {
  data: string;
}

interface IState {
  current1?: string;
  current2?: string;
  current3?: string;
  current4?: string;
  offset1?: number;
  offset2?: number;
  offset3?: number;
  data1: IDivision[];
  data2: IDivision[];
  data3: IDivision[];
  data4: IDivision[];
}

class DropdownMenu extends React.Component<IProps & RouteComponentProps, IState> {
  constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.state = {
      current1: undefined,
      current2: undefined,
      current3: undefined,
      current4: undefined,
      offset1: 0,
      offset2: 0,
      offset3: 0,
      data1: [],
      data2: [],
      data3: [],
      data4: []
    };
  }

  public async componentDidMount() {
    if (this.props.data === "0") {
      await this.props.allCategory();
      await this.setState({data1: this.props.searchResult});
    } else {
      await this.props.getCategory(this.props.data);
      await this.setState({data1: this.props.searchResult});
    }
  }

  public render(): JSX.Element {
    return (
      <Container className="container" style={{paddingBottom: 20}}>
        <div className="row">
          {[1, 2, 3, 4].map((level) => (
            <DropdownColumn
              key={level}
              data={this.getData(level)}
              onClick={this.onClick(level)}
              current={this.state[`current${level - 1}`]}
              offset={this.getOffset(level)}
              title={this.getColumnTitle(level)}
            />
          ))}
        </div>
      </Container>
    );
  }

  private onClick = (level: number) => async (key: string, offset: number) => {
    const same = this.state[`current${level}`] === key;
    const levels = [1, 2, 3, 4].reduce(
      (acc, item) => ((same ? item >= level : item > level) ? {...acc, [`current${item}`]: undefined} : acc),
      {}
    );
    this.setState({[`current${level}`]: key, ...levels, [`offset${level}`]: offset});

    await this.props.getCategory(key);

    const dataName = `data${level + 1}` as "data2";
    await this.setState({[dataName]: this.props.searchResult});
  };

  private getData = (level: number) => {
    const {data1, data2, data3, data4} = this.state;
    const {current1, current2, current3} = this.state;
    switch (level) {
      case 1:
        return data1;
      case 2:
        return current1 && data2 ? data2 : null;
      case 3:
        return current1 && current2 && data2 && data3 ? data3 : null;
      case 4:
        return current1 && current2 && current3 && data2 && data3 && data4 ? data4 : null;
      default:
        return null;
    }
  };

  private getOffset = (level: number) => {
    switch (level) {
      case 1:
        return 0;
      case 2:
        return this.state[`offset1`] || 0;
      case 3:
        return (this.state[`offset1`] || 0) + (this.state[`offset2`] || 0);
      case 4:
        return (this.state[`offset1`] || 0) + (this.state[`offset2`] || 0) + (this.state[`offset3`] || 0);
      default:
        return 0;
    }
  };

  private getColumnTitle = (level: number) => {
    const {data1, data2, data3} = this.state;
    const {current1, current2, current3} = this.state;
    switch (level) {
      case 2:
        return current1 ? data1.filter((value) => value.id === current1)[0].name : null;
      case 3:
        return current1 && current2 ? data2.filter((value) => value.id === current2)[0].name : null;
      case 4:
        return current1 && current2 && current3 ? data3.filter((value) => value.id === current3)[0].name : null;
      default:
        return null;
    }
  };
}

export const DropdownDesctop = connect(
  subCategoryState,
  subCategoryDispatch
)(withRouter(DropdownMenu));

const Container = styled.div`
    @media (max-width: 421px) {
        display: none;
    }
`;
