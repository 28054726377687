import React from "react";
import {styled} from "../../../core/styledComponents";

export const BlackListLink: React.FC = () => {
  return <Title href={"/blackList"}>Черный список</Title>;
};

const Title = styled.a`
    margin-right: 5px;
    font-family: SFUITextMedium, sans-serif;
    font-size: 18px;
    margin-bottom: 28px;
    margin-top: 20px;
    color: #0f77ff;
    cursor: pointer;
    :hover {
        text-decoration: none;
    }

    @media (max-width: 1199px) {
        text-align: center;
        margin-bottom: 8px;
        margin-top: 8px;
    }
`;
