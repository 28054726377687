import React from "react";
import {IFirm} from "../../api/dto/Firm.g";
import {UrlBackend} from "../../common/constants";
import {
  CompanyInfoContainer,
  CompanyInfoImage,
  CompanyInfoTextContainer,
  CompanyInfoTitle,
  CompanyInfoWebsite
} from "./styled";

interface IProps {
  data: IFirm;
}

export class CompanyInfo extends React.PureComponent<IProps> {
  public render() {
    const photoPath = this.props.data && this.props.data.banner ? this.props.data.banner : "";

    return this.props.data && this.props.data.photos
      ? <CompanyInfoContainer>
        {photoPath && <CompanyInfoImage>
            <img src={UrlBackend + "/" + photoPath} alt=""/>
        </CompanyInfoImage>}
        <CompanyInfoTextContainer>
          <CompanyInfoTitle>{this.props.data.name}</CompanyInfoTitle>
          <CompanyInfoWebsite>{this.props.data.site}</CompanyInfoWebsite>
        </CompanyInfoTextContainer>
      </CompanyInfoContainer>
      : null;
  }
}
