import * as moment from "moment";
import queryString from "query-string";
import React from "react";
import {useHistory} from "react-router";
import {INews} from "../../api/dto/News.g";
import {UrlBackend} from "../../common/constants";
import {createUrlPageNews, IUrlPageNews} from "../../common/createURL/urlPageNews";
import {createUrlPageNewsItem, IUrlPageNewsItems} from "../../common/createURL/urlPageNewsItems";
import {styled} from "../../core/styledComponents";
import * as defaultImage from "../../shared/img/defoltImage.png";
import {Span} from "../../ui/span";

interface IProps {
  data: INews;
  hideSchema?: boolean
}

export const NewsItem: React.FC<IProps> = (props) => {
  const history = useHistory();
  const [lengthDescription] = React.useState(!!props.data.imagePath ? 80 : 450);
  const [description] = React.useState(props.data.description.replace(/(<([^>]+)>)/gi, ""));

  const linkNews = (): string => {
    const attributes: IUrlPageNewsItems = {id: props.data.id};
    return "/news/item" + createUrlPageNewsItem(attributes);
  };

  const searchTag = () => {
    const attributes: IUrlPageNews = queryString.parse(history.location.search);
    attributes.category = props.data.category.id;
    attributes.page = 1;
    history.push("/news" + createUrlPageNews(attributes));
  };

  const img = React.useMemo(() => {
    return props.data.imagePath ? (
      <ImageContainer>
        <Image src={`${UrlBackend}/${props.data.imagePath}`} alt=""/>
      </ImageContainer>
    ) : (
      <div style={{height: 24}}/>
    );
  }, [props.data.imagePath]);

  return (
    <>
      <ContainerDesctop>
        <HeaderContainer>
          <Place>{props.data.city ? props.data.city.title : ""}</Place>
          <TagContainer>
            <Tag onClick={searchTag}>{props.data.category.name}</Tag>
          </TagContainer>
        </HeaderContainer>
        <ItemLink
          href={linkNews()}
          itemScope={!props.hideSchema}
          itemType={props.hideSchema ? undefined : "https://schema.org/NewsArticle"}
        >
          {!props.hideSchema && <meta itemProp="identifier" content={props.data.title}/>}
          {img}
          <FooterContainer>
            <Header itemProp={props.hideSchema ? undefined : "headline"}>
              {props.data.title.length > 70 ? props.data.title.substr(0, 70) + "..." : props.data.title}
            </Header>
            <div style={{display: "none"}}>
              <div itemProp={props.hideSchema ? undefined : "about"}>{props.data.category.name}</div>
              <div itemProp={props.hideSchema ? undefined : "author"}>Бизнес-портал Приднестровья</div>
              <link rel={props.hideSchema ? undefined : "canonical"} href={linkNews()}/>
            </div>
            <Description
              itemProp={props.hideSchema ? undefined : "articleBody"}
              dangerouslySetInnerHTML={{
                __html:
                  description.length > lengthDescription
                    ? description.substr(0, lengthDescription) + "..."
                    : description
              }}
            />
            <Date
              itemProp={props.hideSchema ? undefined : "datePublished"}
              dateTime={moment(props.data.createdUtc).format("DD MMMM YYYY")}
            >
              {moment(props.data.createdUtc).format("DD MMMM YYYY")}
            </Date>
          </FooterContainer>
        </ItemLink>
      </ContainerDesctop>

      <ContainerMobile>
        <ItemLink href={linkNews()}>
          {props.data.imagePath
            ? <ImageContainerMobile>
              <ImageMobile src={`${UrlBackend}/${props.data.imagePath}`} alt=""/>
            </ImageContainerMobile>
            : <ImageContainerMobile>
              <ImageMobile src={defaultImage}/>
            </ImageContainerMobile>
          }
          <FooterContainer>
            <Header>{props.data.title.length > 70 ? props.data.title.substr(0, 70) + "..." : props.data.title}</Header>
            <Place>город: {props.data.city ? props.data.city.title : ""}</Place>
          </FooterContainer>
        </ItemLink>
      </ContainerMobile>
    </>
  );
};

const ItemLink = styled.a`
cursor: pointer;
  text-decoration: none;
  :hover,:active{
    text-decoration: none;
  }
  @media (max-width: 420px) {
    display: flex;
  }
`;

const ContainerDesctop = styled.div`
    box-sizing: border-box;
    display: inline-block;
    flex-direction: column;
    padding-top: 21px;
    padding-bottom: 10px;
    border-radius: 4px;
    max-width: 350px;
    word-break: break-all;
    width: 100%;
    @media (max-width: 770px) {
        max-width: ${({theme}) => theme.columnMaxWidth}px;
    }
    background-color: ${(props) => props.theme.colors.white};
    margin: 5px 0;
    @media (max-width: 420px) {
        display: none;
    }
`;

const ContainerMobile = styled.div`
    box-sizing: border-box;
    display: none;
    padding-top: 21px;
    padding-bottom: 21px;
    border-radius: 4px;
    max-width: 350px;
    word-break: break-all;
    background-color: ${(props) => props.theme.colors.white};
    margin: 5px 0;
    @media (max-width: 420px) {
        display: flex;
        flex-direction: row;
        margin: 5px 0;
        width: 100%;
        max-width: none;
        padding: 8px;
    }
`;

const HeaderContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
`;

const FooterContainer = styled.div`
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    @media (max-width: 420px) {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

const Place = styled(Span)`
    word-break: break-word;
    font-size: 20px;
    color: ${(props) => props.theme.colors.mainGrey};
    @media (max-width: 420px) {
        font-size: 12px;
    }
`;

const TagContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    border-radius: 20px;
    cursor: pointer;
    border: solid 1px ${(props) => props.theme.colors.blueAccent};
`;

const Tag = styled(Span)`
    white-space: nowrap;
    font-size: 12px;
    color: ${(props) => props.theme.colors.blueAccent};
`;

const Header = styled(Span)`
    font-family: FiraSansMedium, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.slate};
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-word;
    @media (max-width: 420px) {
        font-size: 16px;
    }
`;

const Description = styled(Span)`
    font-family: RobotoRegular, sans-serif;
    display: inline-block;
    color: #9b9b9b;
    line-height: 1.3em;
    font-size: 16px;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 8px 0;
    width: 100%;
    word-break: break-word;
`;

const Date = styled.time`
    font-size: 12px;
    color: #3f4d5c;
    font-family: SFUIText, sans-serif;
`;

const ImageContainer = styled.div`
    overflow: hidden;
    height: 190px;
    margin-top: 22px;
    margin-bottom: 8px;
`;

const Image = styled.img`
    width: 100%;
    height: auto;
`;

const ImageMobile = styled.img`
    @media (min-width: 992px) {
        display: none;
    }
    width: 100%;
    height: 100%;
`;
const ImageContainerMobile = styled.div`
    display: flex;
    justify-content: center;
    flex: none;
    height: 75px;
    width: 102px;
    margin-right: 16px;

    @media (min-width: 992px) {
        display: none;
    }
`;
