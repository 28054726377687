export enum EnumCalendarDayTypes {
  DayOfWeek,
  Weekend,
  Standard,
  Inactive,
  DayOff,
  Holiday,
  HoliHoliday,
  ProfessionalHoliday,
  Selected,
  Interval
}
