import moment from "moment";
import React from "react";
import {styled} from "../core/styledComponents";
import {IconArrowWhite} from "../shared/icons/IconArrowWhite";
import {Span} from "./span";

interface IProps {
  onClickLeft?: () => void;
  onClickRight?: () => void;
  month?: moment.Moment;
  title?: string;
}

export const HeaderWithScroll: React.FC<IProps> = (props) => {

  const {month, title: t, onClickLeft, onClickRight} = props;

  let title = "";
  let leftTitle = "";
  let rightTitle = "";
  if (month != null) {
    title = month.clone().format("MMMM");
    rightTitle = `′${month.clone().format("YYYY")}`;
    leftTitle = `′${month.clone().format("MM")}`;
  } else if (t != null) {
    title = t;
  }

  return (
    <Container>
      <IconContainerReverse onClick={onClickLeft}>
        <IconArrowWhite/>
      </IconContainerReverse>
      <div style={{display: "flex", justifyContent: "space-between", width: " 100%"}}>
        <Title>{leftTitle}</Title>
        <Title>{title}</Title>
        <Title>{rightTitle}</Title>
      </div>
      <IconContainer onClick={onClickRight}>
        <IconArrowWhite/>
      </IconContainer>
    </Container>
  );

};

const Container = styled.div`
    display: flex;
    flex: 1;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    max-height: 60px;
    border-radius: 4px 4px 0 0;
    background-color: ${(props) => props.theme.colors.blueAccent};
`;

const Title = styled(Span)`
    font-size: 18px;
    color: ${(props) => props.theme.colors.white};
    text-transform: capitalize;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
`;

const IconContainerReverse = styled(IconContainer)`
    transform: rotate(180deg);
`;

