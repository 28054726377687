import {styled} from "../core/styledComponents";

export const ButtonGroup = styled.div`
    display: flex;
    a:nth-child(2) {
        margin-left: 24px;
    }
    button:nth-child(2) {
        margin-left: 24px;
    }
    @media screen and (max-width: 768px) {
        justify-content: space-around;
    }
    @media screen and (max-width: 420px) {
        flex-direction: column;
        align-items: center;
        a:nth-child(2) {
            margin-left: 0;
        }
        button:nth-child(2) {
            margin-left: 0;
        }
    }
`;
