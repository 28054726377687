import {reducerWithInitialState} from "typescript-fsa-reducers";
import {ICategory} from "../../../api/dto/Category.g";
import {initialCategories, updateCategory, updatePage} from "./categoryAction";

interface IReqest {
    activePage: number;
    category: string | null;
    allCategories: ICategory[];
}

const newsSearchReqestState: IReqest = {
    activePage: 1,
    category: null,
    allCategories: []
};

export const newsSearchCategoryReduser = reducerWithInitialState(newsSearchReqestState)
    .case(updatePage, (state, payload) => ({
        activePage: payload,
        category: state.category,
        allCategories: state.allCategories
    }))
    .case(updateCategory, (state, payload) => ({
        activePage: state.activePage,
        category: payload,
        allCategories: state.allCategories
    }))

    .case(initialCategories.started, (state) => ({
        activePage: state.activePage,
        category: state.category,
        allCategories: state.allCategories
    }))
    .case(initialCategories.done, (state, payload) => ({
        activePage: state.activePage,
        category: state.category,
        allCategories: payload.result
    }))
    .case(initialCategories.failed, (state) => ({
        activePage: state.activePage,
        category: state.category,
        allCategories: state.allCategories
    }));
