import queryString from "query-string";
import React, {SyntheticEvent} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {ICity} from "../../../api/dto/City.g";
import {createUrlSearchDirectory} from "../../../common/createURL/urlPageDirectory";
import {createUrlPageNews, IUrlPageNews} from "../../../common/createURL/urlPageNews";
import {replaceSymbolSearch} from "../../../common/replaceSymbolSearch";
import {IAppState} from "../../../core/store/mainReducer";
import {styled} from "../../../core/styledComponents";
import {searchCity, searchText, suggestions} from "../../../modules/search/searchAction";
import {AllSearch} from "../../../modules/search/searchActionAsync";
import {SelectSearch} from "../../../ui/selectSearch";
import {ButtonSearch} from "./componentsSearch/buttonSearch";
import {SearchIcon} from "./componentsSearch/searchIcon";

const placeholderList = [
  "пластиковые окна",
  "аптека Каменка",
  "поликлиника Бендеры",
  "зоомагазин",
  "детский сад Красногорка",
  "купить квартиру Тирасполь",
  "центр занятости Григориополь",
  "министерство экономразвития",
  "перевозка груза",
  "госадминистрация Грушки",
  "русская община Бендер",
  "ремонт автомобиля",
  "туристическое агентство",
  "купить цветы Тирасполь"
];

interface IProps {
  placeholder?: string;
  hideClassContainer?: boolean;
}

export const Search: React.FC<IProps> = (props) => {
  const history = useHistory();
  const options = useSelector((state: IAppState) => state.search.city);
  const attributes: IUrlPageNews = React.useMemo(() => (queryString.parse(window.location.search)), [window.location.search]);
  const [placeholder, setPlaceholder] = React.useState(props.placeholder);
  const [text, setText] = React.useState("");
  const [city, setCity] = React.useState("Всё Приднестровье");
  const suggestionsText = useSelector((state: IAppState) => state.search.suggestionsText);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(suggestions.done({params: undefined, result: ""}));
    dispatch(searchText(attributes.text || ""));
    setText(attributes.text || "");
    setCity(attributes.city || "Всё Приднестровье");
    if (!placeholder) {
      setPlaceholder(randomPlaceholder());
    }
  }, [attributes]);

  const randomPlaceholder = (): string => {
    const rand = Math.floor(Math.random() * placeholderList.length);
    return "Например: " + placeholderList[rand];
  };

  const onSelect = (value: ICity) => {
    setCity(value.title);
    dispatch(searchCity(value.title));
  };

  const onButtonClick = (value: string) => {
    dispatch(searchText(value));

    attributes.page = 1;
    attributes.city = city;
    attributes.text = replaceSymbolSearch(value);
    props.placeholder === "Поиск по новостям"
      ? history.push("/news" + createUrlPageNews(attributes))
      : history.push("/search" + createUrlSearchDirectory(attributes));
  };

  const inputText = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    setText(event.currentTarget.value);
    dispatch(suggestions.done({params: undefined, result: ""}));
    if (event.currentTarget.value.length > 2) {
      dispatch(AllSearch.GetSuggestions(event.currentTarget.value));
    } else if (event.currentTarget.value.length === 0) {
      dispatch(suggestions.done({params: undefined, result: ""}));
    }
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      onButtonClick(text);
    }
  };

  const onClickSuggestionsText = () => {
    setText(suggestionsText);
    onButtonClick(suggestionsText);
    dispatch(suggestions.done({params: undefined, result: ""}));
  };

  return <Form className={props.hideClassContainer ? "" : "container"}>

    {props.placeholder !== "Поиск по новостям" && <SearchDescription className={"col-lg-12"}>Поиск предприятий, товаров, услуг</SearchDescription>}
    <Container>
      <div style={{marginLeft: 9, marginRight: 19}}>
        <SearchIcon/>
      </div>
      <StyledInput
        id={"search-input"}
        placeholder={placeholder}
        value={text}
        onChange={inputText}
        inputMode={window.navigator.platform === "iPhone" ? "search" : undefined}
        type={"search"}
        onKeyPress={onKeyPress}
        autoComplete={"off"}
      />
      <VerticalLine/>
      <SelectSearch options={options} onSelect={onSelect} defaultValue={city}/>
      {/*tslint:disable-next-line*/}
      <ButtonSearch onClick={() => onButtonClick(text)} children={"Найти"}/>
      {
        suggestionsText
        && suggestionsText !== text
        && suggestionsText + " " !== text
        && <SuggestionsTextContainer onClick={onClickSuggestionsText}>
          {suggestionsText}
        </SuggestionsTextContainer>
      }
    </Container>
  </Form>;
};

const Container = styled.div`
position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 72px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 8px 16px 0 rgba(18, 29, 138, 0.1);
  @media (max-width: 600px) {
    height: 38px;
    margin: 30px 0;
    width: 100%;
  }
  @media (max-width: 600px) {
    margin: 0;
  }
`;

const StyledInput = styled.input`
  height: 100%;
  border: none;
  outline: none;
  padding: 0;
  font-family: SFUIText, sans-serif;
  font-size: 18px;
  flex: 1;
  border-radius: 8px;
  @media (max-width: 420px) {
    font-size: 16px;
  }
`;

const VerticalLine = styled.div`
  height: 64px;
  width: 2px;
  background-color: ${(props) => props.theme.colors.paleGrey};
  @media (max-width: 700px) {
    display: none;
  }
`;

const Form = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  margin-bottom: 64px;
  @media (max-width: 420px) {
    margin: 15px 0;
  }
`;

const SuggestionsTextContainer = styled.div`
  position: absolute;
  padding: 10px 64px;
  font-family: SFUIText, sans-serif;
  font-size: 18px;
  cursor: pointer;
  background: #ffffff;
  margin-top: 6px;
  border-radius: 8px;
  bottom: -45px;
  
  :hover{
    background: #0f77ff;
    color: #ffffff;
  }
`;


const SearchDescription = styled.div`
    font-size: 20px;
    font-family: SFUITextSemibold, sans-serif;
    color: #3e4c5b;
    z-index: 3;
    padding: 0;
    margin-bottom: 30px;
    @media (max-width: 420px) {
        display: none;
    }
`;
