import React from "react";

export const IconLoading: React.FC = () => {
  return <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32px" height="32px"
              viewBox="0 0 512.001 512.001">
    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0.6245" y1="257.8236" x2="511.6481" y2="257.8236"
                    gradientTransform="matrix(0.9996 0 0 -0.9996 -0.0218 513.7093)">
      <stop offset="0" style={{stopColor: "#00F2FE"}}/>
      <stop offset="0.021" style={{stopColor: "#03EFFE"}}/>
      <stop offset="0.293" style={{stopColor: "#24D2FE"}}/>
      <stop offset="0.554" style={{stopColor: "#3CBDFE"}}/>
      <stop offset="0.796" style={{stopColor: "#4AB0FE"}}/>
      <stop offset="1" style={{stopColor: "#4FACFE"}}/>
    </linearGradient>
    <path style={{fill: "url(#SVGID_1_)"}} d="M407.497,103.365c-40.873-40.873-95.217-63.383-153.02-63.383s-112.147,22.51-153.02,63.383
	l-9.585,9.585h65.66c11.041,0,19.991,8.95,19.991,19.991c0,11.041-8.95,19.991-19.991,19.991l-71.701-0.001
	c-15.86-0.191-30.735-6.515-41.886-17.807c-11.112-11.253-17.274-26.172-17.353-42.011V19.991C26.592,8.95,35.542,0,46.583,0
	s19.991,8.95,19.991,19.991v61.714l6.611-6.611C121.61,26.669,185.994,0,254.477,0S387.345,26.669,435.77,75.094
	c31.333,31.333,54.094,70.346,65.825,112.823c2.939,10.642-3.307,21.652-13.948,24.591c-1.78,0.492-3.572,0.727-5.334,0.727
	c-8.767,0.001-16.811-5.811-19.258-14.674C453.161,162.736,433.95,129.818,407.497,103.365z M127.759,431.233
	c-4.873-3.625-9.653-7.506-14.207-11.531c-8.273-7.314-20.907-6.533-28.217,1.738c-7.313,8.272-6.534,20.907,1.737,28.218
	c5.392,4.766,11.051,9.359,16.821,13.652c3.584,2.667,7.769,3.954,11.918,3.954c6.103,0,12.131-2.786,16.055-8.059
	C138.456,450.346,136.618,437.823,127.759,431.233z M23.096,297.764c11.002-0.923,19.173-10.589,18.251-21.591
	c-0.506-6.038-0.763-12.192-0.763-18.289c0-11.041-8.95-19.991-19.991-19.991s-19.991,8.95-19.991,19.991
	c0,7.207,0.304,14.484,0.903,21.628c0.875,10.434,9.613,18.323,19.898,18.323C21.964,297.835,22.529,297.812,23.096,297.764z
	 M59.61,346.643c-4.545-10.062-16.387-14.536-26.448-9.991c-10.062,4.544-14.535,16.386-9.991,26.447
	c2.962,6.557,6.245,13.073,9.759,19.366c3.659,6.557,10.462,10.25,17.473,10.25c3.298,0,6.642-0.818,9.726-2.539
	c9.641-5.382,13.093-17.559,7.711-27.2C64.876,357.669,62.107,352.173,59.61,346.643z M210.335,468.85
	c-5.929-1.291-11.876-2.854-17.673-4.645c-10.548-3.258-21.742,2.649-25.002,13.199c-3.26,10.549,2.65,21.742,13.198,25.002
	c6.88,2.125,13.935,3.979,20.97,5.512c1.434,0.312,2.863,0.462,4.272,0.462c9.197,0,17.477-6.388,19.513-15.743
	C227.963,481.848,221.123,471.198,210.335,468.85z M493.217,259.616c-11.012-0.995-20.72,7.102-21.72,18.098
	c-0.551,6.053-1.366,12.151-2.425,18.124c-1.926,10.871,5.326,21.246,16.197,23.173c1.18,0.209,2.353,0.31,3.511,0.31
	c9.513,0,17.944-6.815,19.661-16.507c1.254-7.079,2.221-14.305,2.874-21.476C512.316,270.342,504.212,260.617,493.217,259.616z
	 M471.646,356.782c-9.601-5.451-21.803-2.083-27.254,7.518c-2.995,5.276-6.255,10.492-9.69,15.504
	c-6.242,9.106-3.921,21.549,5.186,27.792c3.455,2.369,7.39,3.503,11.285,3.503c6.37,0,12.633-3.04,16.507-8.69
	c4.071-5.939,7.934-12.122,11.484-18.375C484.614,374.433,481.248,362.232,471.646,356.782z M384.004,432.129
	c-4.903,3.595-10.017,7.022-15.196,10.183c-9.425,5.752-12.4,18.055-6.648,27.479c3.768,6.172,10.344,9.579,17.082,9.579
	c3.55,0,7.145-0.946,10.396-2.931c6.14-3.747,12.199-7.808,18.008-12.067c8.903-6.528,10.829-19.039,4.301-27.943
	C405.418,427.528,392.91,425.599,384.004,432.129z M301.151,469.168c-5.929,1.247-11.996,2.255-18.031,2.998
	c-10.959,1.347-18.751,11.322-17.403,22.281c1.245,10.13,9.864,17.554,19.816,17.554c0.813,0,1.637-0.05,2.464-0.151
	c7.156-0.88,14.35-2.075,21.383-3.554c10.805-2.272,17.721-12.873,15.449-23.677C322.556,473.811,311.953,466.896,301.151,469.168z"
    />
  </svg>;
};

export const IconSuccess: React.FC = () => {
  return <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64px" height="64px"
              viewBox="0 0 455.111 455.111">
    <path fill="#FFFFFF" d="M351.289,162.133L203.378,324.267c-9.956,11.378-27.022,11.378-36.978,0l-62.578-69.689
  c-8.533-9.956-8.533-25.6,1.422-35.556c9.956-8.533,25.6-8.533,35.556,1.422l44.089,49.778l129.422-140.8
  c9.956-9.956,25.6-11.378,35.556-1.422C359.822,136.533,359.822,153.6,351.289,162.133z"/>
  </svg>;
};

export const IconError: React.FC = () => {
  return <svg viewBox="0 0 365.71733 365" width="64px" height="64px" xmlns="http://www.w3.org/2000/svg">
    <g fill="#FFFFFF">
      <path
        d="m356.339844 296.347656-286.613282-286.613281c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503906-12.5 32.769532 0 45.25l286.613281 286.613282c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082032c12.523438-12.480468 12.523438-32.75.019532-45.25zm0 0"/>
      <path
        d="m295.988281 9.734375-286.613281 286.613281c-12.5 12.5-12.5 32.769532 0 45.25l15.082031 15.082032c12.503907 12.5 32.769531 12.5 45.25 0l286.632813-286.59375c12.503906-12.5 12.503906-32.765626 0-45.246094l-15.082032-15.082032c-12.5-12.523437-32.765624-12.523437-45.269531-.023437zm0 0"/>
    </g>
  </svg>;
};
