import React from "react";
import {useHistory, useParams} from "react-router";
import {styled} from "../../core/styledComponents";
import {IconNavigator} from "../../shared/icons/iconNavigator";
import {Button, buttonCss} from "../../ui/Button";
import {ErrorMessage} from "./errorMessage";

interface IProps {
  city: string;
  address: string
  tapButtonOpenMap: (mapVisible: boolean) => void;
  mapVisible: boolean;
  mapCode?: string;
}

export const LocationMap: React.FC<IProps> = (props) => {
  const [formSendErrorShow, setFormSendErrorShow] = React.useState(false);
  const history = useHistory();
  const {id, translate} = useParams();
  const [link, setLink] = React.useState("");

  React.useEffect(() => {
    if (props.mapCode) {
      const div = document.createElement("div");
      div.innerHTML = props.mapCode || "";
      const script = div.children[0] as HTMLScriptElement;

      const mapScript = document.createElement("script");
      mapScript.src = script.src;
      script.async = true;

      const mapElement = document.getElementById("myMap");
      if (mapElement != null) {
        mapElement.appendChild(mapScript);
      }
    }
  }, []);

  React.useEffect(() => {
    setLink(encodeURIComponent(props.city + " " + props.address));
  }, [props.city, props.address]);

  const errorMessage = () => {
    setFormSendErrorShow(!formSendErrorShow);
  };

  const mapVisible = () => {
    props.tapButtonOpenMap(!props.mapVisible);
  };

  const onCancel = () => {
    history.goBack();
  };

  const onEdit = () => {
    history.push(`/edit/${id}/${translate}`);
  };

  return (
    <Container id="Map">
      {props.mapCode && (
        <>
          <ButtonContainer>
            <Button onClick={mapVisible}>
              {props.mapVisible ? "Скрыть карту" : "Показать на карте"}
            </Button>
            <ButtonContainer>
              <RedButton target={"_blank"} href={`https://yandex.ru/maps/?text=${link}`}>
                <IconNavigator color={"#FF0000"}/>Яндекс
              </RedButton>
              <GreenButton target={"_blank"} href={`https://www.google.com/maps/search/?api=1&query=${link}`}>
                <IconNavigator color={"#22A463"}/>Google
              </GreenButton>
            </ButtonContainer>
          </ButtonContainer>
          <Map id="openMap">
            <div id="myMap" style={{display: props.mapVisible ? "flex" : "none"}}/>
          </Map>
        </>
      )}
      <ButtonContainer>
        <Button onClick={onCancel}>Вернуться к результатам поиска</Button>
        <ButtonContainer>
          <Button onClick={errorMessage}>Сообщить об ошибке</Button>
          <Button inverted={true} onClick={onEdit}> Редактировать </Button>
        </ButtonContainer>
      </ButtonContainer>
      <ErrorMessage closeError={errorMessage} errorVisible={formSendErrorShow}/>
    </Container>
  );
};

const Container = styled.div`
    display: flex;
    overflow: hidden;
    margin-bottom: 5px;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const GreenButton = styled.a`
  ${buttonCss};
  border: 2px solid #22A463;
  color: #000000;
  margin-left:18px;

  :hover,:active{
    background: inherit;
    color: #22A463;
  }
`;

const RedButton = styled.a`
  ${buttonCss};
  border: 2px solid #FF0000;
  color: #000000;

  :hover,:active{
    background: inherit;
    color: #FF0000;
  }
`;

const Map = styled.div`
  margin: 16px 0;
  padding: 0;
  box-sizing: border-box;
  
`;
