import {IMessage} from "../../api/dto/Message.g";
import {EmailApiRequest} from "../../api/EmailApiRequest.g";
import {FirmApiRequest} from "../../api/FirmApiRequest.g";
import {IThunkAction} from "../../core/store/mainReducer";
import {PopupAction} from "../popup/popupAction";
import {companyItem, companySimilarItem} from "./companyItemAction";

export const CompanyItemData = {

  IncreaseCountOfFirmViews(params: string): IThunkAction {
    const api = new FirmApiRequest();
    return async () => {
      try {
        await api.IncreaseCountOfFirmViews(params);
      } catch (error) {
        console.log(error);
      }
    };
  },

  LoadItem(params: string): IThunkAction {
    const api = new FirmApiRequest();

    return async (dispatch) => {
      dispatch(companyItem.started(params));
      try {
        const result = await api.getById(params);
        dispatch(companyItem.done({params, result}));
      } catch (error) {
        dispatch(companyItem.failed({params, error}));
      }
    };
  },

  SendMassage(id: string, massage: IMessage): IThunkAction {
    const api = new EmailApiRequest();

    return async (dispatch) => {
      try {
        dispatch(PopupAction.updateDate.started(null));

        await api.sendMessage(id, massage);
        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "success"
        }));

      } catch (error) {
        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "error"
        }));
      }
    };
  },

  SendToPMRMassage(massage: IMessage): IThunkAction {
    const api = new EmailApiRequest();

    return async (dispatch) => {
      try {
        dispatch(PopupAction.updateDate.started(null));
        await api.sendInfoMessage(massage);
        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "success"
        }));
      } catch (error) {
        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "error"
        }));
      }
    };
  },

  SendErrorMassage(massage: IMessage): IThunkAction {
    const api = new EmailApiRequest();

    return async (dispatch) => {
      try {
        dispatch(PopupAction.updateDate.started(null));
        await api.sendErrorMessage(massage);
        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "success"
        }));
      } catch (error) {
        dispatch(PopupAction.updateDate.done({
          params: null,
          result: "error"
        }));
      }
    };
  },

  LoadSimilarOrganization(params: string, cityId: number): IThunkAction {
    const api = new FirmApiRequest();

    return async (dispatch) => {
      dispatch(companySimilarItem.started(params));
      try {
        const result = await api.GetSimilarById(params, cityId);
        dispatch(companySimilarItem.done({params, result: result.items}));
      } catch (error) {
        dispatch(companySimilarItem.failed({params, error}));
      }
    };
  }
};
